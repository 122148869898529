<div class="container-fluid clearfix p-4 pt-0">
  <div class="position-relative pb-5">
    <div class="container-fluid border-bottom ">
      <div class="row border-bottom">
        <div class="col-md-6 columndivider">
          <h4 class="mt-3" tabindex="0">File Import</h4>
        </div>
        <Div class="row col-md-6">
          <div class="col-md-6">
            <h4 class="mt-3" tabindex="0">File Validation</h4>
          </div>
          <div class="col-md-6 text-end d-flex align-items-center justify-content-end">
            <button title="Page Refresh" tabindex="0" role="button" class="btn  btn-custom  btn-sm rounded-0  mb-1"
              (click)="refreshAll()">
              <i class="fas fa-redo-alt"></i>
            </button>
          </div>
        </Div>
      </div>
      <div class="row clearfix align-self-center p-3 pt-0 pb-0">
        <div class="col m-0 p-0">
          <div class="col-md-6 align-self-center columndivider">
            <div class=" form-group pt-3 pb-3 col-md-6">
              <label for="sel1" class="col form-label required">Format</label>
              <div>
                <!-- [value]="value2" -->
                <!-- {{fileValData.messagetype}}--- -->
                <select2 class="rounded-0" tabindex="0" id="messageDropdown" name="messageDropdown" aria-label="Format"
                  placeholder="Select" title="Format of the input message" [data]="data"
                  [(ngModel)]="fileValData.messagetype" (update)="update('value2', $event)" id="selec2_msg">
                </select2>
              </div>
            </div>
            <div class=" form-group pt-3 pb-3 col-md-6">
              <label for="sel1" class="col form-label">Provide File</label>
              <div class="file-drop-area mb-2">
                <span class="choose-file-button">
                  <i class="fas fa-upload"></i>
                </span>

                <span class="mx-3 file-message">Select a file or drag and drop it here</span>

                <input class="file-input" type="file" id="fileid" name="fileid" placeholder="Input" #files
                  accept=".xml,.txt,.DAT,.json" [(ngModel)]="fileValData.inputselected" (change)="readFile($event)"
                  (click)="clearFileInput(files)" multiple>
              </div>

              <span title="No file uploaded yet. Only .xml, .txt, and .dat files are allowed."
                *ngIf="!fileContent || (fileContent && !isValidFile(fileContent))">
                No file uploaded yet. Only .xml, .txt, and .dat files are allowed.
              </span>

              <div *ngIf="FilevalidationInterface.filename">
                <span class="navigation">
                  <i class="fa fa-trash" style="color: #0052c2;background-color:white; border:none;" aria-hidden="true"
                    onclick="document.getElementById('fileid').click()"></i>
                  {{FilevalidationInterface.filename}}
                </span>
              </div>

              <div *ngIf="FilevalidationInterface.dropFileName">
                <span class="navigation">
                  <i class="fa fa-trash" style="color: #0052c2;background-color:white; border:none;" aria-hidden="true"
                    onclick="document.getElementById('fileid').click()"></i>
                  {{FilevalidationInterface.dropFileName}}
                </span>
              </div>

            </div>
          </div>
        </div>
      </div>
      <div class="row clearfix align-self-center">
        <div class="col-md-12 text-center">
          <div class="row">
            <div class="col-md-6 columndivider">
              <div class="d-flex justify-content-between align-items-center">
                <div>
                  <span>OR</span>
                </div>
                <div>
                  <button type="submit" class="btn  btn-primary rounded-0  mb-3 me-3"
                    (click)="executeRunValidation(fileValData)">Run
                    Validation</button>
                  <button type="submit" class="btn  btn-primary rounded-0  mb-3"
                    (click)="inputDownload(fileValData, FilevalidationInterface.dropFileName, false)">Download
                    Current
                    Input</button>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="row">
                <div class="d-flex justify-content-end align-items-center">
                  <div class="d-flex">
                    <div class="dropdown">
                      <button class="btn btn-primary dropdown-toggle rounded-0 me-3" type="button"
                        data-bs-toggle="dropdown"> Download Report </button>
                      <!-- <ul class="dropdown-menu">
                          <li>
                            <a class="dropdown-item" (click)="downloadReport(fileValData, 'text')">Text
                              Format</a>
                          </li>
                          <li>
                            <a class="dropdown-item" (click)="downloadReport(fileValData, 'pdf')">PDF
                              Format</a>
                          </li>
                        </ul> -->
                      <div class="dropdown-menu" style="cursor: pointer;">
                        <a class="dropdown-item" (click)="downloadReport(fileValData, 'text')">Text Format</a>
                        <a class="dropdown-item" (click)="downloadReport(fileValData, 'pdf')">PDF Format</a>
                      </div>
                    </div>
                    <div>
                      <button type="submit" class="btn  btn-primary rounded-0  mb-3" (click)="clearOutput()"> Clear
                        Results</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Added Now -->
      <div class="row clearfix align-self-center mb-3">
        <div class="col-md-12">
          <div class="row">
            <div class="col-md-6 columndivider">
              <div class="d-flex justify-content-between align-items-center">
                <div>
                  <span>Drag and Drop a File Below; Alternatively, Type or Paste Text Below </span>
                </div>
              </div>
              <!-- <div>
                <input type="text" #searchQuery placeholder="Search..." />
                <button (click)="onSearch(searchQuery.value, codeEditor)">Search</button>
              </div> -->
              <div [ngStyle]="splitPaneStyle">
                <ngx-codemirror #codemirror [options]="codeMirrorOptions" [(ngModel)]="fileContent" placeholder="Input"
                  (ngModelChange)="setEditorContent($event)" appDragDrop (fileDropped)="handleFileDropped($event)"
                  [editor]="codemirror.codeMirror" (cursorActivity)="cursorMoved()"></ngx-codemirror>
              </div>

              <div id="block_container" *ngIf="fileContent">
                <div id="lineNo" class="blockalign m-2"></div>
                <div id="colNo" class="blockalign"></div>
              </div>


            </div>
            <div class="col-md-6">
              <div class="mb-3">
                <label for="exampleFormControlTextarea1" class="col-12 form-label text-center">Validation Result</label>
                <textarea tabindex="0" role="status" class="form-control rounded-0" id="output" aria-live="polite"
                  title="Validation Result" placeholder="Validation Result" rows="4" cols="150"
                  disabled>Validation Result</textarea>
              </div>
              <div class="mb-3 mt-3">
                <label for="exampleFormControlTextarea1" class="col-12 form-label text-center">Validation Report</label>
                <textarea class="rounded-0 form-control" id="exception" aria-live="polite" title="Validation Report"
                  rows="16" cols="100" placeholder="Validation Report" disabled>Validation Report</textarea>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>