import { Component } from '@angular/core';
import { Subscription } from 'rxjs';
import { ToastInterface } from 'src/app/interfaces/toast-interface';
import { ToastService } from 'src/app/services/toast/toast.service';

@Component({
  selector: 'app-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.css']
})
export class ToastComponent {
  toasts: ToastInterface[] = [];
  obs: Subscription;
  isToastVisible: boolean = true;

  constructor(public toastService: ToastService) {
    this.obs = toastService.toasts$.subscribe(toasts => this.toasts = toasts);
  }
  remove(index: number) {
    // this.toasts = this.toasts.filter((toast, i) => i !== index);
    this.toastService.remove(index);
  }
  toastclose() {
    this.isToastVisible = false;
  }

  ngOnDestroy() {
    this.obs.unsubscribe();
  }

}
