import { Component, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { Select2UpdateEvent } from 'ng-select2-component';
import { ToastrService } from 'ngx-toastr';
import { MessageService } from 'primeng/api';
import { CommonService } from 'src/app/services/common/common.service';
import { StpGuideService } from 'src/app/services/stp/stp-guide.service';

@Component({
  selector: 'app-stp-guide',
  templateUrl: './stp-guide.component.html',
  styleUrls: ['./stp-guide.component.css'],
})
export class StpGuideComponent implements OnInit {
  overlay = false;
  stpGuidVal: any = {};
  originalFormList: any = [];
  formatList: any = [];
  regionList: any = [];
  tempRegionList: any = [];
  countryList: any = [];
  transTypeList: any = [];
  originalTransTypeList: any = [];
  constructor(
    private stpGuideService: StpGuideService,
    // Added for tooltip
    private commonService: CommonService,
    private toastr: ToastrService,
    private messageService: MessageService
  ) { }
  ngOnInit(): void {
    this.getSTPDetails();
    this.commonService.settingUpTooltip();
  }
  getSTPDetails() {
    this.stpGuideService.getSTPDetails().subscribe({
      next: (response: any) => {
        //let detailsRes = response;
        // console.log(response, 'sssssssss');
        this.originalFormList = response;
        this.preloadDropdownValues(response);
      },
      error: (error: Error) => {
        // console.log('wdsds');
      },
    });
  }
  preloadDropdownValues(response: any) {
    let uniqueFormatArr: any = [];
    let uniqueRegionArr: any = [];
    if (response && response.length) {
      let uniqueFormatArr = response
        .filter(
          (format: any, index: any, self: any) =>
            index ===
            self.findIndex((p: any) => p.MessageFormat === format.MessageFormat)
        )
        ?.map(function (item: any) {
          return { label: item?.MessageFormat, value: item?.MessageFormat };
        });
      // console.log('stp details coming', uniqueFormatArr);
      this.formatList = [...uniqueFormatArr];

      let uniqueRegionArr = response
        .filter(
          (format: any, index: any, self: any) =>
            index === self.findIndex((p: any) => p.Region === format.Region)
        )
        ?.map(function (item: any) {
          return { label: item?.Region, value: item?.Region };
        });
      //console.log('stp details coming', uniqueRegionArr);
      this.tempRegionList = [...uniqueRegionArr];
    }
  }

  preloadDropdownValuesforOptionalDD(response: any) {
    let uniqueTransTypeArr: any = [];
    let uniqueCountryArr: any = [];
    if (response && response.length) {
      let uniqueTransTypeArr = response
        .filter(
          (format: any, index: any, self: any) =>
            index ===
            self.findIndex(
              (p: any) => p.Transactiontype === format.Transactiontype
            )
        )
        ?.map(function (item: any) {
          return { label: item?.Transactiontype, value: item?.Transactiontype };
        });
      //console.log('stp details TransType coming', uniqueTransTypeArr);
      this.transTypeList = [...uniqueTransTypeArr];

      let uniqueCountryArr = response
        .filter(
          (format: any, index: any, self: any) =>
            index === self.findIndex((p: any) => p.Country === format.Country)
        )
        ?.map(function (item: any) {
          return { label: item?.Country, value: item?.Country };
        });
      //console.log('stp details Country coming', uniqueCountryArr);
      this.countryList = [...uniqueCountryArr];
    }
  }

  loadTransTypeCountryDetails() {
    // console.log(this.stpGuidVal);
    let payLoadObj = {
      Format: this.stpGuidVal.messagetype,
      Region: this.stpGuidVal.region,
    };
    this.stpGuideService.getSTPTransTypeDetails(payLoadObj).subscribe({
      next: (response: any) => {
        //let detailsRes = response;
        this.originalTransTypeList = response;
        this.preloadDropdownValuesforOptionalDD(response);
      },
      error: (error: Error) => {
        //console.log('wdsds');
      },
    });
  }
  clearFormatList() {
    //this.formatList = this.originalFormList;
    this.stpGuidVal.region = '';
    this.stpGuidVal.country = [];
    this.stpGuidVal.transType = [];
    this.preloadDropdownValues(this.originalFormList);
  }
  submitSTPGuideForm() {
    if (
      this.stpGuidVal.messagetype.length > 0 &&
      this.stpGuidVal.region.length > 0
    ) {
      const countries = this.stpGuidVal?.country?.reduce((acc, item) => {
        if (item['value'] != undefined || item['value'] != null) {
          acc.push(item['value']);
        }
        return acc;
      }, []);
      const tranasctionType = this.stpGuidVal?.transType?.reduce((acc, item) => {
        if (item['value'] != undefined || item['value'] != null) {
          acc.push(item['value']);
        }
        return acc;
      }, []);

      let payload = {
        MessageType: this.stpGuidVal.messagetype || '',
        Region: this.stpGuidVal.region || '',
        Country: countries ? "'" + countries.join("','") + "'" : '',
        TransactionType: tranasctionType
          ? "'" + tranasctionType.join("','") + "'"
          : '',
      };
      this.stpGuideService.stpReport(payload).subscribe({
        next: (response: any) => {
          //let detailsRes = response;
          //console.log('submitted', response);
          this.commonService.downloadSTPguidePdf(
            this.stpGuidVal.region +
            '_' +
            this.stpGuidVal.messagetype +
            '_STP_guide.pdf',
            response?.message
          );
        },
        error: (err: Error) => {
          // this.toastr.error(
          //   'Invalid input detected. Please ensure that your input is valid and try again' ||
          //   err?.['error'].message
          // );
          this.messageService.add({
            severity: 'error',
            detail: 'Invalid input detected. Please ensure that your input is valid and try again' ||
              err?.['error'].message
          });
        },
      });
    }
  }
  filterTransByCountry() {
    this.transTypeList = this.originalTransTypeList
      .filter((item) =>
        this.stpGuidVal.country.some(
          (citem: any) => citem.value === item.Country
        )
      )
      .map(function (item: any) {
        return { label: item?.Transactiontype, value: item?.Transactiontype };
      });
  }
  update(key: string, event: any) {
    if (key === 'format') {
      let selectedVal = event.value;
      this.regionList = this.originalFormList
        .filter((item) => selectedVal == item['MessageFormat'])
        .map((obj) => ({ label: obj['Region'], value: obj['Region'] }));
    } else if (key === 'region') {
      this.loadTransTypeCountryDetails();
    }
    if (key === 'country') {
      key = event.value;
      this.filterTransByCountry();
    }
    if (key === 'transType') {
      key = event.value;
    }

    // console.log(key, this.stpGuidVal, 'onchage of Select2');
  }

  resetstpGForm() {
    this.stpGuidVal = '';
  }
}
