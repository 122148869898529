<div class="container">
   <div class="container mb-5 pb-100">
      <div class="row col-md-12 border-bottom">
         <div class="col-md-6">
            <h4 class="mt-3" tabindex="0">STP Guide Download</h4>
         </div>
         <div class="col-md-6 text-end d-flex align-items-center justify-content-end">
            <button title="Page Refresh" tabindex="0" role="button" class="btn btn-custom btn-sm rounded-0 mb-1">
               <i class="fas fa-redo-alt"></i>
            </button>
         </div>
      </div>
      <div class="form-group mt-2">
         <h6>
            <p>Asterisk items (<span class="text-danger" style="font-size: 1rem;">*</span>) are required to proceed.</p>
         </h6>
      </div>
      <div class="row col-md-12">
         <div class="col-md-4">
            <div class="form-group mb-4">
               <label class="col form-label">Format</label>
               <!-- <select2 class=" rounded-0" tabindex="0" id="formatDropdown" name="formatDropdown" aria-label="Format"
                  placeholder="Select" title="Format of the input message" [overlay]="overlay" [data]="formatList"
                  [(ngModel)]="stpGuidVal.messagetype" (update)="update('format', $event)" id="selec2_msg">
               </select2> -->
               <p-dropdown [options]="formatList" [filter]="true" filterBy="label" [showClear]="true"
                  [(ngModel)]="stpGuidVal.messagetype" placeholder="Select a Format of the input message"
                  styleClass="full-width-dropdown" (onChange)="update('format', $event)"
                  (onClear)="clearFormatList()"></p-dropdown>
            </div>
            <div class="form-group mb-4">
               <label class="col form-label"></label>Region
               <!-- <select2 class=" rounded-0" tabindex="0" id="regionDropdown" name="regionDropdown" aria-label="Region"
                  placeholder="Select" title="Region details" [overlay]="overlay" [data]="regionList"
                  [(ngModel)]="stpGuidVal.region" (update)="update('region', $event)" id="selec2_msg"></select2> -->

               <p-dropdown [options]="regionList" [filter]="true" filterBy="label" [(ngModel)]="stpGuidVal.region"
                  placeholder="Select a Region" styleClass="full-width-dropdown"
                  (onChange)="update('region', $event)"></p-dropdown>
            </div>
            <div class="form-group mb-4">
               <label class="col form-label">Country/Branch (optional)
                  <span class="text-primary"><i class="fas fa-info-circle" data-bs-toggle="tooltip"
                        data-bs-placement="right"
                        data-bs-original-title="Click on the branch(es) from the dropdown list to select multiple items">
                     </i> </span>
               </label>
               <p-multiSelect styleClass="full-width-dropdown" [options]="countryList" [(ngModel)]="stpGuidVal.country"
                  defaultLabel="Select a Country" optionLabel="label" display="chip"
                  (onChange)="update('country', $event)"></p-multiSelect>
               <!-- <p-multiSelect [options]="countryList" [(ngModel)]="stpGuidVal.country" optionLabel="name" (onChange)="update('country', $event)"></p-multiSelect> -->
               <!-- <select2 class=" rounded-0" tabindex="0" id="countryDropdown" name="countryDropdown" aria-label="country"
                  placeholder="Select" title="country or Branch details" [overlay]="overlay" [data]="countryList"
                  [(ngModel)]="stpGuidVal.country" [multiple]="true" (update)="update('country', $event)"
                  id="selec2_msg"></select2> -->
            </div>
            <div class="form-group mb-4">
               <label class="col form-label">Transaction Type (optional)
                  <span class="text-primary"><i class="fas fa-info-circle" data-bs-toggle="tooltip"
                        data-bs-placement="right"
                        data-bs-original-title=" Click on the transaction Type(s) from the dropdown list to select multiple items">
                     </i> </span>
               </label>
               <p-multiSelect styleClass="full-width-dropdown" [options]="transTypeList"
                  [(ngModel)]="stpGuidVal.transType" defaultLabel="Format of the input message" optionLabel="label"
                  display="chip" (onChange)="update('transType', $event)"></p-multiSelect>
               <!-- <select2 class=" rounded-0" tabindex="0" id="transTypeDropdown" name="transTypeDropdown"
                  aria-label="transaction Type Dropdown" placeholder="Select" [multiple]="true"
                  title="Format of the input message" [overlay]="overlay" [data]="transTypeList"
                  [(ngModel)]="stpGuidVal.transType" (update)="update('transType', $event)" id="selec2_msg"></select2> -->
            </div>
         </div>
      </div>
      <hr>
      <div class="input-group-btn">
         <button type="button" class="h-btn btn btn-primary rounded-0 mx-2 ms-0"
            (click)="submitSTPGuideForm()">Submit</button>
         <button type="button" class="h-btn btn btn-secondary rounded-0" (click)="resetstpGForm()">Reset</button>
      </div>
   </div>
</div>
<!-- <div class="wrapper">
    <div class="content">
      <div class="circle animation one"></div>
      <div class="circle animation two"></div>
      <div class="circle animation three"></div>
      <div class="circle animation four"></div>
      <div class="circle animation five"></div>
      <div class="circle animation six"></div>
      <div class="circle animation seven"></div>
      <div class="circle animation eight"></div>
    </div>
    
    </div>    -->