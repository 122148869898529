import { Directive, HostListener, Input, ElementRef, EventEmitter, Output } from '@angular/core';

@Directive({
  selector: '[appDragDrop]'
})
export class DragDropDirective {
  @Input() editor: any;
  @Output() fileDropped: EventEmitter<string> = new EventEmitter<string>();

  constructor(private el: ElementRef) { }

  @HostListener('dragover', ['$event']) onDragOver(event: DragEvent) {
    event.preventDefault();
  }

  @HostListener('drop', ['$event']) onDrop(event: DragEvent) {
    // event.preventDefault();
    if (event.dataTransfer && event.dataTransfer.files && event.dataTransfer.files.length > 0) {
      const file = event.dataTransfer.files[0];
      this.fileDropped.emit(file.name);

      const reader = new FileReader();
      // reader.onload = () => {
      //   if (this.editor) {
      //     const doc = this.editor.getDoc();
      //     const cursor = doc.getCursor();
      //     doc.replaceRange(file.name, cursor);
      //   }
      // };
      // reader.readAsText(file); // Read file to trigger reader.onload
    }
  }
}
