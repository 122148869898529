import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { AuthguardService } from '../services/authGuard/authguard.service';
import { ToastService } from '../services/toast/toast.service';
import { CommonService } from '../services/common/common.service';

// Define the authGuard function, which implements CanActivateFn interface

export const authGuard: CanActivateFn = (route, state) => {
  const authService = inject(AuthguardService);
  const router = inject(Router);
  const toast = inject(ToastService)
  const commonservice = inject(CommonService);
  const menurole: any = {
    arr: []
  };

  // Check if the user is logged in using the AuthService 
  if (authService.loggedIn()) {
    return true; // If logged in, allow access to the route
  } else {
    router.navigate(['/login']);
    toast.add({
      type: 'error',
      message: 'Forbidden Access. Please login',
    });
    return false; // If not logged in, deny access to the route
  }
}