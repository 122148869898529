import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { ToastInterface } from 'src/app/interfaces/toast-interface';

@Injectable({
  providedIn: 'root'
})
export class ToastService {
  toasts: ToastInterface[] = [];
  delay = 2000;

  subject = new BehaviorSubject<ToastInterface[]>([]);
  toasts$ = this.subject.asObservable();
  toastService: any;
  constructor() { }

  add(toast: ToastInterface) {
    this.toasts = [toast, ...this.toasts];
    this.subject.next(this.toasts);

    setTimeout(() => {
      this.toasts = this.toasts.filter(v => v !== toast);
      this.subject.next(this.toasts);
    }, this.delay);
  }

  remove(index: number) {
    this.toasts = this.toasts.filter((toast, i) => i !== index);
    this.subject.next(this.toasts);
  }
}
