import {
  Component,
  OnInit,
  OnChanges,
  SimpleChanges,
  Input
} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormsComponent, JsonFormData } from '../../core/forms/forms.component';
import { Subscription } from 'rxjs';
import { FormsModule } from '@angular/forms';
import { TableComponent } from 'src/app/core/table/table.component';
import { CommonModule } from '@angular/common';
import RestURL from 'src/assets/config/restURLConstant.json';
import { CommonService } from 'src/app/services/common/common.service';
import { ToastrService } from 'ngx-toastr';
import { StmodalService } from 'src/app/services/common/stmodal.service';
import { StandaloneModalComponent } from 'src/app/core/standalone-modal/standalone-modal.component';
import { NavigationEnd, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
@Component({
  selector: 'app-crud',
  templateUrl: './crud.component.html',
  standalone: true,
  imports: [
    TableComponent,
    FormsComponent,
    CommonModule,
    FormsModule,
    StandaloneModalComponent
  ],
  styleUrls: ['./crud.component.css'],
})
export class CrudComponent implements OnInit, OnChanges {
  @Input() action = '';
  @Input() name: string = '';
  public editformData: any;
  public checkEditFlag: boolean;
  public unlockbuttonFlag: boolean = false;
  public formData: JsonFormData;
  public params: any;
  ClientListData: any = [];
  tableHeaderData: any = {};
  searchValue: string = '';
  deleteUrl: any;
  data: any = {};
  deleteData: any = {};
  unlockURL: any;
  constructor(
    private http: HttpClient,
    private commonService: CommonService,
    private httpClient: HttpClient,
    private toastr: ToastrService,
    public modalService: StmodalService,
    private router: Router,
    private messageService: MessageService

  ) {

  }

  public buildform() {
    if (this.name == 'client') {
      this.http
        .get('./assets/config/createclient.json')
        .subscribe((formData: any) => {
          this.formData = formData;
        });
    } else if (this.name == 'users') {
      this.http
        .get('./assets/config/createuser.json')
        .subscribe((formData: any) => {
          this.formData = formData;
        });
    } else {
      //console.log('else');
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.buildform();
    this.loadDataheaders();
  }
  ngOnInit() { }

  loadDataheaders() {
    if (this.action == 'view') {
      let cliurl = this.commonService.restDomainURL + RestURL.FETCH_CLIENTLISTS;
      let userurl = this.commonService.restDomainURL + RestURL.FETCH_USERSLISTS;
      if (this.name == 'client' || this.name == 'clients') {
        this.http.get(cliurl).subscribe({
          next: (data: any) => {
            this.tableHeaderData['headers'] = [
              { label: '#', headerKey: '' },
              { label: 'Region', headerKey: 'Region' },
              { label: 'Client ID', headerKey: 'ClientID' },
              { label: 'Client Name', headerKey: 'ClientName' },
              {
                label: 'Client Role',
                headerKey: 'ClientRole',
              },
              {
                label: 'Status',
                headerKey: 'Status',
              },
            ];
            // setting of the Table Columns
            this.ClientListData = data;
          },
          error: (err: any) => {
            // this.toastr.error(err?.error?.error?.message);
            this.messageService.add({
              severity: 'error',
              detail: err?.error?.error?.message
            });
          },
        });
      } else if (this.name == 'users') {
        this.http.get(userurl).subscribe({
          next: (data: any) => {
            this.tableHeaderData['headers'] = [
              { label: '#', headerKey: '' },
              { label: 'Region', headerKey: 'Region' },
              { label: 'User Name', headerKey: 'UserName' },
              { label: 'Client ID', headerKey: 'ClientID' },
              { label: 'Client Name', headerKey: 'ClientName' },
              {
                label: 'User Role',
                headerKey: 'UserRole',
              },
              {
                label: 'First Name',
                headerKey: 'FirstName',
              },
              {
                label: 'Last Name',
                headerKey: 'LastName',
              },
              {
                label: 'Email ID',
                headerKey: 'EmailID',
              },
            ];
            // setting of the Table Columns
            this.ClientListData = data;
          },
          error: (err: any) => {
            // this.toastr.error(err?.error?.error?.message);
            this.messageService.add({
              severity: 'error',
              detail: err?.error?.error?.message
            });
          },
        });
      } else {
        //console.log("Table exist")
      }
    } else if (this.action == 'edit') {
      let cliurl1 = this.commonService.restDomainURL + RestURL.GET_CREATEDUSER_LIST;
      let userurl1 = this.commonService.restDomainURL + RestURL.GET_CREATEDCLIENT_LIST;
      if (this.name == 'client' || this.name == 'clients') {
        this.http.get(cliurl1).subscribe({
          next: (data: any) => {
            this.tableHeaderData['headers'] = [
              { label: '#', headerKey: '' },
              { label: 'Region', headerKey: 'region' },
              { label: 'Client ID', headerKey: 'clientid' },
              { label: 'Client Name', headerKey: 'clientname' },
              {
                label: 'Status',
                headerKey: 'status',
              },
              {
                label: 'Actions',
                actions: ['Edit', 'Delete'],
              }
            ];
            // setting of the Table Columns
            this.ClientListData = data;
          },
          error: (err: any) => {
            // this.toastr.error(err?.error?.error?.message);
            this.messageService.add({
              severity: 'error',
              detail: err?.error?.error?.message
            });
          },
        });
      } else if (this.name == 'users' || this.name == 'user') {
        this.http.get(userurl1).subscribe({
          next: (data: any) => {
            this.tableHeaderData['headers'] = [
              { label: '#', headerKey: '' },
              { label: 'Region', headerKey: 'region' },
              { label: 'User Name', headerKey: 'username' },
              { label: 'Client ID', headerKey: 'clientid' },
              {
                label: 'User Type',
                headerKey: 'UserRole',
              },
              {
                label: 'First Name',
                headerKey: 'firstname',
              },
              {
                label: 'Last Name',
                headerKey: 'lastname',
              },
              {
                label: 'Email ID',
                headerKey: 'email',
              },
              {
                label: 'Actions',
                actions: ['Edit', 'Delete'],
              }
            ];
            this.ClientListData = data;
            this.ClientListData.map(val => {
              if (val.isUserAdmin == 3) {
                val['UserRole'] = 'Ti Power User'
              } else if (val.isUserAdmin == 6 || val.isUserAdmin == 7) {
                val['UserRole'] = 'VAPI User'
              } else {
                val['UserRole'] = 'User'
              }
            })

            //console.log(this.ClientListData)
          },
          error: (err: any) => {
            // this.toastr.error(err?.error?.error?.message);
            this.messageService.add({
              severity: 'error',
              detail: err?.error?.error?.message
            });
          },
        });
      } else {
        //console.log("Table exist")
      }
    } else {
      // console.log("otherthan view and edit")
    }


  }

  /* search functionality */
  loadDetailsByKey() {
    let payloadObj = {
      value: this.searchValue,
    };
    if (this.name == 'clients' && this.action == 'view') {
      let clisearchUrl = this.commonService.restDomainURL + RestURL.FETCH_CLIENTLISTS;
      this.httpClient.get<any>(clisearchUrl, { params: payloadObj }).subscribe({
        next: (response: any) => {
          this.ClientListData = response;
          if (this.ClientListData.length == 0) {
            this.loadDataheaders()
          }
        },
        error: (err: any) => {
          // this.toastr.error(err?.error?.error?.message);
          this.messageService.add({
            severity: 'error',
            detail: err?.error?.error?.message
          });
        },
      });
    } else if (this.name == 'users' && this.action == 'view') {
      let usersearchUrl = this.commonService.restDomainURL + RestURL.FETCH_USERSLISTS;
      this.httpClient.get<any>(usersearchUrl, { params: payloadObj }).subscribe({
        next: (response: any) => {
          this.ClientListData = response;
          if (this.ClientListData.length == 0) {
            this.loadDataheaders();
          }
        },
        error: (err: any) => {
          // this.toastr.error(err?.error?.error?.message);
          this.messageService.add({
            severity: 'error',
            detail: err?.error?.error?.message
          });
        },
      });
    } else if (this.name == 'client' && this.action == 'edit') {
      let clisearchUrl = this.commonService.restDomainURL + RestURL.FIND_CLIENTS;
      this.httpClient.get<any>(clisearchUrl, { params: payloadObj }).subscribe({
        next: (response: any) => {
          this.ClientListData = response;
          if (this.ClientListData.length == 0) {
            this.loadDataheaders();
          }
        },
        error: (err: any) => {
          // this.toastr.error(err?.error?.error?.message);
          this.messageService.add({
            severity: 'error',
            detail: err?.error?.error?.message
          });
        },
      });
    } else if (this.name == 'users' && this.action == 'edit') {
      let usersearchUrl = this.commonService.restDomainURL + RestURL.FIND_USERS;
      this.httpClient.get<any>(usersearchUrl, { params: payloadObj }).subscribe({
        next: (response: any) => {
          this.ClientListData = response;
          if (this.ClientListData.length == 0) {
            this.loadDataheaders();
          }
        },
        error: (err: any) => {
          // this.toastr.error(err?.error?.error?.message);
          this.messageService.add({
            severity: 'error',
            detail: err?.error?.error?.message
          });
        },
      });
    }
    else {
      //console.log("Search client user exist:::")
    }
  }
  /* End of search functionality */

  navigateToCrud(eventEmitData: any) {
    if (this.name == 'users') {
      let fieldGroups = this.ClientListData.filter((field) => field.username == eventEmitData.clickedData.username);
      //console.log(fieldGroups)
      this.editformData = fieldGroups[0];
    } else {
      this.editformData = eventEmitData.clickedData;
    }
    this.checkEditFlag = eventEmitData.editFlag;
    if (this.editformData.accountLocked == 1) {
      this.unlockbuttonFlag = true;
    } else {
      this.unlockbuttonFlag = false;
    }
    window.scrollTo(0, 0)
  }
  navigateTocrudForDelete(deleteeventEmitData: any) {
    this.modalService.open('delete_modal');
    this.deleteData = deleteeventEmitData.Data;
  }

  delteData(urlname: any) {
    if (urlname == 'client') {
      this.deleteUrl = this.commonService.restDomainURL + RestURL.DELETE_CLIENT;
      this.data = {
        "params": { clientid: this.deleteData.ClientID, region: this.deleteData.Region }
      }
    } else {
      this.deleteUrl = this.commonService.restDomainURL + RestURL.DELETE_USERS;
      this.data = {
        "params": { username: this.deleteData.UserName }
      }
    }

    this.http.delete(this.deleteUrl, this.data).subscribe({
      next: (response: any) => {
        let dataSuccess = response;
        // this.toastr.success(dataSuccess.message);
        this.messageService.add({
          severity: 'success',
          detail: dataSuccess.message
        });
        this.modalService.close();
        window.location.reload()
      },
      error: (err: any) => {
        // this.toastr.error(err?.error?.error?.message);
        this.messageService.add({
          severity: 'error',
          detail: err?.error?.error?.message
        });
      }

    })

  }


  unlockUseraccount(arg1) {
    if (arg1.popup == true) {
      this.modalService.open('unlock_modal');
    }
  }
  confirmUnlock() {
    this.unlockURL = this.commonService.restDomainURL + RestURL.PUT_EDIT_USER_ACCOUNT
    this.http.put(this.unlockURL + '?value=' + this.editformData.username, '').subscribe({
      next: (response: any) => {
        let dataSuccess = response;
        // this.toastr.success(dataSuccess.message);
        this.messageService.add({
          severity: 'success',
          detail: dataSuccess.message
        });
        this.modalService.close()
        window.location.reload()
      },
      error: (err) => {
        //  this.toastr.error(err?.error?.error?.message);
        this.messageService.add({
          severity: 'error',
          detail: err?.error?.error?.message
        });
      },
    })
  }
  reload(arg) {
    if (arg.changedetect == true) {
      this.loadDataheaders()
    }
  }

}
