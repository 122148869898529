import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { CommonService } from 'src/app/services/common/common.service';
import { Router } from '@angular/router';
import { Select2UpdateEvent, Select2Option, Select2Group } from 'ng-select2-component';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/services/auth/auth.service';
import { ValidatorInterface, format } from 'src/app/interfaces/validator-interface';
import RestURL from 'src/assets/config/restURLConstant.json';
import { Collapse } from 'bootstrap';
import { MessageService } from 'primeng/api';
import { ToastService } from 'src/app/services/toast/toast.service';
@Component({
  selector: 'app-validator',
  templateUrl: './validator.component.html',
  styleUrls: ['./validator.component.css'],
})

export class ValidatorComponent {

  data: (Select2Group | Select2Option)[];
  ValidatorInterface: ValidatorInterface = {
    selectedObj: {},
    dropFileName: '',
    filename: '',
    advancedOptionStatus: '',
    isAdvancedCollapsed: false,
    statusOutput: '',
    errorsOutput: '',
    parsedPayloadOutput: '',
    initValidationVal: '',
    isDisabled: true
  }
  formatList: any;
  versionList: format[];
  categoryList: format[];
  nameList: format[];
  validationsList: format[] = [];
  targetValidations: format[];

  validator: any = {};
  fullObj: any = {};
  fileContent: any = '';

  private bsCollapseOne: Collapse;
  private bsCollapseTwo: Collapse;
  private bsCollapseThree: Collapse;

  constructor(private authService: AuthService, private http: HttpClient, private commonService: CommonService, private toastr: ToastrService, public router: Router, private messageService: MessageService, public toastService: ToastService) {
    // Returns a Bootstrap tooltip instance
    this.commonService.settingUpTooltip();
  }
  // Function to verify the uploaded file size and parse it
  changeAngle() {
    if (this.ValidatorInterface.isAdvancedCollapsed) {
      let element = document.getElementById("hideAngle");
      element.classList.add("fa-angle-right");
      this.ValidatorInterface.isAdvancedCollapsed = !this.ValidatorInterface.isAdvancedCollapsed;
    } else {
      let element = document.getElementById("hideAngle");
      element.classList.add("fa-angle-down");
      this.ValidatorInterface.isAdvancedCollapsed = !this.ValidatorInterface.isAdvancedCollapsed;
    }

  }

  ngOnInit() {
    // this.formatList = this.formatList;
    this.authService.getCatalog().subscribe({
      next: (response: any) => {
        this.fullObj = response;
        this.fullObj = this.removeCategoryAndName(this.fullObj.catalog);

        this.formatList = this.GetJsonKeyValue(this.fullObj, 'format').sort((a, b) => {
          let x = a.toUpperCase(),
            y = b.toUpperCase();
          return x === y ? 0 : x > y ? 1 : -1;
        }).reverse();

        this.defaultVal();
        this.validator.category = 'All';
        this.validationsList?.push({ label: 'None', value: 'None' });
        this.validatorDropdownValues(this.fullObj);
      },
      error: (err) => {
        /* this.http.get('./assets/config/catalog.json').subscribe((response) => {
           this.fullObj = response;
           this.fullObj = this.removeCategoryAndName(this.fullObj.catalog);
           this.formatList = this.GetJsonKeyValue(this.fullObj, 'format').sort((a, b) => {
             let x = a.toUpperCase(),
               y = b.toUpperCase();
             return x === y ? 0 : x > y ? 1 : -1;
           }).reverse();
 
           this.defaultVal();
           this.validator.category = 'All';
           this.validator.includeInput = 'Never';
           this.validationsList.push({ label: 'None', value: 'None' });
           this.validatorDropdownValues(this.fullObj);
         })*/
      },
    });

    this.commonService.getincludeJsonData().subscribe(data => {
      this.data = data.sort((a: any, b: any) => a.label.localeCompare(b.label));
    });

  }

  update(key: string, event: any) {
    key = event.value;
    if (key == 'version') {
      this.validator.name = [];
      this.validator.validation = [];
    }
  }

  updateversion(key: string, event: any) {
    key = event.value;
    if (key) {
      this.validator.name = [];
      this.validator.category = [];
      this.validator.validation = [];
    }
  }

  validatorDropdownValues(response: any) {
    if (response && response.length) {
      let formatArr = response.filter((items: any, index: any, self: any) =>
        index === self.findIndex((p: any) => p.format === items.format)
      )?.map(function (item: any) {
        return { label: item?.format, value: item?.format };
      });
      this.formatList = [...formatArr];
    }
  }

  defaultVal() {
    if ((this.formatList.length > 0) && (this.fullObj)) {
      this.getVersion(this.formatList[0], 'version')
    }
  }

  getVersion(v: string, k: string) {
    this.validator = {};
    if (this.fullObj) {
      this.validator.format = v;
      let versionArr = this.fullObj.filter((items: any, index: any, self: any) =>
        index === self.findIndex((p: any) => p.version === items.version && p.format === items.format)
      )?.map(function (item: any) {
        return { label: item?.version, value: item?.version };
      });
      this.versionList = [...versionArr]
      this.versionList.sort((a: any, b: any) => {
        let x = a.value.toUpperCase(),
          y = b.value.toUpperCase();
        return x === y ? 0 : x > y ? 1 : -1;
      }).reverse();
      this.validator[k] = this.versionList[0].value;
      this.getMSGCategory(this.validator[k], 'version');
    }
  }

  getMSGCategory(version: any, key: string): void {
    if (this.fullObj) {
      let categoryArr = this.fullObj.filter((items: any, index: any, self: any) =>
        index === self.findIndex((p: any) => p.category === items.category)
      )?.map(function (item: any) {

        return { label: item?.category, value: item?.category };
      });

      this.categoryList = [...categoryArr];
      this.categoryList.push({ label: 'All', value: 'All' })
      this.categoryList.sort((a: any, b: any) => {
        let x = a.value.toUpperCase(),
          y = b.value.toUpperCase();
        return x === y ? 0 : x > y ? 1 : -1;
      }).reverse();
    }
  }

  getMSGNames(v: string, k: string) {
    if (this.fullObj && v != 'All') {
      let nameListArr = this.fullObj.filter((items: any, index: any) => {
        return items.category === this.validator.category && items.format === this.validator.format && items.version === this.validator.version
      })?.map(function (item: any) {
        return { label: item?.name, value: item?.name };
      });
      this.nameList = [...nameListArr];
      this.nameList.sort((a: any, b: any) => {
        let x = a.value.toUpperCase(),
          y = b.value.toUpperCase();
        return x === y ? 0 : x > y ? 1 : -1;
      }).reverse();
    }
    if (v == 'All') {
      let nameListArr = this.fullObj.filter((items: any, index: any, self: any) =>
        index === self.findIndex((p: any) => p.name === items.name)
      )?.map(function (item: any) {
        return { label: item?.name, value: item?.name };
      });

      this.nameList = [...nameListArr];
      this.nameList.sort((a: any, b: any) => {
        let x = a.value.toUpperCase(),
          y = b.value.toUpperCase();
        return x === y ? 0 : x > y ? 1 : -1;
      }).reverse();
    }
  }

  removeCategoryAndName(arr: any) {
    let key = 'name';
    let names = ["head.001.001.02", "pacs.008.001.08STP", "pacs.008.001.08", "pacs.009.001.08COV", "pacs.009.001.08ADV", "pacs.009.001.08"];
    let tempArr = [];
    for (let i = 0; i < arr.length; i++) {
      if (names.includes(arr[i][key])) {
        tempArr.push(arr[i]);
      }
    }
    return tempArr;
  }

  getTargetValidation(val: any, key: string): void {
    this.targetValidations = [];
    this.validator.validation = [];
    this.validator.targetValidations = [];

    if (this.fullObj && val != null) {
      const preppedArray = this.arrayFindByKey(
        this.arrayFindByKey(
          this.arrayFindByKey(this.fullObj, 'format', this.validator.format),
          'version', this.validator.version), key, val
      );

      const obj1 = this.objectFindByKey(preppedArray, key, val);
      this.ValidatorInterface.selectedObj = obj1;
      this.targetValidations = obj1.validations;
      this.validator.validation = obj1.validations;

      let validationsListArr = this.validator.validation.filter((items: any, index: any) => {
        return items.validations === this.validator.validations
      })?.map(function (item: any) {
        return { label: item?.name, value: item?.name };
      });

      this.validationsList = [...validationsListArr];
      this.validationsList.sort((a: any, b: any) => {
        let x = a.value.toUpperCase(),
          y = b.value.toUpperCase();
        return x === y ? 0 : x > y ? 1 : -1;
      }).reverse();

      this.validationsList.push({ label: 'None', value: 'None' })
      // this.validator.validation = ['Default'];
      this.validator.validation = [{ label: "Default", value: "Default" }];

    }
  }


  private GetJsonKeyValue(arr: any[], key: string): any[] {
    let tempKey = [];
    for (let i = 0; i < arr.length; i++) {
      if ((tempKey.indexOf(arr[i][key])) == -1) {
        tempKey.push(arr[i][key]);
      }
    }
    return tempKey;

  }

  arrayFindByKey(array: any[], key: string, value: any): any[] {
    return array.filter(item => item[key] === value);
  }

  objectFindByKey(array: any[], key: string, value: any): any {
    return array.find(item => item[key] === value);
  }

  getJsonKeyValue(array: any[], key: string): any[] {
    return array.map(item => item[key]);
  }

  clearFileInput(files: any) {
    // console.log("testing", files)
  }

  readFile(fileList: any) {
    let file = fileList.target.files[0];
    this.ValidatorInterface.dropFileName = '';
    this.ValidatorInterface.filename = file?.name ? file?.name : this.ValidatorInterface.filename;
    let fileReader: FileReader = new FileReader();
    let self = this;
    fileReader.onloadend = function (x) {
      self.fileContent = fileReader.result;
    };
    fileReader.readAsText(file);
  }

  clearOutputValidator() {
    document.getElementById("statusOutput").style.display = "none";
    document.getElementById("errorsOutput").style.display = "none";
    document.getElementById("parsedPayloadOutput").style.display = "none";
    document.getElementById("collapseOne").classList.remove("show");
    document.getElementById("collapseTwo").classList.remove("show");
    document.getElementById("collapseThree").classList.remove("show");
    document.getElementById("errorNumbers").style.display = "none";

    const header1 = document.getElementById('headingOne')?.querySelector('button') as HTMLButtonElement;
    header1?.setAttribute('disabled', 'true');
    const header2 = document.getElementById('headingTwo')?.querySelector('button') as HTMLButtonElement;
    header2?.setAttribute('disabled', 'true');
    const header3 = document.getElementById('headingThree')?.querySelector('button') as HTMLButtonElement;
    header3?.setAttribute('disabled', 'true');
  }


  executeValidator(val: any) {
    this.ValidatorInterface.isDisabled = false;
    let advOptionStatus = document.getElementById("advancedOptionStatus") as HTMLInputElement;
    if (advOptionStatus) {
      advOptionStatus.innerText;
    }

    if (this.fileContent === "") {
      // this.toastr.error("Provide payload in 'Input' textarea or Select a file");
      this.messageService.add({
        severity: 'error',
        detail: "Provide payload in 'Input' textarea or Select a file"
      });
    } else {
      this.checkSession();
      let url = "";
      let inputType = "json";
      let serviceType = "validation";

      if (advOptionStatus.value == "true") {
        let includePayload = document.getElementById("includePayloadDropdown") as HTMLInputElement;
        if (includePayload) {
          includePayload.value;
        }
      }
      if (serviceType == "none") {
        // this.toastr.error("Make sure all options are selected before sending the request...");
        this.messageService.add({
          severity: 'error',
          detail: "Make sure all options are selected before sending the request..."
        });
      } else if (val.format == "none") {
        // this.toastr.error("Make sure 'Message Format' is selected before sending the request...");
        this.messageService.add({
          severity: 'error',
          detail: "Make sure 'Message Format' is selected before sending the request..."
        });
      } else if (val.version == "none") {
        // this.toastr.error("Make sure 'Message Version' is selected before sending the request...");
        this.messageService.add({
          severity: 'error',
          detail: "Make sure 'Message Version' is selected before sending the request..."
        });
      } else if (val.name == "none" || val.name == undefined) {
        // this.toastr.error("Make sure 'Message Name' is selected before sending the request...");
        this.messageService.add({
          severity: 'error',
          detail: "Make sure 'Message Name' is selected before sending the request..."
        });
      } else {
        if (serviceType == "validation") {
          if (inputType == "raw" || advOptionStatus.innerText == "false") {
            url = this.commonService.vapiDomainURL + 'validator/raw?format=' + encodeURIComponent(val.format) + '&version=' + encodeURIComponent(val.version) + '&name=' + val.name;
          } else {
            url = this.commonService.vapiDomainURL + 'validator';
          }
        }
        let encoding = "Base64"
        let obj: any = {};
        let toSend: any;
        if (encoding == "Base64" && advOptionStatus.innerText == "true") {
          // this.fileContent = this.commonService.b64EncodeUnicode(this.fileContent);
        }
        if ((inputType == "json" || inputType == "xml") && advOptionStatus.innerText == "true") {
          obj["message"] = this.commonService.b64EncodeUnicode(this.fileContent);
          obj["name"] = val.name;
          obj["format"] = val.format;
          obj["version"] = val.version;
          obj["includePayload"] = val.includeInput;
          // obj["validations"] = val.validation;

          if (encoding == "Base64") {
            obj["payloadEncoding"] = encoding;
          }
          if (val.validation.length > 0) {
            if (val.validation.length === 1 && val.validation[0] === "none") {
              obj["validations"] = "";
            } else {
              if (val.validation) {
                const values = val.validation.map(item => item.value);
                obj["validations"] = values.join();
              }

            }
          }
          if (obj["name"] == "head.001.001.02") {
            obj["validatorOptions"] = "ext-name:BOFA";
          } else {
            obj["validatorOptions"] = "appHdrMandatory:true,ext-name:BOFA";
          }
        } else {
          toSend = this.fileContent;
          if (advOptionStatus.innerText == "true") {
            url += "&includePayload=" + encodeURIComponent(val.includeInput);
            if (encoding == "Base64") {
              url += "&payloadEncoding=" + encodeURIComponent(encoding);
            }
          }
          if (val.validation.length > 0) {
            if (val.validation.length === 1 && val.validation[0] === "none") {
              url += "&validations=" + "";
            } else {
              const values = val.validation.map(item => item.value);
              url += "&validations=" + encodeURIComponent(values.join());
            }
          }
          if (val.name == "head.001.001.02") {
            url += "&validatorOptions=ext-name:BOFA";
          } else {
            url += "&validatorOptions=appHdrMandatory:true,ext-name:BOFA";
          }
        }

        let validatorUrl: any;
        let payload: any;
        if (advOptionStatus.innerText == 'false') {
          validatorUrl = url;
          payload = this.fileContent;
        } else {
          validatorUrl = this.commonService.vapiDomainURL + RestURL.POST_VALIDATOR;
          payload = obj;
        }
        let headers = new HttpHeaders({
          'Content-Type': 'application/json;charset=UTF-8',
          'Authorization': sessionStorage['tokenType'] + " " + sessionStorage['accessToken'],
          'convertLineBreakToCRLF': "true"
        });



        this.http.post(validatorUrl, payload, { headers }).subscribe({
          next: (response: any) => {
            let resJson = response;
            let statusJSON: any = {};
            let parsedPayloadJSON: any = {};
            let errorsJSON: any = {};
            let numberOfErrors = 0;
            // let status = response.currentTarget.status;

            statusJSON['success'] = resJson.success;
            statusJSON['messageId'] = resJson.messageId;
            parsedPayloadJSON['parsedPayload'] = resJson.parsedPayload;
            errorsJSON['errors'] = resJson.errors ? resJson.errors : 0;
            if (errorsJSON.errors == "" || undefined || null) {
              numberOfErrors = 0;
            } else {
              numberOfErrors = errorsJSON['errors']?.length;
            }

            // if (status == 401) {
            //   this.toastr.error('Not a valid authenticated call. Please Login again...');
            //   return;
            // } else {

            document.getElementById("statusOutput").style.display = "block";
            // document.getElementById("errorsOutput").style.display = "block";
            document.getElementById("parsedPayloadOutput").style.display = "block";
            document.getElementById("errorNumbers").style.display = "block";

            const header1 = document.getElementById('headingOne')?.querySelector('button') as HTMLButtonElement;
            header1?.removeAttribute('disabled');
            const header2 = document.getElementById('headingTwo')?.querySelector('button') as HTMLButtonElement;
            header2?.removeAttribute('disabled',);
            const header3 = document.getElementById('headingThree')?.querySelector('button') as HTMLButtonElement;
            header3?.removeAttribute('disabled');

            // collapseOne - Get the collapse element and initialize it and Add event listener to the button
            const collapseOne = document.getElementById('collapseOne');
            this.bsCollapseOne = new Collapse(collapseOne, { toggle: false });
            if (document.getElementById('headingOne')) {
              document.getElementById('headingOne').querySelector('button').addEventListener('click', () => {
                this.bsCollapseOne.toggle();
              });
            }

            // collapseTwo - Get the collapse element and initialize it and Add event listener to the button
            const collapseTwo = document.getElementById('collapseTwo');
            this.bsCollapseTwo = new Collapse(collapseTwo, { toggle: false });
            if (document.getElementById('headingTwo')) {
              document.getElementById('headingTwo').querySelector('button').addEventListener('click', () => {
                this.bsCollapseTwo.toggle();
              });
            }

            // collapseThree - Get the collapse element and initialize it and Add event listener to the button
            const collapseThree = document.getElementById('collapseThree');
            this.bsCollapseThree = new Collapse(collapseThree, { toggle: false });
            if (document.getElementById('headingThree')) {
              document.getElementById('headingThree').querySelector('button').addEventListener('click', () => {
                this.bsCollapseThree.toggle();
              });
            }

            if (numberOfErrors == 0) {
              document.getElementById("errorsOutput").style.display = "none";
              document.getElementById("collapseThree").classList.remove("show");
              const ss = document.getElementById('headingTwo')?.querySelector('button') as HTMLButtonElement;
              ss?.setAttribute('disabled', 'true');
            } else {
              document.getElementById("errorsOutput").style.display = "block";
              // document.getElementById("collapseThree").classList.add("show");
              const ss = document.getElementById('headingTwo')?.querySelector('button') as HTMLButtonElement;
              ss?.removeAttribute('disabled');
            }

            if (statusJSON['success'] == undefined || errorsJSON['errors'] == undefined) {
              this.ValidatorInterface.isDisabled = true;
            } else {
              this.ValidatorInterface.isDisabled = false;
              this.ValidatorInterface.statusOutput = JSON.stringify(statusJSON, undefined, 4);
              this.ValidatorInterface.errorsOutput = JSON.stringify(errorsJSON['errors'], undefined, 4);
              this.ValidatorInterface.parsedPayloadOutput = parsedPayloadJSON.parsedPayload;

              if (this.ValidatorInterface.statusOutput) {
                const statusOutputPanel = document.getElementById('collapseOne');
                if (statusOutputPanel && statusOutputPanel.classList.contains('collapse')) {
                  const bsCollapse = new Collapse(statusOutputPanel, {
                    toggle: true
                  });
                }
              }

              if (this.ValidatorInterface.parsedPayloadOutput == undefined) {
                document.getElementById('headingThree').style.display = 'block';
                document.getElementById("collapseThree").classList.remove("show");
                const payloadThree = document.getElementById('headingThree')?.querySelector('button') as HTMLButtonElement;
                payloadThree?.setAttribute('disabled', 'true');
              } else {

                const payloadThree = document.getElementById('headingThree')?.querySelector('button') as HTMLButtonElement;
                payloadThree?.removeAttribute('disabled');
              }
            }

            if (numberOfErrors == 0) {
              document.getElementById("errorNumbers").style.color = "green";
              document.getElementById("errorNumbers").innerText =
                "(" + numberOfErrors + ")";
            } else {
              document.getElementById("errorNumbers").style.color = "red";
              document.getElementById("errorNumbers").innerText =
                "(" + numberOfErrors + ")";
            }

          },
          error: (err) => {
            this.ValidatorInterface.isDisabled = true;
            // this.toastr.error(err.message);
            this.messageService.add({
              severity: 'error',
              detail: err.message
            });

            /*let statusJSON: any = {};
            let parsedPayloadJSON: any = {};
            let errorsJSON: any = {};
            let numberOfErrors = 0;
 
            statusJSON['success'] = err.error.success;
            statusJSON['messageId'] = err.error.messageId;
            parsedPayloadJSON['parsedPayload'] = err.parsedPayload;
            errorsJSON['errors'] = err.error.errors;
            // if (err.status == 401) {
            //   this.toastr.error('Not a valid authenticated call. Please Login again...');
            //   return;
            // } else {
            if (statusJSON['success'] == undefined || errorsJSON['errors'] == undefined) {
              this.ValidatorInterface.isDisabled = true;
            } else {
              this.ValidatorInterface.statusOutput = JSON.stringify(statusJSON, undefined, 4);
              this.ValidatorInterface.errorsOutput = JSON.stringify(errorsJSON["errors"], undefined, 4);
              this.ValidatorInterface.parsedPayloadOutput = parsedPayloadJSON.parsedPayload;
            }
            // }
            document.getElementById("panelsStayOpen-headingTwo").classList.add("show");
            if (numberOfErrors == 0) {
              document.getElementById("errorNumbers").style.color = "green";
              document.getElementById("errorNumbers").innerText =
                "(" + numberOfErrors + ")";
            } else {
              document.getElementById("errorNumbers").style.color = "red";
              document.getElementById("errorNumbers").innerText =
                "(" + numberOfErrors + ")";
            }*/
          },
        })
        return;
      }
    }
  }

  checkSession() {
    let token = sessionStorage['accessToken'];
    if (token == null) {
      // this.toastr.error("Login Required");
      this.toastService.add({
        type: 'error',
        message: "Login Required"
      });
      this.router.navigate(['/login']);
    }
  }

  isValidFile(fileContent: any): boolean {
    if (fileContent) {
      let allowedExtensions = ['xml', 'txt', 'dat', 'json'];
      let fileExtension = this.ValidatorInterface.dropFileName.split('.').pop().toLowerCase() || this.ValidatorInterface.filename.split('.').pop().toLowerCase();

      if (allowedExtensions.includes(fileExtension)) {
        return true;
      } else {
        this.ValidatorInterface.filename = '';
        this.ValidatorInterface.dropFileName = '';
        return false;
      }
    }
    return false;
  }

  setEditorContent(event: any) {
    if (event == '') {
      this.ValidatorInterface.filename = ''
      this.ValidatorInterface.dropFileName = ''
      this.validator.inputselected = ''
    }
  }

  clearInput() {
    this.fileContent = '';
    this.ValidatorInterface.dropFileName = '';
    this.ValidatorInterface.filename = '';
    this.validator.inputselected = '';
    document.getElementById("fileid").innerHTML = "";
  }



  copyText(containerId: any): void {
    const tooltipAlertBox = document.getElementById('tooltipAlerts');
    if (tooltipAlertBox) {
      tooltipAlertBox.innerHTML = '';

      const containerElement = document.createElement('p');
      containerElement.classList.add('tooltipAlerts');
      containerElement.innerText = 'Copied to Clipboard!';
      tooltipAlertBox.appendChild(containerElement);

      let str = document.getElementById(containerId).innerText;
      navigator.clipboard.writeText(str);

      // const textToCopy = document.getElementById(containerId)?.innerText || '';
      // this.commonService.copyToClipboard(textToCopy);

      setTimeout(() => {
        tooltipAlertBox.innerHTML = '';
      }, 1000);
    }
  }

  downloadContent(containerId: any, fileName: any, fileExtension: any) {
    let text = document.getElementById(containerId).innerText;
    let absoluteFileName = fileName + fileExtension;
    let element = document.createElement('a');
    element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
    element.setAttribute('download', absoluteFileName);
    element.style.display = 'none';
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  }



}


