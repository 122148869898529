<footer class="fixedfooter clearfix  mt-auto py-3 px-3">
    <div *ngIf="showLoginFooter" class="div">
        <span>© 2024 &nbsp;Bank of America Corporation. All rights reserved. CashPro® is a registered trademark of Bank
            of America Corporation in the United States and other countries. </span>
        <div class="text-center">
            <img class="mb-2 mt-2 text-center footer-img" src="assets/themes/images/Footer_img.png"
                alt="Bank of America's" aria-label="Bank Of America's">
        </div>
    </div>
    <div *ngIf="!showLoginFooter" class="div">
        <span>© 2024 &nbsp;Bank of America Corporation. All rights reserved. CashPro® is a registered trademark of Bank
            of America Corporation in the United States and other countries. </span>
    </div>
</footer>