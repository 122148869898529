import { Component, OnInit } from '@angular/core';
import { confirmpwdInterface } from 'src/app/interfaces/login-interface';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { AuthService } from 'src/app/services/auth/auth.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NgForm } from '@angular/forms';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-verify-otp',
  templateUrl: './verify-otp.component.html',
  styleUrls: ['./verify-otp.component.css']
})
export class VerifyOTPComponent implements OnInit {

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    public router: Router,
    private toastr: ToastrService,
    private messageService: MessageService

  ) { }


  ngOnInit(): void {
  }

  confirmData: confirmpwdInterface = {};

  public doReset(ngform: NgForm): void {
    setTimeout(() => {
      document.getElementById('userId').focus();
    }, 1000);

    if (ngform.invalid) {
      for (const control of Object.keys(ngform.controls)) {
        ngform.controls[control].markAsTouched();
      }
      return;
    }

    let finalconfirmData = {
      otp: this.confirmData.otp,
      newPassword: this.confirmData.newPassword,
      userid: sessionStorage.getItem('changePwdUsrNme')
    };

    let encoded_finalDataObj = btoa(JSON.stringify(finalconfirmData));
    if (this.confirmData.newPassword != this.confirmData.confPassword) {
      // this.toastr.error('New Password and Confirm Password does not match.');
      this.messageService.add({
        severity: 'error',
        detail: 'New Password and Confirm Password does not match.'
      });
      return;
    }
    delete this.confirmData.confPassword;

    this.authService.resetPwd(encoded_finalDataObj).subscribe({
      next: (response: any) => {
        let dataSuccess = response;
        // this.toastr.success(dataSuccess.message);
        this.messageService.add({
          severity: 'success',
          detail: dataSuccess.message
        });
        this.router.navigate(['./']);
        setTimeout(() => {
          document.getElementById('userId').focus();
        }, 1000);
      },
      error: (err) => {
        // this.toastr.error(err?.error?.error?.message);
        this.messageService.add({
          severity: 'error',
          detail: err?.error?.error?.message
        });
        sessionStorage.clear();
      },
    });
  }

  cancel() {
    this.router.navigate(['./']);
    setTimeout(() => {
      document.getElementById('userId').focus();
    }, 1000);
  }
}
