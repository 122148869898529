import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import { KeepAliveDialogComponent } from 'src/app/core/keep-alive-dialog/keep-alive-dialog.component';
import { AuthService } from 'src/app/services/auth/auth.service';
import { CommonService } from 'src/app/services/common/common.service';
import { ModalService } from 'src/app/services/common/modal.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.css'],
})
export class MainComponent implements OnInit {
  AuthorizedMenus = {};
  currentUserName: any;
  idleState = 'Not started.';
  timedOut = false;
  lastPing?: Date = null;
  countdown: number = 0;
  @ViewChild('keepAliveModal') keepAliveModal: KeepAliveDialogComponent;
  constructor(
    private authService: AuthService,
    private idle: Idle,
    private keepalive: Keepalive,
    private router: Router,
    private ngbModalServiice: NgbModal,
    private modalService: ModalService,
    private commonService: CommonService,
    private toastr: ToastrService
  ) {

    // Sets an idle timeout of 5 minutes.
    const idleTimeoutLimit = 10; // We should get this value from the Config Settings
    idle.setIdle(idleTimeoutLimit * 60);
    // After 5 minutes of inactivity, the user will be considered timed out.
    idle.setTimeout(15);
    // Sets the default interrupts, in this case, things like clicks, scrolls, touches to the document.
    idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    idle.onIdleEnd.subscribe(() => {
      this.idleState = 'No longer idle.';
      // console.log('OnIdleEnd');
      this.ngbModalServiice.dismissAll();
      this.idle.watch();
    });
    idle.onTimeout.subscribe(() => {
      this.idleState = 'Timed out!';
      this.ngbModalServiice.dismissAll();
      this.modalService.open('sessionExpiredModal');
    });
    idle.onIdleStart.subscribe(() => {
      this.idleState = "You've gone idle!";
      // console.log('OnIdleStart');
    });
    idle.onTimeoutWarning.subscribe((countdown) => {
      // console.log('ready to show the warning dialog', countdown);
      this.countdown = countdown;
      this.idleState = 'You will time out in ' + countdown + ' seconds!';
      this.ngbModalServiice.dismissAll();
      this.openDialog(countdown);
    });
    // Sets the ping interval to 15 seconds.
    keepalive.interval(15);
    keepalive.onPing.subscribe(() => {
      this.lastPing = new Date();
      //console.log('One Last ping');
    });

    this.reset();
  }
  ngOnInit() {
    // console.log(this.authService.loginResp);
    if (
      this.authService.loginResp?.IsAuthorized ||
      sessionStorage.getItem('isAuthorized')
    ) {
      let authMenus =
        this.authService.loginResp?.IsAuthorized ||
        sessionStorage.getItem('isAuthorized');
      this.AuthorizedMenus = JSON.parse(atob(authMenus));
      this.currentUserName = sessionStorage.getItem('username');
      this.reset();
    }
  }
  reset() {
    this.idle.watch();
    this.idleState = 'Started.';
    this.timedOut = false;
    this.ngbModalServiice.dismissAll();
  }
  openDialog(countdown: number): void {
    const modalRef = this.ngbModalServiice.open(KeepAliveDialogComponent);
    modalRef.componentInstance.countdown = countdown;
    modalRef.componentInstance.onStayLoggedIn.subscribe(() => {
      this.reset();
    });
  }
  movetoLoginpage() {
    this.router.navigate(['/login']); // Redirect to login or perform logout.
  }
}
