import {
  AbstractControl,
  FormControl,
  ValidationErrors,
  ValidatorFn,
} from '@angular/forms';
export const passwordMatch: ValidatorFn = (
  control: AbstractControl
): ValidationErrors | null => {
  let password = control.get('newPwd');
  let confirmPassword = control.get('confirmNewPwd');
  if (
    password &&
    confirmPassword &&
    password.value !== confirmPassword?.value
  ) {
    return { passwordMatchError: true };
  }
  return null;
};
