import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Select2Group, Select2Option } from 'ng-select2-component';
import { ToastrService } from 'ngx-toastr';
import { MessageService } from 'primeng/api';
import { accountUnlockInterface } from 'src/app/interfaces/login-interface';
// import { challengeQusInterface } from 'src/app/interfaces/login-interface';
import { AuthService } from 'src/app/services/auth/auth.service';

@Component({
  selector: 'app-account-unlock',
  templateUrl: './account-unlock.component.html',
  styleUrls: ['./account-unlock.component.css']
})
export class AccountUnlockComponent implements OnInit, AfterViewInit {

  data: (Select2Group | Select2Option)[];
  @ViewChild('select2') select2Element: ElementRef;

  accountUnlockData: accountUnlockInterface = {
    items: [],
    myData: [],
    QnsSet: [],
    changingQnset: [],
    slctdVal: [],
    qusAnsFinal: [],
    finaldata: '',
    updateslctdVal: [],
    preQnsSet: []
  };
  accountUnlockDatalist: Array<Select2Option>;


  constructor(
    private authService: AuthService,
    public router: Router,
    private toastr: ToastrService,
    private messageService: MessageService) {
  }


  ngOnInit() {
    this.accountUnlockData.items = [1, 2, 3, 4, 5, 6];
    this.accountUnlockData.myData = [];
    this.accountUnlockData.slctdVal = [];

    this.authService.getsecurityQus().subscribe({
      next: (response: any) => {
        this.accountUnlockData.QnsSet = response;
        this.accountUnlockData.changingQnset = this.accountUnlockData.QnsSet;
        for (let i = 0; i < this.accountUnlockData.items.length; i++) {
          let obj = {
            selVal: {
              Qn: "",
              Ans: ""
            },
            itemQnSet: this.accountUnlockData.QnsSet
          }
          this.accountUnlockData.myData.push(obj);
          // console.log("this.accountUnlockData.myData--", this.accountUnlockData.myData)

          const accoununiqueArr = [];
          this.accountUnlockData.myData[0].itemQnSet.forEach(item => {

            accoununiqueArr.push({ label: item?.question, value: item?.questionId });
          });
          this.accountUnlockDatalist = accoununiqueArr;
          // console.log(accoununiqueArr, "accoununiqueArr");
          this.accountUnlockData.slctdVal.push('');
        }
      },
      error: (err) => {
        // this.toastr.error(err?.error?.error?.message);
        this.messageService.add({
          severity: 'error',
          detail: err?.error?.error?.message
        });
      },
    });
  }

  ngAfterViewInit() {
    // this.select2Element?.nativeElement?.focus();
    // const select2NativeElement = this.select2Element?.nativeElement;
    // select2NativeElement.setAttribute('aria-placeholder', 'Select an option');
  }

  onFocus() {
    // const searchInput = this.select2Element.nativeElement.querySelector('.select2-search__field');
    // if (searchInput) {
    //   searchInput.focus();
    // }
  }

  onEnter() {
    // event.preventDefault(); // Prevent default action to manage the focus properly
    const searchInput = this.select2Element.nativeElement.querySelector('.select2-search__field');
    if (searchInput) {
      searchInput.focus();
    }
  }

  // get Qn() {
  //   return this.challQuesForm.get('Qn')!;
  // }

  // get Ans() {
  //   return this.challQuesForm.get('Ans')!;
  // }

  submitAccountUnlockForm() {
    this.accountUnlockData.qusAnsFinal = [];
    for (let i = 0; i < this.accountUnlockData.myData.length; i++) {
      for (let j = 0; j < this.accountUnlockData.myData[i].itemQnSet.length; j++) {
        if (this.accountUnlockData.myData[i].selVal['Qn'] == this.accountUnlockData.myData[i].itemQnSet[j]['questionId']) {
          this.accountUnlockData.qusAnsFinal.push({
            question: this.accountUnlockData.myData[i].itemQnSet[j]['question'],
            answer: this.accountUnlockData.myData[i].selVal['Ans']
          })
        }
      }
      this.accountUnlockData.finaldata = {
        quAns: this.accountUnlockData.qusAnsFinal
      }
    }
    this.authService.postsecurityQus(this.accountUnlockData.finaldata).subscribe({
      next: (response: any) => {
        let dataSuccess = response;
        // this.toastr.success(dataSuccess.message);
        this.messageService.add({
          severity: 'success',
          detail: dataSuccess.message
        });
        this.router.navigate(['/login']);
      },
      error: (err) => {
        // this.toastr.error(err?.error?.error?.message);
        this.messageService.add({
          severity: 'error',
          detail: err?.error?.error?.message
        });
      },
    });
  }


  AccountUnlockCancel() {
    this.router.navigate(['/login']);
  }

  modifyOtherQtns(item: any, ind: any) {
    //dropdown value is undefined
    if (!item.selVal.Qn) {
      item.selVal.Qn = '';
      this.accountUnlockData.slctdVal[ind] = item.selVal.Qn;
      this.accountUnlockData.changingQnset = this.accountUnlockData.QnsSet.filter(val => !this.accountUnlockData.slctdVal.includes(val.questionId));
      // console.log("1", this.accountUnlockData.changingQnset)

    }
    // Checking selected value is thr r not
    if (item.selVal.Qn && !this.accountUnlockData.slctdVal.includes(item.selVal.Qn)) {
      // $scope.slctdVal.push(item.selVal.Qn);
      this.accountUnlockData.slctdVal[ind] = item.selVal.Qn;
      this.accountUnlockData.changingQnset = this.accountUnlockData.QnsSet.filter(val => !this.accountUnlockData.slctdVal.includes(val.questionId));
      // console.log("2", this.accountUnlockData.changingQnset)


      setTimeout(() => {
        document.querySelectorAll('.select2-selection__clear').forEach((closebtn, index) => {
          // console.log("Close");
          closebtn.setAttribute('tabindex', '0');
          closebtn.setAttribute('aria-label', 'close Button');
          closebtn.removeAttribute('title');
        })
      }, 500)
    }
    //  
    for (let i = 0; i < this.accountUnlockData.myData.length; i++) {
      if (i == ind) {
        continue;
      } else {
        if (!this.accountUnlockData.myData[i].selVal.Qn) {
          this.accountUnlockData.myData[i].itemQnSet = this.accountUnlockData.changingQnset;
          const accoununiqueArr1 = [];
          this.accountUnlockData.myData[i].itemQnSet.forEach(item => {

            accoununiqueArr1.push({ label: item?.question, value: item?.questionId });
          });
          this.accountUnlockDatalist = accoununiqueArr1;
          // console.log(accoununiqueArr1, "accoununiqueArr---after111");
        } else {
          // $scope.myData[i].itemQnSet = $scope.myData[i].itemQnSet.filter(val => val.questionId != item.selVal.Qn);
          // $scope.myData[i].itemQnSet = $scope.changingQnset.filter(val => val.questionId != item.selVal.Qn);

          let drpdwnSel = this.accountUnlockData.QnsSet.filter(val => val.questionId === this.accountUnlockData.myData[i].selVal.Qn)?.[0];

          this.accountUnlockData.myData[i].itemQnSet = this.accountUnlockData.changingQnset.filter(val => val.questionId != item.selVal.Qn);

          // console.log("DDD: ", drpdwnSel)

          this.accountUnlockData.myData[i].itemQnSet.push(drpdwnSel);

          const accoununiqueArr1 = [];
          this.accountUnlockData.myData[i].itemQnSet.forEach(item => {

            accoununiqueArr1.push({ label: item?.question, value: item?.questionId });
          });
          this.accountUnlockDatalist = accoununiqueArr1;
          // console.log(accoununiqueArr1, "accoununiqueArr---after222");


          // console.log("testing", this.accountUnlockData.myData[i].itemQnSet)
        }
      }
    }
  }


}
