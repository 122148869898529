/// <reference types="@angular/localize" />

import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
// import 'codemirror/lib/codemirror';
// import 'codemirror/mode/sql/sql';
// import 'codemirror/addon/edit/matchbrackets';
// import 'codemirror/addon/hint/show-hint';
// import 'codemirror/addon/hint/sql-hint';

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));

  // platformBrowserDynamic().bootstrapModule(AppModule).then(ref => {
  //   // Ensure Angular destroys itself on hot reloads.
  //   if (window['ngRef']) {
  //     window['ngRef'].destroy();
  //   }
  //   window['ngRef'] = ref;
  
  //   // Otherwise, log the boot error
  // }).catch(err => console.error(err));