<body class="d-flex flex-column h-100">
    <main class="clearfix pb-5 flex-fill container-fluid">

        <div class="mt-2 position-relative h-100 pb-5 ng-scope">
            <div class="row m-2">

                <div class="col-md-4 mb-3">
                    <div class="card border-0 rounded-0 shadow-lg bg-light h-100">
                        <div class="card-body p-3 pt-4">
                            <form id="verifyotp" #verifyotp="ngForm" name="verifyotp" class="col-12" #form="ngForm"
                                autocomplete="off">
                                <div class="form-group m-3">
                                    <div class="input-group"> <label id="secuotp" for="securityToken">One Time
                                            Password</label> </div>
                                    <div class="input-group">
                                        <input tabindex="0" type="text" id="securityToken" name="securityToken"
                                            class="form-control rounded-0" #securityToken="ngModel"
                                            [(ngModel)]="confirmData.otp" required placeholder="Please enter the OTP"
                                            aria-labelledby="secuotp"
                                            [attr.aria-describedby]="securityToken.invalid && (securityToken.dirty || securityToken.touched) && securityToken.errors?.['required'] ? 'err1' : ''"
                                            [class.is-invalid]="securityToken.invalid && (securityToken.dirty || securityToken.touched)">
                                    </div>

                                    <div *ngIf="securityToken.invalid && (securityToken.dirty || securityToken.touched) && securityToken.errors?.['required']"
                                        class="help-block text-danger" id="err1">
                                        <span>* OTP is required </span>
                                    </div>
                                </div>
                                <div class="form-group m-3">
                                    <div class="input-group"> <label id="secunewpwd" for="newPassword">New
                                            Password</label> </div>
                                    <div class="input-group"> <input tabindex="0" type="password" id="newPassword"
                                            name="newPassword" #newPassword="ngModel" class="form-control rounded-0"
                                            [(ngModel)]="confirmData.newPassword" required
                                            placeholder="Please enter the New Password" aria-labelledby="secunewpwd"
                                            [attr.aria-describedby]="newPassword.invalid && (newPassword.dirty || newPassword.touched) && newPassword.errors?.['required'] ? 'err2' : ''"
                                            [class.is-invalid]="newPassword.invalid && (newPassword.dirty || newPassword.touched)">
                                    </div>
                                    <div *ngIf="newPassword.invalid && (newPassword.dirty || newPassword.touched) && newPassword.errors?.['required']"
                                        class="help-block text-danger" id="err2">
                                        <span>* New Password is required </span>
                                    </div>
                                </div>
                                <div class="form-group m-3">
                                    <div class="input-group"> <label id="secuconpwd" for="confPassword">Confirm
                                            Password</label> </div>
                                    <div class="input-group"> <input tabindex="0" type="password" id="confPassword"
                                            name="confPassword" #confPassword="ngModel" class="form-control rounded-0"
                                            [(ngModel)]="confirmData.confPassword" required
                                            placeholder="Please enter the Confirm Password" aria-labelledby="secuconpwd"
                                            [attr.aria-describedby]="confPassword.invalid && (confPassword.dirty || confPassword.touched) && confPassword.errors?.['required'] ? 'err3' : ''"
                                            [class.is-invalid]="confPassword.invalid && (confPassword.dirty || confPassword.touched)">
                                    </div>
                                    <div *ngIf="confPassword.invalid && (confPassword.dirty || confPassword.touched) && confPassword.errors?.['required']"
                                        class="help-block text-danger" id="err3">
                                        <span>* Confirm Password is required </span>
                                    </div>
                                </div>
                                <div class="d-flex">
                                    <div class="form-group m-3">
                                        <div> <button type="submit" tabindex="0" title="Reset"
                                                class=" btn btn-primary rounded-0" [disabled]="!verifyotp.form.valid"
                                                (click)="doReset(form)">Reset</button>
                                        </div>
                                    </div>
                                    <div class="px-form-group m-3 d-flex align-items-center">
                                        <div> <button type="button" tabindex="0" title="Cancel"
                                                class=" btn btn-primary rounded-0" (click)="cancel()">Cancel</button>
                                        </div>
                                    </div>
                                </div>

                            </form>
                        </div>
                    </div>
                </div>

                <div class="col-md-8 mb-3">
                    <div class="card border-0  shadow-lg bg-light h-100">
                        <div class="card-body p-3">
                            <h1 class="h1font">About CashPro® Validator</h1>
                            <span class="h1font">CashPro® Validator is a complimentary tool that enables you to validate
                                payment and
                                collection files as part of the on-boarding process. Key features include:
                            </span>
                            <br>
                            <span class="h1font">
                                <ul class="list-unstyled px-5">
                                    <li class="listyle">Format validation against comprehensive Straight Through
                                        Processing
                                        (STP)
                                        rules
                                    </li>
                                    <li>Instant test results for format and STP issues</li>
                                    <li>On-screen editing for immediate re-validation</li>
                                    <li>Dashboard summary for previous requests up to 90 days</li>
                                </ul>
                            </span>
                            <br>
                            <span class="h1font">If you would like more information about CashPro® Validator, please
                                contact
                                your
                                Bank of
                                America representative.
                            </span>
                            <span class="h1font">For the best overall performance we recommend using Google
                                Chrome.</span>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Newly added for modal remove it once it is used  -->
            <!-- <button type="button" class="btn btn-primary me-2 rounded-0" data-bs-toggle="modal"
                data-bs-target="#Challenge-Questions">
                Challenge Questions
            </button>
            <button type="button" class="btn btn-primary me-2 rounded-0" data-bs-toggle="modal"
                data-bs-target="#Session-Expired">
                Session Expired
            </button> -->

            <!-- <div class="modal fade modal-lg" id="Challenge-Questions" tabindex="-1"
                aria-labelledby="Challenge-Questions-Label" aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h1 class="modal-title fs-5" id="Challenge-Questions-Label">Challenge Questions</h1>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <h6>For security, verify your identity by answering one challenge Questions.</h6>

                            <div class="form-group mb-3">
                                <div class="input-group mb-2">
                                    <label for="City">1. In what city did you meet your first Spouse/significant
                                        other
                                        ?</label>
                                </div>
                                <div class="input-group">
                                    <input tabindex="0" type="text" required="" placeholder="Enter your answer"
                                        class="form-control rounded-0">
                                </div>

                            </div>


                            <div class="form-group">
                                <div class="input-group mb-2">
                                    <label for="City"> 2. Please enter OTP sent through mail</label>
                                </div>
                                <div class="input-group">
                                    <input tabindex="0" type="text" required="" placeholder="Enter your OTP"
                                        class="form-control rounded-0">
                                </div>

                            </div>




                        </div>
                        <div class="modal-footer">

                            <button type="submit" class=" h-btn btn btn-primary border-0">Sign In</button>


                        </div>
                    </div>
                </div>
            </div> -->
            <div class="modal fade" id="Session-Expired" tabindex="-1" aria-labelledby="Session-Expired-Label"
                aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h1 class="modal-title fs-5" id="Session-Expired-Label">Session Expired</h1>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body Session-Expired row">
                            <div class="col-md-2">
                                <h1> <i class="fas fa-exclamation-triangle text-danger"></i></h1>
                            </div>
                            <div class="col-md-10">
                                <p>
                                    Your session expired due to inactivity.<br>
                                    Please sign out and sign in again.
                                </p>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="submit" class=" h-btn btn btn-primary border-0">Sign Out</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </main>
</body>