import { Injectable } from '@angular/core';
import { StandaloneModalComponent } from 'src/app/core/standalone-modal/standalone-modal.component';
@Injectable({
  providedIn: 'root'
})
export class StmodalService {
  private smodals: StandaloneModalComponent[] = [];
  add(modal: StandaloneModalComponent) {
    // ensure component has a unique id attribute
    if (!modal.id || this.smodals.find((x) => x.id === modal.id)) {
      throw new Error('modal must have a unique id attribute');
    }

    // add modal to array of active modals
    this.smodals.push(modal);
  }

  remove(modal: StandaloneModalComponent) {
    // remove modal from array of active modals
    this.smodals = this.smodals.filter((x) => x !== modal);
  }

  open(id: string) {
    // open modal specified by id
    const modal = this.smodals.find((x) => x.id === id);

    if (!modal) {
      throw new Error(`modal '${id}' not found`);
    }

    modal.open();
  }

  close() {
    // close the modal that is currently open
    const modal = this.smodals.find((x) => x.isOpen);
    modal?.close();
  }
  constructor() { }
}
