import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpHeaders,
  HttpResponse,
} from '@angular/common/http';
import { finalize, Observable, tap } from 'rxjs';
import { LoaderService } from '../LoaderService/loader.service';


@Injectable()
export class HttpinterceptorInterceptor implements HttpInterceptor {
  constructor(private loaderService: LoaderService) { }

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    this.loaderService.show();
    // console.log('Http Interceptor called..', request.url);
    request.headers.set('Content-Type', 'application/json;charset=UTF-8');
    // const authToken = sessionStorage.getItem('tokenType') + " " + sessionStorage.getItem('accessToken');
    // request.headers.set('Authorization', authToken);
    // if(sessionStorage.getItem('isAuthorized')){

    // }
    const authToken = sessionStorage.getItem('tokenType') + " " + sessionStorage.getItem('accessToken'); // get the value from the Session/Temp storage and assign
    //Have to
    const modifiedReq = request.clone({
      setHeaders: {
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: authToken,
      },
    });
    return next.handle(modifiedReq).pipe(
      tap(event => {
        if (event instanceof HttpResponse) {
          // You can handle any response processing here if needed
          // console.log('Response received:', event.url);
        }
      }),
      finalize(() => {
        this.loaderService.hide();
      })
    );;
  }
}
