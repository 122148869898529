<ng-container  *ngIf="action != 'view'">
    <app-forms  [jsonFormData]="formData" [method]="action" [modulename]="name" [disflag]="checkEditFlag" 
    [editdata] = "editformData" (actionEvent)="reload($event)" [buttflag]="unlockbuttonFlag" (unlockEvent)="unlockUseraccount($event)"></app-forms>
</ng-container>

<ng-container *ngIf="action == 'view' || action == 'edit'">

  <div class="container mb-10">
    <div class="card flex-fill mt-3 mb-3 clearfix rounded-0">
      <div class="card-header">
        <span *ngIf="action == 'edit' && name == 'client'">
          <h5 tabindex="0" class="d-inline-block card-title mb-0 text-capitalize">Created Clients</h5>
        </span>
        <span *ngIf="action == 'edit' && name == 'users'">
          <h5 tabindex="0" class="d-inline-block card-title mb-0 text-capitalize">Created Users</h5>
        </span>
        <h5 class="p-1 mb-0 text-capitalize" tabindex="0" *ngIf="action == 'view'">
          <span *ngIf="name == 'clients'">List of Client IDs</span>
          <span *ngIf="name == 'users'">List of User IDs</span>
        </h5>
      </div>
      <div class="card-body p-1 ">
        <div *ngIf="ClientListData?.length" class="mb-2">
          <div class="row">
            <div class="col d-flex align-items-center px-4">
              <div class="caption" *ngIf="name == 'clients'&& action == 'view'">
                List of Client IDs / Total Items: {{ClientListData?.length}} (Showing Items: {{ClientListData?.length}})
              </div>
              <div class="caption" *ngIf="action =='edit' && name == 'client'">List of created client / Total Items:
                {{ClientListData?.length}} (Showing Items: {{ClientListData?.length}})
              </div>
              <div class="caption" *ngIf="name == 'users' && action == 'view'">
                List of User IDs / Total Items: {{ClientListData?.length}} (Showing Items: {{ClientListData?.length}})
              </div>
              <div class="caption" *ngIf="action =='edit' && name == 'users'">List of created users / Total Items:
                {{ClientListData?.length}} (Showing Items: {{ClientListData?.length}})
              </div>
            </div>
            <div class="col">
              <div>
                <div class="form-group pt-1 pb-1">
                  <div class="col-md-12">
                    <form class="navbar-form" role="search">
                      <div class="input-group add-on">
                        <input class="form-control rounded-0" placeholder="Click search icon to search all pages"
                          [(ngModel)]="searchValue" name="srch-term" id="srch-term" type="text" />
                        <div class="input-group-btn z-0">
                          <button (click)="loadDetailsByKey()" class="btn btn-default rounded-0 border" aria-label="Search for details"><i
                              class="fas fa-search" aria-hidden="true"></i></button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>

          </div>
          <hr class="my-1" role="presentation">
          <div>
            <div class="col-md-12 overflow-auto calc-height">
              <app-table [tableInfo]="tableHeaderData" [tableDataToDisplay]="ClientListData" [searchKey]="searchValue"
                (editEventHandle)="navigateToCrud($event)"
                (deleteEventHandle)="navigateTocrudForDelete($event)"></app-table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</ng-container>
<ng-container>
  <!-- Delete Modal-->
  <app-standalone-modal id="delete_modal" style="display: none;">
    <div class="modal-header">
      <h4 class="modal-title " id="deletepopup">Are you sure you want to delete?</h4>
      <button type="button" class="btn-close" data-bs-dismiss="modal" (click)="modalService.close();"
        aria-label="Close"></button>
    </div>
    <div class="modal-body">
      <button class="btn btn-h btn-primary vol-button rounded-0 border me-3" (click)="delteData(name)">
        Confirm
      </button>
      <button class="h-btn btn btn-secondary rounded-0 border " (click)="modalService.close();" type="button">
        Cancel
      </button>
    </div>
  </app-standalone-modal>
</ng-container>

<ng-container>	
	<!-- unlock Modal-->
	<app-standalone-modal id="unlock_modal" style="display: none;">
	   <div class="modal-header">
		   <h4 class="modal-title " id="unlockpopup">Are you sure you want to unlock the user?</h4>
		   <button type="button" class="btn-close" data-bs-dismiss="modal" (click)="modalService.close();"
			   aria-label="Close"></button>
	   </div>
	   <div class="modal-body">
		   <button class="btn btn-h btn-primary vol-button rounded-0 border me-3" (click)="confirmUnlock()">
			   Confirm
			 </button>
			 <button class="h-btn btn btn-secondary rounded-0 border "(click)="modalService.close();"  type="button">
			   Cancel
			 </button>
	   </div>
	</app-standalone-modal>
</ng-container>