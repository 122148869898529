import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { Select2, Select2Module } from 'ng-select2-component';
import { TableComponent } from 'src/app/core/table/table.component';
import { AuthService } from 'src/app/services/auth/auth.service';
@Component({
  selector: 'app-test',
  templateUrl: './test.component.html',
  standalone: true,
  styleUrls: ['./test.component.css'],
  imports: [TableComponent, Select2Module],
})
export class TestComponent {
  data: any;
  constructor(private http: HttpClient, private authService: AuthService) {
    this.data = [
      {
        label: 'Red',
        data: {
          name: '(Red)',
        },
        options: [
          {
            value: 'hibiscus',
            label: 'Hibiscus',
            data: {
              color: 'red',
              name: 'Hibiscus',
            },
            templateId: 'template1',
            id: 'option-hibiscus',
          },
          {
            value: 'marigold',
            label: 'Marigold',
            data: {
              color: 'red',
              name: 'Marigold',
            },
            templateId: 'template2',
            id: 'option-marigold',
          },
        ],
      },
      {
        label: 'Yellow',
        data: {
          name: '(Yellow)',
        },
        options: [
          {
            value: 'sunflower',
            label: 'Sunflower',
            data: {
              color: 'yellow',
              name: 'Sunflower',
            },
            templateId: 'template3',
            id: 'option-sunflower',
          },
        ],
      },
      {
        label: 'White',
        data: {
          name: '(White)',
        },
        options: [
          {
            value: 'heliotrope',
            label: 'Heliotrope',
            data: {
              color: 'white',
              name: 'Heliotrope',
            },
            templateId: 'template1',
            id: 'option-heliotrope',
          },
          {
            value: 'lily',
            label: 'Lily',
            data: {
              color: 'white',
              name: 'Lily',
            },
            templateId: 'template2',
            id: 'option-lily',
          },
          {
            value: 'petunia',
            label: 'Petunia',
            data: {
              color: 'white',
              name: 'Petunia',
            },
            templateId: 'template3',
            id: 'option-petunia',
          },
        ],
      },
    ];
  }
}
