import { CommonModule } from '@angular/common';
import { Component, OnInit, NO_ERRORS_SCHEMA } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { TableComponent } from 'src/app/core/table/table.component';
import { DashboardService } from 'src/app/services/dashboard/dashboard.service';
import { DropdownModule } from 'primeng/dropdown';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css'],
  standalone: true,
  imports: [TableComponent, CommonModule, FormsModule, DropdownModule],
  schemas: [NO_ERRORS_SCHEMA],
})
export class DashboardComponent implements OnInit {
  dashboardCountData: any = {};
  dashboardTableData: any = {};
  dashBoardData: any = [];
  dashBoardFilter: string = 'all';
  keywordSearchValue: string = '';
  reportDurationData: any = [];
  constructor(
    private dashboardService: DashboardService,
    private router: Router
  ) {
    this.reportDurationData = [
      {
        label: 'ALL',
        value: 'all',
      },
      {
        label: 'Current Day',
        value: 'Current Day',
      },
      {
        label: 'Previous Day',
        value: 'Previous Day',
      },
      {
        label: 'Current Week',
        value: 'Current Week',
      },
      {
        label: 'Current Month',
        value: 'Current Month',
      },
      {
        label: 'Previous 90 Days',
        value: 'Previous 90 Days',
      },
      {
        label: 'customRage',
        value: 'customRage',
      },
    ];
  }
  ngOnInit(): void {
    let defaulfilterVal: string = 'all';
    this.loadDashboardDetails(defaulfilterVal);
  }
  loadDashboardDetails(filterVal?: any) {
    this.dashBoardData = [];
    this.dashboardService.getDashboardDetails(filterVal).subscribe({
      next: (response: any) => {
        // console.log(response, 'Response came');
        this.dashboardTableData['headers'] = [
          { label: 'Client ID', headerKey: 'clientid' },
          { label: 'Region', headerKey: 'region' },
          { label: 'Format', headerKey: 'MessageType' },
          {
            label: 'Total # of Requests processed',
            headerKey: 'NoOfMessage',
            details: { navigationLink: 'detailsPage' },
          },
          {
            label: '# of Successful Requests',
            headerKey: 'Success',
            details: { navigationLink: 'detailsPage' },
          },
          {
            label: '# of Failed Requests',
            headerKey: 'Failure',
            details: { navigationLink: 'detailsPage' },
          },
          // { label: 'Actions', actions: ['edit', 'delete'] },
        ]; // setting of the Table Columns
        this.dashBoardData = response?.DashBoard;
        this.dashboardCountData = response;
      },
      error: (err: any) => {
        /* console.log(err);
        this.dashboardTableData['headers'] = [
          { label: 'Client ID', headerKey: 'clientid' },
          { label: 'Region', headerKey: 'region' },
          { label: 'Format', headerKey: 'MessageType' },
          { label: 'Total # of Requests processed', headerKey: 'NoOfMessage' },
          { label: '# of Successful Requests', headerKey: 'Success' },
          { label: '# of Failed Requests', headerKey: 'Failure' },
        ]; // setting of the Table Columns
        let response = {
          success: true,
          DashBoard: [
            {
              region: 'ALL',
              clientid: 'ADMIN',
              MessageType: 'ARP',
              NoOfMessage: 2,
              Success: 0,
              Failure: 2,
            },
            {
              region: 'ALL',
              clientid: 'ADMIN',
              MessageType: 'BULKCSV',
              NoOfMessage: 3,
              Success: 1,
              Failure: 2,
            },
            {
              region: 'ALL',
              clientid: 'ADMIN',
              MessageType: 'CHECK ISSUE (ARP)',
              NoOfMessage: 21,
              Success: 10,
              Failure: 11,
            },
            {
              region: 'ALL',
              clientid: 'ADMIN',
              MessageType: 'CNAB240',
              NoOfMessage: 10,
              Success: 0,
              Failure: 10,
            },
            {
              region: 'ALL',
              clientid: 'ADMIN',
              MessageType: 'DIGITAL DISBURSEMENT',
              NoOfMessage: 1,
              Success: 0,
              Failure: 1,
            },
            {
              region: 'ALL',
              clientid: 'ADMIN',
              MessageType: 'INACH',
              NoOfMessage: 8,
              Success: 1,
              Failure: 7,
            },
            {
              region: 'ALL',
              clientid: 'ADMIN',
              MessageType: 'ISO 20022',
              NoOfMessage: 10,
              Success: 4,
              Failure: 6,
            },
            {
              region: 'ALL',
              clientid: 'ADMIN',
              MessageType: 'NACHA',
              NoOfMessage: 34,
              Success: 6,
              Failure: 28,
            },
            {
              region: 'ALL',
              clientid: 'BOFA',
              MessageType: 'ARP',
              NoOfMessage: 2,
              Success: 2,
              Failure: 0,
            },
            {
              region: 'ALL',
              clientid: 'BOFA',
              MessageType: 'BULKCSV',
              NoOfMessage: 7,
              Success: 0,
              Failure: 7,
            },
            {
              region: 'ALL',
              clientid: 'BOFA',
              MessageType: 'CRF',
              NoOfMessage: 1,
              Success: 0,
              Failure: 1,
            },
            {
              region: 'ALL',
              clientid: 'BOFA',
              MessageType: 'INACH',
              NoOfMessage: 6,
              Success: 3,
              Failure: 3,
            },
            {
              region: 'ALL',
              clientid: 'BOFA',
              MessageType: 'ISO 20022',
              NoOfMessage: 111,
              Success: 0,
              Failure: 111,
            },
            {
              region: 'ALL',
              clientid: 'BOFA',
              MessageType: 'NACHA',
              NoOfMessage: 28,
              Success: 0,
              Failure: 28,
            },
          ],
          TotalValidationRequests: 244,
          Successfull: 27,
          Failure: 217,
        };
        this.dashBoardData = response?.DashBoard;
        this.dashboardCountData = response; */
      },
    });
  }

  loadDashboardDetailsByKey() {
    let payloadObj = {
      ReportDuration: this.dashBoardFilter,
      Keyword: this.keywordSearchValue,
    };
    this.dashboardService.getDashboardDetailsByKey(payloadObj).subscribe({
      next: (response: any) => {
        this.dashBoardData = response?.DashBoard;
        this.dashboardCountData = response;
      },
      error: (err: any) => {
        //console.log(err);
      },
    });
  }

  navigateToDetailsPage(eventEmitData: any) {
    // console.log('Ready to navigate the Details page', eventEmitData);
    this.router.navigateByUrl('app/dashboard-details', {
      state: eventEmitData,
    });
  }
}
