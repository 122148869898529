<nav class="navbar navbar-expand-lg navbar-light bg-white">
   <div class="container-fluid ">
      <button class="navbar-toggler border-0" type="button" data-bs-toggle="collapse">
         <span class="navbar-toggler-icon" data-bs-toggle="offcanvas" href="#offcanvasExample" role="button"
            aria-controls="offcanvasExample"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
         <ul class="navbar-nav me-auto mb-2 mb-lg-0  mx-3">
            <li class="nav-item dropdown pr " *ngFor="let menu of menuItems">
               <a tabindex="0" class="nav-link" aria-current="page"
                  [routerLink]="(menu.name === 'admin' ? '' : menu.link)" routerLinkActive="active">{{menu.name}}</a>
               <ul *ngIf="menu.children" class="dropdown-menu" aria-labelledby="navbarDropdown">
                  <li class="nav-item dropdown admin" *ngFor="let submenu of menu.children">
                     <a tabindex="0" class="nav-link dropdown-toggle border-bottom" role="button" aria-current="page"
                        aria-expanded="false" [routerLink]="[submenu.link]"
                        routerLinkActive="active">{{submenu.name}}</a>
                  </li>
               </ul>
            </li>
            <li class="nav-item dropdown admin">
               <a class="nav-link dropdown-toggle" role="button" aria-expanded="false" tabindex="0"
                  (keydown.enter)="toggleDropdown($event)">
                  Profile
               </a>
               <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                  <li tabindex="0"><a class="nav-link dropdown-toggle border-bottom"
                        (click)="openProfileModal()">{{username}}</a>
                  </li>
                  <li tabindex="0"><a class="nav-link dropdown-toggle border-bottom"
                        (click)="openChangePwdModal()">Change
                        Password</a></li>

                  <li tabindex="0" *ngIf="switchRole"><a class="nav-link dropdown-toggle border-bottom"
                        (click)="showRoleDetails()">Switch Role</a></li>
                  <li tabindex="0" *ngIf="updateUnlock"><a class="nav-link dropdown-toggle border-bottom"
                        (click)="openupdateUnlock()">Update Challenge
                        Questions</a>
                  </li>
                  <span><a class="nav-link dropdown-toggle">Version &nbsp;{{version}}</a></span>
               </ul>
            </li>
            <li class="nav-item">
               <a tabindex="0" class="nav-link" (click)="signOut()">Sign out</a>
            </li>
         </ul>
      </div>
   </div>
</nav>
<app-modal id="profileModal" style="display: none;">
   <div class="modal-header">
      <h5 class="modal-title">Current User - {{username}}</h5>
      <button type="button" class="btn-close" (click)="modalService.close();"></button>
   </div>
   <div class="modal-body">
      <table class="table modal-table table-bordered">
         <tbody>
            <tr>
               <td>Client ID</td>
               <td>{{currentLoggedInUserDetails?.ClientId}}</td>
            </tr>
            <tr>
               <td>Entitlement</td>
               <td>{{currentLoggedInUserDetails?.Entitlement}}</td>
            </tr>
            <tr>
               <td>First Name</td>
               <td>{{currentLoggedInUserDetails?.FirstName}}</td>
            </tr>
            <tr>
               <td>Last Name</td>
               <td>{{currentLoggedInUserDetails?.LastName}}</td>
            </tr>
            <tr>
               <td>Email Address</td>
               <td>{{currentLoggedInUserDetails?.Email}}</td>
            </tr>
         </tbody>
      </table>
   </div>
</app-modal>

<app-modal id="ChangePwdModal" style="display: none;">
   <form [formGroup]="changePwdForm" (ngSubmit)="changePwdFormSubmit()">
      <div class="modal-header">
         <h1 class="modal-title fs-5" id="change-passswordLabel">Change Password </h1>
         <button type="button" class="btn-close" data-bs-dismiss="modal" (click)="modalService.close();"
            aria-label="Close"></button>
      </div>
      <div class="modal-body">
         <div class="form-group mt-2">
            <h6>
               <p tabindex="0">Asterisk items <span class="text-danger">*</span> are required to proceed.</p>
            </h6>
         </div>

         <div class="form-group">
            <div class="input-group">
               <label for="Current-Password" class="mb-1">Current Password <span class="text-danger">*</span></label>
            </div>
            <div class="input-group">
               <input name="" tabindex="0" class="form-control rounded-0" formControlName="currentPwd" type="password"
                  [ngClass]="(this.changePwdForm.get('currentPwd')?.dirty || this.changePwdForm.get('currentPwd')?.touched)  && this.changePwdForm.get('currentPwd')?.invalid ?'has-error' : ''"
                  placeholder="Enter your current paswword">
            </div>
            <div class="error-message mt-1"
               *ngIf="this.changePwdForm.get('currentPwd')?.invalid && (this.changePwdForm.get('currentPwd')?.dirty || this.changePwdForm.get('currentPwd')?.touched) && this.changePwdForm.get('currentPwd')?.errors?.['required']">
               <svg aria-hidden="true" class="svg-inline--fa fa-minus-circle fa-w-16 text-error" focusable="false"
                  data-prefix="fa" data-icon="minus-circle" role="img" xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512" data-fa-i2svg="">
                  <path fill="currentColor"
                     d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zM124 296c-6.6 0-12-5.4-12-12v-56c0-6.6 5.4-12 12-12h264c6.6 0 12 5.4 12 12v56c0 6.6-5.4 12-12 12H124z">
                  </path>
               </svg>
               Current Password is
               required.
            </div>
         </div>
         <div class="form-group mt-3">
            <div class="input-group">
               <label for="New-Password" class="mb-1">New Password <span class="text-danger">*</span></label>
            </div>
            <div class="input-group">
               <input name="" tabindex="0" class="form-control rounded-0" type="password" formControlName="newPwd"
                  placeholder="Enter your New paswword"
                  [ngClass]=" (this.changePwdForm.get('newPwd')?.dirty || this.changePwdForm.get('newPwd')?.touched) && this.changePwdForm.get('newPwd')?.invalid ? 'has-error' : ''">
            </div>
            <div class="error-message mt-1"
               *ngIf="this.changePwdForm.get('newPwd')?.invalid && (this.changePwdForm.get('newPwd')?.dirty || this.changePwdForm.get('newPwd')?.touched) && this.changePwdForm.get('newPwd')?.errors?.['required']">
               <svg aria-hidden="true" class="svg-inline--fa fa-minus-circle fa-w-16 text-error" focusable="false"
                  data-prefix="fa" data-icon="minus-circle" role="img" xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512" data-fa-i2svg="">
                  <path fill="currentColor"
                     d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zM124 296c-6.6 0-12-5.4-12-12v-56c0-6.6 5.4-12 12-12h264c6.6 0 12 5.4 12 12v56c0 6.6-5.4 12-12 12H124z">
                  </path>
               </svg>
               New Password is
               required.
            </div>
         </div>
         <div class="form-group mt-3">
            <div class="input-group">
               <label for="New-Password" class="mb-1">Re-enter Password <span class="text-danger">*</span></label>
            </div>
            <div class="input-group">
               <input name="" tabindex="0" class="form-control rounded-0"
                  [ngClass]="(this.changePwdForm.get('confirmNewPwd')?.dirty || this.changePwdForm.get('confirmNewPwd')?.touched) && (this.changePwdForm.get('confirmNewPwd')?.invalid ) ? 'has-error' : ''"
                  formControlName="confirmNewPwd" type="password" placeholder="Re-enter Password">
            </div>
            <div class="error-message mt-1"
               *ngIf="this.changePwdForm.get('confirmNewPwd')?.invalid && (this.changePwdForm.get('confirmNewPwd')?.dirty || this.changePwdForm.get('confirmNewPwd')?.touched) && this.changePwdForm.get('confirmNewPwd')?.errors?.['required']">
               <svg aria-hidden="true" class="svg-inline--fa fa-minus-circle fa-w-16 text-error" focusable="false"
                  data-prefix="fa" data-icon="minus-circle" role="img" xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512" data-fa-i2svg="">
                  <path fill="currentColor"
                     d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zM124 296c-6.6 0-12-5.4-12-12v-56c0-6.6 5.4-12 12-12h264c6.6 0 12 5.4 12 12v56c0 6.6-5.4 12-12 12H124z">
                  </path>
               </svg>
               Re-enter Password is
               required.
            </div>

            <div class="error-message mt-1"
               *ngIf="(((this.changePwdForm.get('newPwd')?.dirty || this.changePwdForm.get('newPwd')?.touched)) && (this.changePwdForm.get('confirmNewPwd')?.dirty || this.changePwdForm.get('confirmNewPwd')?.touched)) && this.changePwdForm.errors?.['passwordMatchError']">
               <svg aria-hidden="true" class="svg-inline--fa fa-minus-circle fa-w-16 text-error" focusable="false"
                  data-prefix="fa" data-icon="minus-circle" role="img" xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512" data-fa-i2svg="">
                  <path fill="currentColor"
                     d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zM124 296c-6.6 0-12-5.4-12-12v-56c0-6.6 5.4-12 12-12h264c6.6 0 12 5.4 12 12v56c0 6.6-5.4 12-12 12H124z">
                  </path>
               </svg>
               password and confirm password is mismatch.
            </div>
         </div>
      </div>
      <div class="modal-footer justify-content-start">
         <button type="submit" class=" h-btn btn btn-primary rounded-0"
            [disabled]="this.changePwdForm.invalid">Update</button>
         <button type="button" class="h-btn btn btn-secondary rounded-0" (click)="resetChangePwdForm()">Reset</button>
         <a class="btn-link mx-4" data-bs-target="#exampleModalToggle" data-bs-toggle="modal"
            (click)="modalService.close();">Close</a>
      </div>
   </form>
</app-modal>

<app-modal id="switchRoleModal" style="display: none;">
   <div class="modal-header">
      <h5 class="modal-title">SwitchRole - {{username}} </h5>
      <button type="button" class="btn-close" (click)="modalService.close();"></button>
   </div>
   <div class="modal-body">

      <!-- <div *ngIf="roleLists?.length == 0" style="font-family: ConnectionsRegular, sans-serif;
                     color: #012169;text-align:center;font-size: larger">No Records Found</div> -->
      <!-- *ngIf="roleLists?.length > 0" -->
      <form #switchroleModalForm="ngForm" (ngSubmit)="submitSwitchRole(roles.roleName)">
         <div class="form-group mb-3">
            <label for="role" id="rolename">Role Name</label>
            <!-- <select class="form-control rounded-0 mt-2 custom-select-apparance" name="role" id="role"
               placeholder="Choose Role Name" [(ngModel)]="roleLists" #switchrole="ngModel" aria-labelledby="rolename"
               [attr.aria-describedby]="'err1'">
               <option selected [ngValue]="">Choose Role Name</option>
               <option *ngFor="let role of roleLists" [ngValue]="role.roleName">{{ role.roleName }}</option>
            </select> -->

            <p-dropdown [options]="roleLists" [filter]="true" filterBy="label" [showClear]="true"
               [(ngModel)]="roles.roleName" [ngModelOptions]="{standalone: true}" placeholder="Choose Role Name"
               styleClass="full-width-dropdown"></p-dropdown>
            <!-- <div *ngIf="switchrole.touched && switchrole.errors?.['required']" class="help-block text-danger" id="err1">
               <span>* This value is required</span>
            </div> -->
         </div>
         <div class="modal-footer">
            <button type="submit" class="h-btn btn btn-primary rounded-0 mx-2 ms-0">Submit</button>
            <button type="reset" class="h-btn btn btn-secondary rounded-0" (click)="closeDownload()">Close</button>
         </div>
      </form>
   </div>
</app-modal>


<div class="offcanvas offcanvas-start" tabindex="-1" id="offcanvasExample" aria-labelledby="offcanvasExampleLabel">
   <div class="offcanvas-header">
      <h5 class="offcanvas-title" id="offcanvasExampleLabel">Menu</h5>
      <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
   </div>
   <div class="offcanvas-body">
      <h3 class="cashpro-span">
         CashPro® Validator
      </h3>

      <ul class="list-group list-group-flush sidebar-menu-item list-unstyled" role="menu" id="navbarMenu">
         <li class="p-0 border-0 profile2" *ngFor="let menu of menuItems">
            <ul class="list-group list-group-flush sidebar-menu-item list-unstyled ">
               <li *ngIf="!menu.children"> <a class="nav-link sidebar-collapse-btn collapsed " data-bs-toggle="collapse"
                     [routerLink]="[menu.link]" role="button" aria-expanded="false" aria-controls="collapseExample"
                     title=" "> {{menu.name}}</a> </li>
               <li *ngIf="menu.children"> <a class="nav-link sidebar-collapse-btn collapsed " data-bs-toggle="collapse"
                     [href]="'#profile2_' + menu.name" role="button" aria-expanded="false"
                     aria-controls="collapseExample" title=" "> {{menu.name}}</a> </li>
               <ul class="list-group list-group-flush list-unstyled sidebar-menu-item-collapse collapse"
                  [id]="'profile2_' + menu.name" [attr.data-parent]="'profile2'+menu.name" *ngIf="menu.children">
                  <li class="border-0" *ngFor="let submenu of menu.children"> <a class="nav-link mx-3 border-0"
                        (click)="goToSubMenuPage(menu.link, submenu.link)" title="">{{submenu.name}}</a></li>
               </ul>
            </ul>
         </li>
         <li class="p-0 border-0 profile">
            <ul class="list-group list-group-flush sidebar-menu-item list-unstyled ">
               <li> <a class="nav-link sidebar-collapse-btn collapsed " data-bs-toggle="collapse" href="#profile"
                     role="button" aria-expanded="false" aria-controls="collapseExample" title=" "> Profile</a> </li>
               <ul class="list-group list-group-flush list-unstyled sidebar-menu-item-collapse collapse" id="profile"
                  data-parent="#profile">
                  <li class="border-0"> <a class="nav-link mx-3 border-0" (click)="openProfileModal()"
                        title="">{{username}}</a></li>
                  <li class="border-0"><a class="nav-link mx-3 border-0" (click)="openChangePwdModal()" title="">Change
                        Password</a></li>
                  <li class="border-0"><a class="nav-link mx-3 border-0" title="">Version 13.3.0</a></li>
               </ul>
            </ul>
         </li>

         <li role="menuitem" class="nav-item pr-md-2">
            <a class="nav-link" role="button" href="#/">
               <span>Sign out</span>
            </a>
         </li>
      </ul>
   </div>
</div>