<div class="container mb-5 pb-100">
    <div class="mt-3">
        <div class="col p-0 m-0">
            <h5 class="">
                <h2 class="h2align">Update challenge questions</h2>
            </h5>
        </div>
        <hr>
    </div>
    <div>
        <form #challQuesForm="ngForm" (ngSubmit)="challQuesForm.form.valid && submitupdateUnlockForm(optQnval)"
            class="col-12">
            <div class="form-group mt-2">
                <p class="pColor">Asterisk items (<span class="text-danger spansize">*</span>)
                    are required to proceed.
                </p>
            </div>
            <div *ngFor="let item of accountUnlockData.myData; let i = index">
                <div class="row">
                    <div>
                        <div class="form-group pt-3 pb-3">
                            <label for="question_{{i}}" class="col form-label required"
                                id="ques_{{i}}">Question{{i+1}}</label>
                            <div class="col-md-6">
                                <!-- <select class="form-select rounded-0" id="question_{{i}}" name="question_{{i}}"
                                    [(ngModel)]="item.selVal.Qn" (change)="modifyOtherQtns(item, i)" #question="ngModel"
                                    placeholder="Choose a question" required aria-required="true" data-toggle="collapse"
                                    [attr.aria-describedby]="question.invalid && (question.dirty || question.touched) && question.errors?.['required'] ? 'err2' : ''"
                                    [class.is-invalid]="question.invalid && (question.dirty || question.touched)">
                                    <option selected>Select</option>

                                    <option *ngFor="let opt of item.itemQnSet" [ngValue]="opt.questionId">
                                        {{opt.question}}</option>
                                </select> -->

                                <!-- <select2 #select2Element tabindex="0" #select2 class="rounded-0" id="question_{{i}}"
                                    name="question_{{i}}" [data]="item.select2Data" [(ngModel)]="item.selVal.Qn"
                                    #question="ngModel" aria-required='true' data-toggle="collapse"
                                    (ngModelChange)="modifyOtherQtns(item, i)" [placeholder]="'Choose a question'"
                                    attr.aria-labelledby="ques_{{i}}" aria-expanded="false" aria-haspopup="listbox"
                                    role="listbox" (focus)="onFocus()" (keydown.enter)="onEnter()" aria-selected="true"
                                    [attr.aria-describedby]="question.invalid && (question.dirty || question.touched) && question.errors?.['required'] ? 'err1' : ''"
                                    [class.is-invalid]="question.invalid && (question.dirty || question.touched)">
                                </select2> -->

                                <p-dropdown inputId="question_{{i}}" [options]="item.select2Data" [filter]="true"
                                    filterBy="label" [showClear]="true" [(ngModel)]="item.selVal.Qn"
                                    [ngModelOptions]="{standalone: true}" aria-required='true' data-toggle="collapse"
                                    ariaLabelledBy="ques_{{i}}" aria-expanded="false"
                                    (onChange)="modifyOtherQtns(item, i)" placeholder="Choose a question"
                                    styleClass="full-width-dropdown"></p-dropdown>
                            </div>

                            <!-- <div *ngIf="question.invalid && (question.dirty || question.touched) && question.errors?.['required']"
                                class="help-block text-danger" id="err1">
                                <span>* Question is required </span>
                            </div> -->
                        </div>
                        <div class="mb-3">
                            <label for="answer_{{i}}" class="form-label" [attr.id]="'sanswerlbl_'+i">Answer{{i+1}}<span
                                    class="text-danger" style="font-size: 1rem;">*</span></label>
                            <div class="col-md-6">
                                <input class="form-control rounded-0" type="password" tabindex="0" #answer="ngModel"
                                    name="answer_{{i}}" id="answer_{{i}}" minlength="6" maxlength="45"
                                    placeholder="Please enter the answer" (input)="validateInputAns($event)"
                                    [(ngModel)]="item.selVal.Ans" required aria-required="true" autocomplete="off"
                                    aria-invalid="false" [attr.aria-labelledby]="'sanswerlbl_'+i"
                                    [attr.aria-describedby]="'err1_' + i + ' err2_' + i"
                                    [class.is-invalid]="answer.invalid && (answer.dirty || answer.touched)" />
                            </div>
                            <!-- (oninput)="checkDuplicates()" [attr.aria-describedby]="answer.invalid && (answer.dirty || answer.touched) && answer.errors?.['required'] ? 'err2' : ''" -->

                            <div *ngIf="answer.invalid && (answer.dirty || answer.touched) && answer.errors?.['required']"
                                class="help-block text-danger" [id]="'err1_' + i">
                                <span>* Answer is required </span>
                            </div>
                            <div *ngIf="answer.errors?.['minlength']" class="help-block text-danger" [id]="'err2_' + i">
                                <span> No special character allowed. Use alpha numeric and space. 6-45 characters in
                                    length.</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="mb-2">
                <div class="col p-0 m-0">
                    <label for="optQnval" id="otp_lbl" class="subtitle">Please enter OTP
                    </label>
                </div>
            </div>

            <div class="row px-form-group col-md-6 mt-3">
                <div class="input-group rounded-0">
                    <input type="text" tabindex="0" class="form-control rounded-0" name="optQnval" id="optQnval"
                        [(ngModel)]="optQnval" maxlength="6" (input)="validateInput($event)"
                        placeholder="Enter your OTP" aria-labelledby="otp_lbl" aria-describedby="'err1'" required
                        aria-required="true" aria-invalid="false" />
                    <button tabindex="0" type="button" class="h-40 btn btn-primary rounded-0" (click)="getOPT()">Get
                        OTP</button>
                </div>
                <div *ngIf="challQuesForm.form.touched && challQuesForm.form?.['required']" class="error-message"
                    id="err1">
                    <span style="color: #dc3545;">* Enter your OTP sent through mail </span>
                </div>
            </div>
            <div class="row">
                <div class="mt-2">
                    <div class="p-0">
                        <button type="button" class="h-40 btn btn-primary rounded-0 me-3" tabindex="0" type="submit"
                            [disabled]="!challQuesForm.form.valid">Save</button>
                        <button type="button" class="h-40 btn btn-primary rounded-0" tabindex="0" type="reset"
                            (click)="AccountUnlockCancel()">Cancel</button>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>