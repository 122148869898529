import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CommonService } from '../common/common.service';
import RestURL from 'src/assets/config/restURLConstant.json';

@Injectable({
  providedIn: 'root',
})
export class StpGuideService {
  constructor(private http: HttpClient, private commonService: CommonService) {}
  getSTPDetails() {
    let stpGuideDetailsUrl =
      this.commonService.restDomainURL + RestURL.STP_GUIDE_DETAILS;
    return this.http.get(stpGuideDetailsUrl);
  }
  getSTPTransTypeDetails(payload: any) {
    let stpGuideTransCountryDetailsUrl =
      this.commonService.restDomainURL +
      RestURL.STP_GUIDE_COUNTRY_TRANSTYPEDETAILS;
    return this.http.post(stpGuideTransCountryDetailsUrl, payload);
  }
  stpReport(payload: any) {
    let stpGuideReportDetailsUrl =
      this.commonService.restDomainURL + RestURL.STP_REPORT_SUBMIT;
    return this.http.post(stpGuideReportDetailsUrl, payload);
  }
}
