<table class="table table-striped table-hover table-sm ">
	<thead>
		<!-- <th sortable (sort)="onSort({column:'kesav', direction: 'asc'})"> kesav</th> -->
		<ng-container *ngFor="let header of tableInfo?.headers">
			<th scope="col" sortable="{{header['headerKey']}}" (sort)="onSort($event)">{{header.label}}
				<span>
					<i class="fa fa-sort"></i>
				</span>
			</th>

		</ng-container>
	</thead>
	<tbody>
		<tr *ngFor="let tData of tableData let i = index">
			<td *ngFor="let theader of tableInfo?.headers"
				[ngClass]="{'first-column-success': tData['Status'] === 'Success', 'first-column-failure': tData['Status']== 'Failure'}">
				<span><i *ngIf="theader['headerKey'] == 'Status' && tData['Status'] == 'Failure'"
						class="fas fa-minus-circle error"></i></span>
				<span><i *ngIf="theader['headerKey'] == 'Status' && tData['Status'] == 'Success'"
						class="fas fas fa-check-circle success"></i></span>
				<ng-container *ngIf="!theader?.actions">
					<span
						*ngIf="!searchKey && !theader?.details?.navigationLink && theader.label != 'Status'">{{tData[theader?.headerKey]}}</span>
					<span *ngIf="!searchKey && theader?.details?.navigationLink"
						(click)="dataClickEvent(tData, theader)" class="textStyle">{{tData[theader?.headerKey]}}
					</span>
					<ngb-highlight *ngIf="searchKey?.length && theader.label != 'Status'" [result]="tData[theader?.headerKey]"
						[term]="searchKey"></ngb-highlight></ng-container>
				<!-- <ng-container *ngIf="theader?.actions">edit/delete/download</ng-container> -->
				<ng-container *ngIf="theader.label == 'Status'">
					<span class="badge"
						[ngClass]="{'px-badge-success':(tData.status == 'active'|| tData.Status == 'active'),'px-badge-danger ':(tData.status == 'inactive' || tData.Status == 'inactive')}"
						[ngStyle]="{'font-size': 'small'}"> {{tData[theader?.headerKey]}}
					</span>
				</ng-container>
				<ng-container *ngIf="theader.label == '#'">
					<span>
						{{((page-1)*pageSize)+i+1}}
					</span>
				</ng-container>
				<ng-container *ngIf="theader?.actions">
			<td class="text-center p-0" *ngFor="let action of theader?.actions">
				<a *ngIf='action=="Download"' tabindex="0" class="commonColorblu text-decoration-underlined"
					href="javascript:void(0)" title="action" id="action" (click)="downloadEvent(tData)"> {{action}}
				</a>
				<span m-l-2>
				<a *ngIf='action=="Edit"' tabindex="0" class="commonColorblu text-decoration-none"
					href="javascript:void(0)" title="Edit" id="Edit" (click)="editEvent(tData)">
					<i class="fas fa-user-edit"></i> {{action}}   /   
				</a>
				<a *ngIf='action=="Delete"' tabindex="0" class="commonColorred text-decoration-none"
					href="javascript:void(0)" title="Delete" id="Delete" (click)="deleteEvent(tData)">
					<i class="fas fa-trash-alt"></i> {{action}}
				</a>
				</span>
			</td>
			</ng-container>
			</td>
		</tr>
		<!-- <tr *ngFor="let country of countries">
			<th scope="row">{{ country.id }}</th>
			<td>
				<img [src]="'https://upload.wikimedia.org/wikipedia/commons/' + country.flag" class="me-2"
					style="width: 20px" />
				{{ country.name }}
			</td>
			<td>{{ country.area | number}}</td>
			<td>{{ country.population | number }}</td>
		</tr>
		 -->

	</tbody>
</table>
<div class="d-flex justify-content-between">
	<ngb-pagination [collectionSize]="collectionSize" [(page)]="page" [pageSize]="pageSize" [boundaryLinks]="true"
		[maxSize]="1" [ellipses]="true" (pageChange)="refreshCountries()">
		<ng-template ngbPaginationPrevious>Previous</ng-template>
		<ng-template ngbPaginationNext>Next</ng-template>
		<ng-template ngbPaginationLast>Last</ng-template>
		<ng-template ngbPaginationFirst>First</ng-template>
	</ngb-pagination>

	<div class="d-flex justify-content-center  align-items-center flex-wrap-reverse">
		<!-- <span aria-label="Show per page">Show per page:
		</span> -->
		<label class="text-muted me-2" tabindex="0"  for="pageSizeSelect">Show per page:</label>
		<select id="pageSizeSelect" class="form-select rounded-0 selectBorder  custom-select-apparance" style="width: auto"
			[(ngModel)]="pageSize" (ngModelChange)="refreshCountries()">
			<option [ngValue]="5">5 </option>
			<option [ngValue]="10">10 </option>
			<option [ngValue]="50">50 </option>
		</select>
	</div>
</div>

<div class="text-muted">
	<span class="ng-binding" aria-hidden="false">
		Showing {{setCount(page-1, pageSize) ? setCount(page-1, pageSize)+1 : setCount(page-1, pageSize)+1 }} -
		{{setCount(page,pageSize) >= collectionSize ? collectionSize : setCount(page,pageSize)}} of
		{{collectionSize}} Items.
	</span>
</div>