import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Select2Group, Select2Option } from 'ng-select2-component';
import { ToastrService } from 'ngx-toastr';
import { MessageService } from 'primeng/api';
import { accountUnlockInterface } from 'src/app/interfaces/login-interface';
// import { challengeQusInterface } from 'src/app/interfaces/login-interface';
import { AuthService } from 'src/app/services/auth/auth.service';
import { ToastService } from 'src/app/services/toast/toast.service';

@Component({
  selector: 'app-update-unlock',
  templateUrl: './update-unlock.component.html',
  styleUrls: ['./update-unlock.component.css'],
})
export class UpdateUnlockComponent implements OnInit, AfterViewInit {
  data: (Select2Group | Select2Option)[];
  @ViewChild('select2') select2Element: ElementRef;

  accountUnlockData: accountUnlockInterface = {
    items: [],
    myData: [],
    QnsSet: [],
    changingQnset: [],
    slctdVal: [],
    qusAnsFinal: [],
    finaldata: '',
    updateslctdVal: [],
    preQnsSet: [],
  };
  accountUnlockDatalist: Array<Select2Option>;
  otpQnAns: any;
  optQnval: any;
  // updateslctdVal: string[];
  // changingQnset: any[];

  constructor(
    private authService: AuthService,
    public router: Router,
    private toastr: ToastrService,
    public toastService: ToastService,
    private renderer: Renderer2,
    private messageService: MessageService
  ) { }

  ngOnInit() {
    this.accountUnlockData.items = [1, 2, 3, 4, 5, 6];
    this.accountUnlockData.myData = [];
    this.accountUnlockData.slctdVal = [];
    this.accountUnlockData.changingQnset = [];
    this.accountUnlockData.updateslctdVal = ['', '', '', '', '', ''];

    this.authService.getsecurityQus().subscribe({
      next: (response: any) => {
        this.accountUnlockData.QnsSet = response;
        this.accountUnlockData.changingQnset = this.accountUnlockData.QnsSet;
      },
      error: (err) => {
        // this.toastr.error(err?.error?.error?.message);
        this.messageService.add({
          severity: 'error',
          detail: err?.error?.error?.message
        });
      },
    });

    this.authService.getprechalngQus().subscribe({
      next: (response: any) => {
        this.accountUnlockData.preQnsSet = response;

        for (
          let i = 0;
          i < this.accountUnlockData.preQnsSet.challengeQnAns.length;
          i++
        ) {
          this.accountUnlockData.slctdVal[i] =
            this.accountUnlockData.preQnsSet.challengeQnAns[i].questionId;
        }

        for (
          let i = 0;
          i < this.accountUnlockData.preQnsSet.challengeQnAns.length;
          i++
        ) {
          this.accountUnlockData.changingQnset =
            this.accountUnlockData.QnsSet.filter(
              (val) => !this.accountUnlockData.slctdVal.includes(val.questionId)
            );
          let obj = {
            selVal: {
              Qn: this.accountUnlockData.preQnsSet.challengeQnAns[i].questionId,
              Ans: this.accountUnlockData.preQnsSet.challengeQnAns[i].answer,
            },
            itemQnSet: this.accountUnlockData.changingQnset,
            select2Data: [],
          };
          let drpdwnSel = this.accountUnlockData.QnsSet.filter(
            (val) => val.questionId === obj.selVal.Qn
          )?.[0];
          obj.itemQnSet.push(drpdwnSel);

          let accoununiqueArr = [];
          obj.itemQnSet.forEach((item) => {
            accoununiqueArr.push({
              label: item?.question,
              value: item?.questionId,
            });
          });
          obj.select2Data = Object.assign([], accoununiqueArr);
          this.accountUnlockData.myData.push(obj);
        }
      },
      error: (err) => {
        // this.toastr.error(err?.error?.error?.message);
        this.messageService.add({
          severity: 'error',
          detail: err?.error?.error?.message
        });
      },
    });
  }

  validateInput(event: any) {
    const pattern = /^[0-9\s]*$/;
    const input = event.target.value;

    if (!pattern.test(input)) {
      event.target.value = input.replace(/[^0-9\s]/g, '');
      this.optQnval = event.target.value;
    }
  }

  validateInputAns(event: any) {
    const pattern = /^[A-Za-z0-9\s]*$/; // Only allows letters, numbers, and spaces
    const input = event.target.value;

    if (!pattern.test(input)) {
      // Remove invalid characters
      event.target.value = input.replace(/[^A-Za-z0-9\s]/g, '');
    }

    // Update the model value
    // this.accountUnlockData.myData['selVal']['Ans'] = event.target.value;
  }

  // ngAfterViewInit() {
  // this.select2Element.nativeElement.focus();
  // const select2NativeElement = this.select2Element.nativeElement;
  // select2NativeElement.setAttribute('aria-placeholder', 'Select an option');
  // }

  onFocus() {
    // const searchInput = this.select2Element.nativeElement.querySelector('.select2-search__field');
    // if (searchInput) {
    //   searchInput.focus();
    // }
  }

  ngAfterViewInit() {
    // this.renderer.selectRootElement('#question_0').focus();
  }

  onEnter() {
    // event.preventDefault(); // Prevent default action to manage the focus properly
    const searchInput = this.select2Element.nativeElement.querySelector(
      '.select2-search__field'
    );
    if (searchInput) {
      searchInput.focus();
    }
  }

  // get Qn() {
  //   return this.challQuesForm.get('Qn')!;
  // }

  // get Ans() {
  //   return this.challQuesForm.get('Ans')!;
  // }

  submitupdateUnlockForm(otpQnDts) {
    this.accountUnlockData.qusAnsFinal = [];
    for (let i = 0; i < this.accountUnlockData.myData.length; i++) {
      for (
        let j = 0;
        j < this.accountUnlockData.myData[i].itemQnSet.length;
        j++
      ) {
        if (
          this.accountUnlockData.myData[i].selVal['Qn'] ==
          this.accountUnlockData.myData[i].itemQnSet[j]['questionId']
        ) {
          this.accountUnlockData.qusAnsFinal.push({
            question: this.accountUnlockData.myData[i].itemQnSet[j]['question'],
            answer: this.accountUnlockData.myData[i].selVal['Ans'],
          });
        }
      }
      this.accountUnlockData.finaldata = {
        quAns: this.accountUnlockData.qusAnsFinal,
        secretCode: otpQnDts,
      };
    }
    this.authService.putSecQues(this.accountUnlockData.finaldata).subscribe({
      next: (response: any) => {
        let dataSuccess = response;
        // this.toastr.success(dataSuccess.message);
        this.router.navigate(['/login']);
        this.toastService.add({
          type: 'success',
          message: dataSuccess.message,
        });
        // document.getElementById('toast').focus();
        if (document.getElementById('toast')) {
          setTimeout(() => {
            document.getElementById('closeButton').focus();
          }, 0);
        }
      },
      error: (err) => {
        // this.toastr.error(err?.error?.error?.message);
        this.messageService.add({
          severity: 'error',
          detail: err?.error?.error?.message
        });
      },
    });
  }

  AccountUnlockCancel() {
    this.router.navigate(['/login']);
    setTimeout(() => {
      document.getElementById('userId').focus();
    }, 1000);
  }

  modifyOtherQtns(item: any, ind: any) {
    //dropdown value is undefined
    if (!item.selVal.Qn) {
      item.selVal.Qn = '';
      this.accountUnlockData.slctdVal[ind] = item.selVal.Qn;
      this.accountUnlockData.changingQnset =
        this.accountUnlockData.QnsSet.filter(
          (val) => !this.accountUnlockData.slctdVal.includes(val.questionId)
        );
      // console.log("1", this.accountUnlockData.changingQnset)
    }
    // Checking selected value is thr r not
    if (
      item.selVal.Qn &&
      !this.accountUnlockData.slctdVal.includes(item.selVal.Qn)
    ) {
      // $scope.slctdVal.push(item.selVal.Qn);
      this.accountUnlockData.slctdVal[ind] = item.selVal.Qn;
      this.accountUnlockData.changingQnset =
        this.accountUnlockData.QnsSet.filter(
          (val) => !this.accountUnlockData.slctdVal.includes(val.questionId)
        );
      // console.log("2", this.accountUnlockData.changingQnset)

      // setTimeout(() => {
      //   document.querySelectorAll('.select2-selection__clear').forEach((closebtn, index) => {
      //     console.log("Close");
      //     closebtn.setAttribute('tabindex', '0');
      //     closebtn.setAttribute('aria-label', 'close Button');
      //     closebtn.removeAttribute('title');
      //   })
      // }, 500)
    }
    //Remove and Push data in global variable
    for (let i = 0; i < this.accountUnlockData.myData.length; i++) {
      // console.log("myData : ", $scope.myData[i])
      // if (i == ind && $scope.myData[i].selVal.Qn) {
      //     continue;
      // } else {
      this.accountUnlockData.myData[i].itemQnSet = JSON.parse(
        JSON.stringify(this.accountUnlockData.changingQnset)
      );

      if (this.accountUnlockData.myData[i].selVal.Qn) {
        // $scope.myData[i].itemQnSet = $scope.changingQnset;
        let drpdwnSel = this.accountUnlockData.QnsSet.filter(
          (val) => val.questionId === this.accountUnlockData.myData[i].selVal.Qn
        )?.[0];
        // console.log('drpdwnSel----', drpdwnSel);
        this.accountUnlockData.myData[i].itemQnSet.push(drpdwnSel);
        // console.log('final---', this.accountUnlockData.myData[i].itemQnSet);
      }
    }
    /*for (let i = 0; i < this.accountUnlockData.myData.length; i++) {
      if (i == ind) {
        continue;
      } else {
        if (!this.accountUnlockData.myData[i].selVal.Qn) {
          this.accountUnlockData.myData[i].itemQnSet = this.accountUnlockData.changingQnset;
          const accoununiqueArr1 = [];
          this.accountUnlockData.myData[i].itemQnSet.forEach(item => {

            accoununiqueArr1.push({ label: item?.question, value: item?.questionId });
          });
          this.accountUnlockDatalist = accoununiqueArr1;
          // console.log(accoununiqueArr1, "accoununiqueArr---after111");
        } else {
          // $scope.myData[i].itemQnSet = $scope.myData[i].itemQnSet.filter(val => val.questionId != item.selVal.Qn);
          // $scope.myData[i].itemQnSet = $scope.changingQnset.filter(val => val.questionId != item.selVal.Qn);

          let drpdwnSel = this.accountUnlockData.QnsSet.filter(val => val.questionId === this.accountUnlockData.myData[i].selVal.Qn)?.[0];

          this.accountUnlockData.myData[i].itemQnSet = this.accountUnlockData.changingQnset.filter(val => val.questionId != item.selVal.Qn);

          // console.log("DDD: ", drpdwnSel)

          this.accountUnlockData.myData[i].itemQnSet.push(drpdwnSel);

          const accoununiqueArr1 = [];
          this.accountUnlockData.myData[i].itemQnSet.forEach(item => {

            accoununiqueArr1.push({ label: item?.question, value: item?.questionId });
          });
          this.accountUnlockDatalist = accoununiqueArr1;
          // console.log(accoununiqueArr1, "accoununiqueArr---after222");


          // console.log("testing", this.accountUnlockData.myData[i].itemQnSet)
        }
      }
    }*/
  }

  getOPT(optval?) {
    this.authService.getOTPdts(optval).subscribe({
      next: (response: any) => {
        // console.log('optval---', optval);
        let dataSuccess = response;
        this.otpQnAns = dataSuccess.message;
        // this.toastr.success(dataSuccess.message);
        this.messageService.add({
          severity: 'success',
          detail: dataSuccess.message
        });
      },
      error: (err) => {
        // this.toastr.error(err?.error?.error?.message);
        this.messageService.add({
          severity: 'error',
          detail: err?.error?.error?.message
        });
      },
    });
  }
}
