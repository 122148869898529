<h1 class="h1font">About CashPro® Validator</h1>
<span class="h1font">CashPro® Validator is a complimentary tool that enables you to validate
    payment and
    collection files as part of the on-boarding process. Key features include:
</span>
<br>
<span class="h1font">
    <ul class="list-unstyled px-5">
        <li class="listyle">Format validation against comprehensive Straight Through Processing
            (STP)
            rules
        </li>
        <li>Instant test results for format and STP issues</li>
        <li>On-screen editing for immediate re-validation</li>
        <li>Dashboard summary for previous requests up to 90 days</li>
    </ul>
</span>
<br>
<span class="h1font">If you would like more information about CashPro® Validator, please contact
    your
    Bank of
    America representative.
</span>
<span class="h1font">For the best overall performance we recommend using Google Chrome.</span>