import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { NgIdleModule } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';

import { AppComponent } from './app.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { LoginComponent } from './modules/login/login.component';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FooterComponent } from './layout/footer/footer.component';
import { HeaderComponent } from './layout/header/header.component';
import { HttpinterceptorInterceptor } from './services/interceptors/httpinterceptor.interceptor';
import { NavbarmenusComponent } from './layout/navmenu/navbarmenus.component';
import { APP_INITIALIZER } from '@angular/core';
import { CommonService } from './services/common/common.service';
import { MainComponent } from './modules/main/main.component';
import { FilevalidationComponent } from './modules/filevalidation/filevalidation.component';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CodemirrorModule } from '@ctrl/ngx-codemirror';
import { VerifyOTPComponent } from './modules/verify-otp/verify-otp.component';
import { FormsComponent } from './core/forms/forms.component';
import { CrudComponent } from './modules/crud/crud.component';

import { ModalComponent } from './core/modal/modal.component';
import { DashboardComponent } from './modules/dashboard/dashboard.component';
import { AccountUnlockComponent } from './modules/account-unlock/account-unlock.component';
import { Select2Module } from 'ng-select2-component';
import { StpGuideComponent } from './modules/stp-guide/stp-guide.component';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { ToastComponent } from './toast/toast/toast.component';
import { AboutComponent } from './layout/about/about.component';
import { ValidatorComponent } from './modules/validator/validator.component';
import { DragDropDirective } from './directive/drag-drop.directive';
import { TableComponent } from './core/table/table.component';
import { DetailspageComponent } from './modules/detailspage/detailspage.component';
import { DashboardDetailsComponent } from './modules/dashboard-details/dashboard-details/dashboard-details.component';
import { StandaloneModalComponent } from './core/standalone-modal/standalone-modal.component';
import { VapiconstructorComponent } from './modules/vapiconstructor/vapiconstructor.component';
import { KeepAliveDialogComponent } from './core/keep-alive-dialog/keep-alive-dialog.component';
import { DropdownModule } from 'primeng/dropdown';
import { MultiSelectModule } from 'primeng/multiselect';
import { ToastModule } from 'primeng/toast';
import { MessageService } from 'primeng/api';
import { UpdateUnlockComponent } from './modules/updateUnlock/update-unlock/update-unlock.component';
import { CustomLoaderComponent } from './modules/custom-loader/custom-loader.component';

export function fetchUIConfig(CommonService: CommonService) {
  return () => CommonService.fetchUIConfig();
}
@NgModule({
  declarations: [
    AppComponent,
    PageNotFoundComponent,
    LoginComponent,
    FooterComponent,
    HeaderComponent,
    NavbarmenusComponent,
    MainComponent,
    FilevalidationComponent,
    VerifyOTPComponent,
    AccountUnlockComponent,
    StpGuideComponent,
    ToastComponent,
    AboutComponent,
    ValidatorComponent,
    DragDropDirective,
    DetailspageComponent,
    DashboardDetailsComponent,
    ModalComponent,
    VapiconstructorComponent,
    KeepAliveDialogComponent,
    UpdateUnlockComponent,
    CustomLoaderComponent,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpinterceptorInterceptor,
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: fetchUIConfig,
      deps: [CommonService],
      multi: true,
    },
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    Keepalive, MessageService
  ],
  bootstrap: [AppComponent],
  imports: [
    BrowserModule,
    NgbModule,
    NgIdleModule.forRoot(),
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    Select2Module,
    ReactiveFormsModule,
    ToastrModule.forRoot({
      timeOut: 5000,
      positionClass: 'toast-top-right',
      preventDuplicates: true,
      includeTitleDuplicates: true,
      resetTimeoutOnDuplicate: true,
      countDuplicates: true,
      closeButton: false,
    }),
    BrowserAnimationsModule,
    CodemirrorModule,
    TableComponent,
    CrudComponent,
    FormsComponent,
    DropdownModule,
    MultiSelectModule,
    ToastModule
  ],
  schemas: [NO_ERRORS_SCHEMA],
})
export class AppModule { }
