import { DecimalPipe } from '@angular/common';
import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import RestURL from 'src/assets/config/restURLConstant.json';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/services/common/common.service';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  PipeTransform,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import {
  NgbPaginationModule,
  NgbTypeaheadModule,
} from '@ng-bootstrap/ng-bootstrap';
import {
  SortableDirective,
  SortEvent,
} from 'src/app/directives/sortable.directive';
import { StandaloneModalComponent } from '../standalone-modal/standalone-modal.component';
import { StmodalService } from 'src/app/services/common/stmodal.service';
interface Country {
  id?: number;
  name: string;
  flag: string;
  area: number;
  population: number;
}
const compare = (v1: string | number, v2: string | number) =>
  v1 < v2 ? -1 : v1 > v2 ? 1 : 0;
const COUNTRIES: Country[] = [
  {
    name: 'Russia',
    flag: 'f/f3/Flag_of_Russia.svg',
    area: 17075200,
    population: 146989754,
  },
  {
    name: 'France',
    flag: 'c/c3/Flag_of_France.svg',
    area: 640679,
    population: 64979548,
  },
  {
    name: 'Germany',
    flag: 'b/ba/Flag_of_Germany.svg',
    area: 357114,
    population: 82114224,
  },
  {
    name: 'Portugal',
    flag: '5/5c/Flag_of_Portugal.svg',
    area: 92090,
    population: 10329506,
  },
  {
    name: 'Canada',
    flag: 'c/cf/Flag_of_Canada.svg',
    area: 9976140,
    population: 36624199,
  },
  {
    name: 'Vietnam',
    flag: '2/21/Flag_of_Vietnam.svg',
    area: 331212,
    population: 95540800,
  },
  {
    name: 'Brazil',
    flag: '0/05/Flag_of_Brazil.svg',
    area: 8515767,
    population: 209288278,
  },
  {
    name: 'Mexico',
    flag: 'f/fc/Flag_of_Mexico.svg',
    area: 1964375,
    population: 129163276,
  },
  {
    name: 'United States',
    flag: 'a/a4/Flag_of_the_United_States.svg',
    area: 9629091,
    population: 324459463,
  },
  {
    name: 'India',
    flag: '4/41/Flag_of_India.svg',
    area: 3287263,
    population: 1324171354,
  },
  {
    name: 'Indonesia',
    flag: '9/9f/Flag_of_Indonesia.svg',
    area: 1910931,
    population: 263991379,
  },
  {
    name: 'Tuvalu',
    flag: '3/38/Flag_of_Tuvalu.svg',
    area: 26,
    population: 11097,
  },
  {
    name: 'China',
    flag: 'f/fa/Flag_of_the_People%27s_Republic_of_China.svg',
    area: 9596960,
    population: 1409517397,
  },
];
@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  standalone: true,
  imports: [
    CommonModule,
    DecimalPipe,
    NgbPaginationModule,
    NgbTypeaheadModule,
    FormsModule,
    SortableDirective,
    StandaloneModalComponent,
  ],
  providers: [DecimalPipe],
  styleUrls: ['./table.component.css'],
})
export class TableComponent implements OnInit, OnChanges {
  @Input() tableInfo: any = {};
  @Input() tableDataToDisplay: any = [];
  @Input() searchKey: any = '';
  @Output() clickEventHandle = new EventEmitter();
  @Output() editEventHandle = new EventEmitter();
  @Output() downloadEventHandle = new EventEmitter();
  @Output() deleteEventHandle = new EventEmitter();

  @Input() editFlag: boolean = false;
  @Input() id: any = {};
  page = 1;
  pageSize = 5;
  collectionSize = 0;
  tableData: any = [];
  countries!: Country[];
  FilteredTableData: any = [];
  deletdata: any = {};
  url: any;
  data: any = {};
  @ViewChildren(SortableDirective) headers: QueryList<SortableDirective>;
  constructor(
    public pipe: DecimalPipe,
    public router: Router,
    public modalService: StmodalService,
    private http: HttpClient,
    private toastr: ToastrService,
    private commonService: CommonService
  ) {
    // if (this.tableInfo?.tableData?.length > this.pageSize) {
    // this.collectionSize = this.tableInfo?.tableData?.length;
    //this.refreshCountries();
    //}
  }

  // ngAfterContentInit() {
  //   this.collectionSize = this.tableDataToDisplay.length;
  //   this.refreshCountries();
  // }
  ngOnChanges() {
    if (this.searchKey.length) {
      this.FilteredTableData = this.tableDataToDisplay.filter((data: any) =>
        this.matches(data, this.searchKey, this.pipe)
      );
      const total = this.FilteredTableData.length;
      this.collectionSize = this.FilteredTableData.length;

      // 3. paginate
      this.tableData = this.FilteredTableData.slice(
        (this.page - 1) * this.pageSize,
        (this.page - 1) * this.pageSize + this.pageSize
      );
    } else {
      this.FilteredTableData = [];
      this.tableData = this.tableDataToDisplay;
      this.collectionSize = this.tableDataToDisplay.length;
      this.refreshCountries();
    }
    // if (this.tableInfo?.tableData?.length > this.pageSize) {
    //   this.collectionSize = this.tableInfo?.tableData?.length;
    //this.refreshCountries();
    // }
    //this.refreshCountries();
    //console.log(this.collectionSize);
  }
  ngOnInit() {
    //console.log(this.tableInfo);
    //this.collectionSize = this.tableInfo?.tableData?.length;
    //this.refreshCountries();
    //console.log(this.collectionSize);
  }

  matches(data: any, term: string, pipe: PipeTransform) {
    let flg = false;
    let temp = [];
    let header = this.tableInfo?.headers;
    // this.tableInfo?.headers.map((header: any) => {
    for (let n = 0; n < header.length; n++) {
      flg =
        String(data[header[n]['headerKey']])
          ?.toLowerCase()
          ?.includes(term.toLowerCase()) || false;
      if (flg) break;
    }

    return flg;
  }
  editEvent(value: any) {
    this.editEventHandle.emit({
      clickedData: value,
      clickEventFrom: 'table',
      editFlag: true
    });
  }

  deleteEvent(param: any) {
    this.deleteEventHandle.emit({
      Data: param,
      EventFrom: 'table',
    });
  }
  downloadEvent(tData: any) {
    this.downloadEventHandle.emit({
      clickedData: tData,
      clickEventFrom: 'Download',
    });
  }



  refreshCountries() {
    // console.log(this.searchKey, 'Search Key');
    // this.countries = COUNTRIES.map((country, i) => ({
    //   id: i + 1,
    //   ...country,
    // })).slice(
    //   (this.page - 1) * this.pageSize,
    //   (this.page - 1) * this.pageSize + this.pageSize
    // );
    // console.log(this.tableInfo['tableData'], '22222', this.page);

    if (this.searchKey.length) {
      this.FilteredTableData = this.tableDataToDisplay.filter((data: any) =>
        this.matches(data, this.searchKey, this.pipe)
      );
      const total = this.FilteredTableData.length;
      this.collectionSize = this.FilteredTableData.length;

      // 3. paginate
      this.tableData = this.FilteredTableData.slice(
        (this.page - 1) * this.pageSize,
        (this.page - 1) * this.pageSize + this.pageSize
      );
    } else {
      this.FilteredTableData = [];
      if (this.tableData?.length > 0) {
        this.tableData = this.tableDataToDisplay
          ?.map((data: any, i: number) => ({
            ...data,
          }))
          .slice(
            (this.page - 1) * this.pageSize,
            (this.page - 1) * this.pageSize + this.pageSize
          );
      }
    }
    //console.log(this.tableInfo['tableData'], '33333');
  }


  onSort({ column, direction }: SortEvent) {
    // resetting other headers
    this.headers.forEach((header) => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });
    // console.log(column, direction, '00000000000');
    if (this.searchKey.length) {
      // console.log(this.FilteredTableData, 'hhhhhh');
      let fff = this.FilteredTableData;
      if (direction === '' || column === '') {
        return this.FilteredTableData;
      } else {
        this.FilteredTableData = [...fff].sort((a, b) => {
          const res = compare(a[column], b[column]);
          return direction === 'asc' ? res : -res;
        });
      }
    } else {
      //this.FilteredTableData = [];
      let ttt = this.tableDataToDisplay;
      // console.log(direction, column, this.tableDataToDisplay, 'iiiiii');
      if (direction === '' || column === '') {
        return ttt;
      } else {
        this.tableDataToDisplay = [...ttt].sort((a, b) => {
          const res = compare(a[column], b[column]);
          return direction === 'asc' ? res : -res;
        });
      }
    }
    this.refreshCountries();
    //this.service.sortColumn = column;
    //this.service.sortDirection = direction;
  }

  dataClickEvent(data: any, header: any) {
    if (header?.headerKey !== 'Filename') {
      this.router.navigate(['/app/detailspage']);
    }
    //this.clickEventHandle.emit({ clickedData: data, headerOpt: header });
    this.clickEventHandle.emit({
      clickedData: data,
      headerOpt: header,
      clickEventFrom: 'FileDownload',
    });
  }

  setCount(firstcount, lastcount) {
    return firstcount * lastcount;
  }
}

