import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, Input, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ModalComponent } from 'src/app/core/modal/modal.component';
import {
  IChangePasswordFormFlds,
  IUserProfileRes,
} from 'src/app/interfaces/user';
import { AuthService } from 'src/app/services/auth/auth.service';
import { CommonService } from 'src/app/services/common/common.service';
import { ModalService } from 'src/app/services/common/modal.service';
import RestURL from 'src/assets/config/restURLConstant.json';
import { passwordMatch } from 'src/app/core/validators/passwordMatch.validator';
import { ToastService } from 'src/app/services/toast/toast.service';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-navbarmenus',
  templateUrl: './navbarmenus.component.html',
  styleUrls: ['./navbarmenus.component.css'],
  providers: [ModalComponent],
})
export class NavbarmenusComponent implements OnInit {
  @Input() menus: any = [];
  @Input() username: string | undefined;
  currentLoggedInUserDetails: IUserProfileRes | undefined;
  menuItems: any = [];
  changePwdForm!: FormGroup<any>;
  activeSelectedMenu: string;
  updateUnlock: any;
  roleLists: any;
  switchRole: any;
  finalRole: any;
  roles: any = {};
  version: string;
  constructor(
    public router: Router,
    private authService: AuthService,
    private http: HttpClient,
    private commonService: CommonService,
    public modalService: ModalService,
    private fb: FormBuilder,
    private el: ElementRef,
    private toaster: ToastrService,
    public toastService: ToastService,
    private messageService: MessageService
  ) { }

  ngOnInit() {
    ///form declaration
    this.changePwdForm = new FormGroup(
      {
        currentPwd: new FormControl('', [Validators.required]),
        newPwd: new FormControl('', [Validators.required]),
        confirmNewPwd: new FormControl('', [Validators.required]),
      },
      passwordMatch
    );
    let profileDetails = sessionStorage.getItem('isAuthorized') || '';
    // console.log("profileDetails", profileDetails)
    this.updateUnlock = JSON.parse(atob(profileDetails))['challengeQnAnsUpdatePage'];
    this.switchRole = JSON.parse(atob(profileDetails))['switchRole'];
    // console.log(this.updateUnlock)
    if (JSON.parse(atob(profileDetails))['landingpage'] == 'createclient') {
      this.activeSelectedMenu = 'Create Clients';
    } else {
      this.activeSelectedMenu =
        JSON.parse(atob(profileDetails))['landingpage'] || 'dashboard';
    }

    this.generateMenu();

    this.commonService.switchRoleUpdate.subscribe((val) => {
      this.menus = val;
      this.generateMenu();
    })

    this.username = sessionStorage.getItem('username') as string;
    this.version = sessionStorage.getItem('version')
  }

  generateMenu() {
    // Menu Items Structure
    const menuItems = [
      {
        id: 1,
        name: 'Dashboard',
        link: 'dashboard',
        key: 'dashboard',
        children: null,
      },
      {
        id: 2,
        name: 'File validation',
        link: 'filevalidation',
        key: 'filevalidation',
        children: null,
      },
      {
        id: 3,
        name: 'STP guide',
        link: 'stpguide',
        key: 'stpguide',
        children: null,
      },
      {
        id: 4,
        name: 'Validator',
        link: 'validator',
        key: 'validator',
        children: null,
      },
      {
        id: 5,
        name: 'Constructor',
        link: 'vapiconstructor',
        key: 'constructor',
        children: null,
      },
      {
        id: 6,
        name: 'Admin',
        key: 'admin',
        children: [
          {
            id: 61,
            name: 'Create Clients',
            link: 'client/create',
            key: 'createclient',
          },
          {
            id: 62,
            name: 'Create Users',
            link: 'users/create',
            key: 'createusers',
          },
          {
            id: 63,
            name: 'Edit Clients',
            link: 'client/edit',
            key: 'editclient',
          },
          {
            id: 64,
            name: 'Edit Users',
            link: 'users/edit',
            key: 'editusers',
          },
          {
            id: 65,
            name: 'View Clients',
            link: 'clients/view',
            key: 'viewclients',
          },
          {
            id: 66,
            name: 'View Users',
            link: 'users/view',
            key: 'viewusers',
          },
        ],
      },
      {
        id: 7,
        name: 'Profile',
        link: 'Profile',
        key: 'profile',
        children: [
          {
            id: 71,
            name: 'User',
            link: 'user',
            key: 'User',
          },
          {
            id: 72,
            name: 'Change Password',
            link: 'changepassword',
            key: 'changepassword',
          },
          {
            id: 73,
            name: 'Forgot Password',
            link: 'forgotpassword',
            key: 'forgotpassword',
          },
        ],
      },
    ];
    const loginObj: any = this.menus;
    const adminMenuitems = [
      'viewclients',
      'viewusers',
      'createclient',
      'createusers',
      'editclient',
      'editusers',
    ];
    let MenuArr = [];
    let adminMenuArr: any = [];
    for (let i = 0; i < menuItems.length; i++) {
      for (const [key, val] of Object.entries(loginObj)) {
        if (key !== 'admin' && val && menuItems[i]?.['key'] == key) {
          MenuArr.push(menuItems[i]);
        } else if (key == 'admin' && val && menuItems[i]?.['key'] == key) {
          adminMenuArr.push(menuItems[i]);
        }
      }
    }
    let adminChildren: any = [];
    for (let i = 0; i < adminMenuArr?.[0]?.['children']?.length; i++) {
      for (let k = 0; k < adminMenuitems.length; k++) {
        if (
          loginObj?.[adminMenuitems[k]] &&
          adminMenuArr?.[0]?.['children'][i]['key'] == adminMenuitems[k]
        ) {
          adminChildren.push(adminMenuArr[0]?.['children'][i]);
        }
      }
    }
    // console.log(MenuArr, adminMenuArr);
    // console.info(adminChildren);
    if (adminMenuArr.length > 0) adminMenuArr[0].children = adminChildren;
    this.menuItems = [...MenuArr, ...adminMenuArr].sort(
      (a, b) => parseInt(a.id) - parseInt(b.id)
    );
    this.commonService.menuItemforGuard.emit(this.menuItems)
  }

  changePwdFormSubmit = () => {
    //console.log(this.changePwdForm, this.changePwdForm.valid);
    if (this.changePwdForm.valid) {
      let securedData = {
        username: this.username,
        newPassword: this.changePwdForm.value?.['newPwd'],
        currPass: this.changePwdForm.value?.['currentPwd'],
      };
      let encoded_securedData = btoa(JSON.stringify(securedData));
      this.authService.profileChangePassword(encoded_securedData).subscribe({
        next: (response: any) => {
          // console.log('Password updated successfully', response);
          // this.toaster.success(
          //   response.message || 'Password updated successfully'
          // );
          this.messageService.add({
            severity: 'success',
            detail: response.message || 'Password updated successfully'
          });
          this.modalService.close();
          this.router.navigate(['/login']);
        },
        error: (err) => {
          // console.log(err, 'Unable to change the password from Profile page');
          // this.toaster.error(
          //   err.error?.error?.message ||
          //   'Unable to change the password from Profile page'
          // );
          this.messageService.add({
            severity: 'error',
            detail: err.error?.error?.message ||
              'Unable to change the password from Profile page'
          });
        },
      });
    }
  };

  resetChangePwdForm = () => {
    this.changePwdForm.reset();
    this.changePwdForm.markAsPristine();
    this.changePwdForm.markAsUntouched();
  };

  public goToSubMenuPage = (parentLink: string, routerLink: string) => {
    //console.log(parentLink + '/' + routerLink);
    this.router.navigate(['app/' + routerLink]);
  };

  //Unsed goto page items
  public goToPage = (menulink: string) => {
    this.router.navigate(['app/' + menulink]);
  };

  public signOut = () => {
    // this.authService.signOut().subscribe({
    //   next: (response: any) => {
    //     console.log("response---signout", response)
    //     // this.router.navigate(['/login']);
    //     sessionStorage.clear();
    //   }
    // })
    this.commonService.deleteAllCookies();
    let url = RestURL.USER_LOGOUT;
    let signOutUrl = this.commonService.restDomainURL + url;
    this.http.get(signOutUrl).subscribe((data) => {
      // console.log('data---signout', data);
      // this.authService.stopRefreshtoken();
      this.router.navigate(['/login']);
      this.toastService.add({
        type: 'success',
        message: data['message'],
      });
      if (document.getElementById('toast')) {
        setTimeout(() => {
          document.getElementById('closeButton').focus();
        }, 0);
      }
      setTimeout(() => {
        document.getElementById('username').focus();
      }, 1000);
      sessionStorage.clear();
    });
  };

  openProfileModal() {
    this.username = sessionStorage.getItem('username') as string;
    if (!this.currentLoggedInUserDetails) {
      this.authService.getCurrentUserDetails(this.username).subscribe({
        next: (response: any) => {
          this.currentLoggedInUserDetails = response[0];
          this.modalService.open('profileModal');
        },
        error: (err) => {
          console.log(err, 'Unable to get the User  details logged in...');
        },
      });
    } else {
      this.modalService.open('profileModal');
    }
  }

  openChangePwdModal() {
    this.resetChangePwdForm();
    this.modalService.open('ChangePwdModal');
  }
  openupdateUnlock() {
    this.router.navigate(['updateunlock']);
  }

  showRoleDetails() {
    this.authService.getRole().subscribe({
      next: (response: any) => {
        let dataSuccess = response;
        this.roleLists = dataSuccess.roles.role;
        // this.roles = this.roleLists.roleName;
        if (this.roleLists && this.roleLists.length) {
          let uniqueFormatArr = this.roleLists
            .filter(
              (format: any, index: any, self: any) =>
                index ===
                self.findIndex((p: any) => p.roleName === format.roleName)
            )
            ?.map(function (item: any) {
              return { label: item?.roleName, value: item?.roleName };
            });
          this.roleLists = [...uniqueFormatArr];
        }
        // this.toaster.success(dataSuccess.message);
        this.modalService.open('switchRoleModal');
      },
      error: (err) => {
        // this.toaster.error(err?.error?.error?.message);
        this.messageService.add({
          severity: 'error',
          detail: err?.error?.error?.message
        });
        this.modalService.open('switchRoleModal');
      },
    });
  }

  submitSwitchRole(roles: any) {
    this.roles = {};
    this.authService.getSwitchRole(roles).subscribe({
      next: (response: any) => {
        let dataSuccess = response;
        this.finalRole = dataSuccess;
        this.commonService.getSwitchRoleResponse(this.finalRole);
        this.modalService.close();
      },
      error: (err) => {
        // this.toaster.error(err?.error?.error?.message);
        this.messageService.add({
          severity: 'error',
          detail: err?.error?.error?.message
        });
      },
    });
  }
  switchRoletonavigate() {
    // console.log("innnn")
  }

  closeDownload() {
    this.modalService.close();
  }

  toggleDropdown(event: Event) {
    const keyboardEvent = event as KeyboardEvent;
    const dropdownToggle = keyboardEvent.target as HTMLElement;
    const dropdownMenu = dropdownToggle.nextElementSibling as HTMLElement;

    // Toggle the "aria-expanded" attribute
    const isExpanded = dropdownToggle.getAttribute('aria-expanded') === 'true';
    dropdownToggle.setAttribute('aria-expanded', String(!isExpanded));

    // If the dropdown is now expanded, focus on the first item
    if (!isExpanded) {
      dropdownMenu.style.display = 'block'; // Ensure the dropdown is visible
      const firstLink = dropdownMenu.querySelector('.nav-link') as HTMLElement;
      firstLink?.focus();
    } else {
      dropdownMenu.style.display = 'none'; // Hide the dropdown if collapsing
    }
  }
}
