<div class="container">
  <div class="row col-md-12 border-bottom">
    <div class="col-md-6">
      <h4 class="mt-3" tabindex="0">Dashboard</h4>
    </div>
    <div class="col-md-6 text-end d-flex align-items-center justify-content-end">
      <button title="Page Refresh" tabindex="0" role="button" class="btn btn-custom btn-sm rounded-0 mb-1"
        (click)="loadDashboardDetails(dashBoardFilter)"><i class="fas fa-redo-alt"></i></button>
    </div>
  </div>
  <div class="row align-items-start">
    <div class="col-md-4">
      <div class="form-group pt-3 pb-3">
        <label for="Report-Duartion" class="col form-label">Report Duartion</label>
        <div class="col-md-12">
          <p-dropdown [options]="reportDurationData"  [(ngModel)]="dashBoardFilter" placeholder="Select a Report Duration"  styleClass="full-width-dropdown" (onChange)="loadDashboardDetails(dashBoardFilter)"></p-dropdown>
          <!-- <select class="form-select rounded-0" id="Report-Duartion" [(ngModel)]="dashBoardFilter"
            (ngModelChange)="loadDashboardDetails(dashBoardFilter)" name="Report-Duartion">
            <option [ngValue]="'all'">All</option>
            <option [ngValue]="'Current Day'">Current Day</option>
            <option [ngValue]="'Previous Day'">Previous Day</option>
            <option [ngValue]="'Current Week'">Current Week</option>
            <option [ngValue]="'Current Month'">Current Month</option>
            <option [ngValue]="'Previous 90 Days'">Previous 90 Days</option>
            <option [ngValue]="'customRage'">Custom Range</option>
          </select> -->
        </div>
      </div>
    </div>
    <div class="col-md-4">
      <div class="form-group pt-3 pb-3">
        <label for="Report-Duartion" class="col form-label">Keyword</label>
        <div class="col-md-12">
          <form class="navbar-form" role="search">
            <div class="input-group add-on">
              <input class="form-control rounded-0" placeholder="Click search icon to search all pages"
                [(ngModel)]="keywordSearchValue" name="srch-term" id="srch-term" type="text" />
              <div class="input-group-btn z-0">
                <button (click)="loadDashboardDetailsByKey()" class="btn btn-default rounded-0 border"><i
                    class="fas fa-search"></i></button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <div class="row align-items-center calc-height1">
    <div class="col-md-4 mb-5">
      <div class="card card-btn shadow h-100 rounded-0 cursor-pointer" style="background-color: #012169;"
        (click)="navigateToDetailsPage({clickEventFrom: 'widget', filterStatusVal: 'all'})">
        <div class="card-body text-white">
          <div class="row no-gutters align-items-center">
            <div class="col text-center">
              <div class="text-xs mb-1">Total Processed Requests</div>
              <div class="h5 mb-0">{{dashboardCountData?.TotalValidationRequests}}</div>
            </div>
            <div class="col-auto mx-auto">
              <svg class="svg-inline--fa fa-chart-line fa-w-16 fa-3x" aria-hidden="true" focusable="false"
                data-prefix="fas" data-icon="chart-line" role="img" xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512" data-fa-i2svg="">
                <path fill="currentColor"
                  d="M496 384H64V80c0-8.84-7.16-16-16-16H16C7.16 64 0 71.16 0 80v336c0 17.67 14.33 32 32 32h464c8.84 0 16-7.16 16-16v-32c0-8.84-7.16-16-16-16zM464 96H345.94c-21.38 0-32.09 25.85-16.97 40.97l32.4 32.4L288 242.75l-73.37-73.37c-12.5-12.5-32.76-12.5-45.25 0l-68.69 68.69c-6.25 6.25-6.25 16.38 0 22.63l22.62 22.62c6.25 6.25 16.38 6.25 22.63 0L192 237.25l73.37 73.37c12.5 12.5 32.76 12.5 45.25 0l96-96 32.4 32.4c15.12 15.12 40.97 4.41 40.97-16.97V112c.01-8.84-7.15-16-15.99-16z">
                </path>
              </svg>
            </div>
          </div>
        </div>
        <div class="card-footer text-muted text-center p-2 footer-hover"> <a
            class="card-box-footer text-white text-decoration-none">
            View More
            <svg class="svg-inline--fa fa-arrow-circle-right fa-w-16" aria-hidden="true" focusable="false"
              data-prefix="fa" data-icon="arrow-circle-right" role="img" xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512" data-fa-i2svg="">
              <path fill="currentColor"
                d="M256 8c137 0 248 111 248 248S393 504 256 504 8 393 8 256 119 8 256 8zm-28.9 143.6l75.5 72.4H120c-13.3 0-24 10.7-24 24v16c0 13.3 10.7 24 24 24h182.6l-75.5 72.4c-9.7 9.3-9.9 24.8-.4 34.3l11 10.9c9.4 9.4 24.6 9.4 33.9 0L404.3 273c9.4-9.4 9.4-24.6 0-33.9L271.6 106.3c-9.4-9.4-24.6-9.4-33.9 0l-11 10.9c-9.5 9.6-9.3 25.1.4 34.4z">
              </path>
            </svg>
          </a> </div>
      </div>
    </div>
    <div class="col-md-4 mb-5">
      <div class="card card-btn shadow h-100 rounded-0 cursor-pointer" style="background-color: #279f00;"
        (click)="navigateToDetailsPage({clickEventFrom: 'widget', filterStatusVal: 'success'})">
        <div class="card-body text-white">
          <div class="row no-gutters align-items-center">
            <div class="col text-center">
              <div class="text-xs mb-1">Successful Requests</div>
              <div class="h5 mb-0">{{dashboardCountData?.Successfull}}</div>
            </div>
            <div class="col-auto mx-auto">
              <svg class="svg-inline--fa fa-sort-amount-up fa-w-16 fa-3x" aria-hidden="true" focusable="false"
                data-prefix="fas" data-icon="sort-amount-up" role="img" xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512" data-fa-i2svg="">
                <path fill="currentColor"
                  d="M304 416h-64a16 16 0 0 0-16 16v32a16 16 0 0 0 16 16h64a16 16 0 0 0 16-16v-32a16 16 0 0 0-16-16zM16 160h48v304a16 16 0 0 0 16 16h32a16 16 0 0 0 16-16V160h48c14.21 0 21.38-17.24 11.31-27.31l-80-96a16 16 0 0 0-22.62 0l-80 96C-5.35 142.74 1.77 160 16 160zm416 0H240a16 16 0 0 0-16 16v32a16 16 0 0 0 16 16h192a16 16 0 0 0 16-16v-32a16 16 0 0 0-16-16zm-64 128H240a16 16 0 0 0-16 16v32a16 16 0 0 0 16 16h128a16 16 0 0 0 16-16v-32a16 16 0 0 0-16-16zM496 32H240a16 16 0 0 0-16 16v32a16 16 0 0 0 16 16h256a16 16 0 0 0 16-16V48a16 16 0 0 0-16-16z">
                </path>
              </svg>
            </div>
          </div>
        </div>
        <div class="card-footer text-muted text-center p-2 footer-hover"> <a
            class="card-box-footer text-white text-decoration-none">
            View More
            <svg class="svg-inline--fa fa-arrow-circle-right fa-w-16" aria-hidden="true" focusable="false"
              data-prefix="fa" data-icon="arrow-circle-right" role="img" xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512" data-fa-i2svg="">
              <path fill="currentColor"
                d="M256 8c137 0 248 111 248 248S393 504 256 504 8 393 8 256 119 8 256 8zm-28.9 143.6l75.5 72.4H120c-13.3 0-24 10.7-24 24v16c0 13.3 10.7 24 24 24h182.6l-75.5 72.4c-9.7 9.3-9.9 24.8-.4 34.3l11 10.9c9.4 9.4 24.6 9.4 33.9 0L404.3 273c9.4-9.4 9.4-24.6 0-33.9L271.6 106.3c-9.4-9.4-24.6-9.4-33.9 0l-11 10.9c-9.5 9.6-9.3 25.1.4 34.4z">
              </path>
            </svg>
          </a> </div>
      </div>
    </div>
    <div class="col-md-4 mb-5">
      <div class="card card-btn shadow h-100 rounded-0 cursor-pointer" style="background-color: #c41230;"
        (click)="navigateToDetailsPage({clickEventFrom: 'widget', filterStatusVal: 'failure'})">
        <div class="card-body text-white">
          <div class="row no-gutters align-items-center">
            <div class="col text-center">
              <div class="text-xs mb-1">Failed Requests</div>
              <div class="h5 mb-0">{{dashboardCountData?.Failure}}</div>
            </div>
            <div class="col-auto mx-auto">
              <svg class="svg-inline--fa fa-sort-amount-down fa-w-16 fa-3x" aria-hidden="true" focusable="false"
                data-prefix="fas" data-icon="sort-amount-down" role="img" xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512" data-fa-i2svg="">
                <path fill="currentColor"
                  d="M304 416h-64a16 16 0 0 0-16 16v32a16 16 0 0 0 16 16h64a16 16 0 0 0 16-16v-32a16 16 0 0 0-16-16zm-128-64h-48V48a16 16 0 0 0-16-16H80a16 16 0 0 0-16 16v304H16c-14.19 0-21.37 17.24-11.29 27.31l80 96a16 16 0 0 0 22.62 0l80-96C197.35 369.26 190.22 352 176 352zm256-192H240a16 16 0 0 0-16 16v32a16 16 0 0 0 16 16h192a16 16 0 0 0 16-16v-32a16 16 0 0 0-16-16zm-64 128H240a16 16 0 0 0-16 16v32a16 16 0 0 0 16 16h128a16 16 0 0 0 16-16v-32a16 16 0 0 0-16-16zM496 32H240a16 16 0 0 0-16 16v32a16 16 0 0 0 16 16h256a16 16 0 0 0 16-16V48a16 16 0 0 0-16-16z">
                </path>
              </svg>
            </div>
          </div>
        </div>
        <div class="card-footer text-muted text-center p-2 footer-hover"> <a
            class="card-box-footer text-white text-decoration-none">
            View More
            <svg class="svg-inline--fa fa-arrow-circle-right fa-w-16" aria-hidden="true" focusable="false"
              data-prefix="fa" data-icon="arrow-circle-right" role="img" xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512" data-fa-i2svg="">
              <path fill="currentColor"
                d="M256 8c137 0 248 111 248 248S393 504 256 504 8 393 8 256 119 8 256 8zm-28.9 143.6l75.5 72.4H120c-13.3 0-24 10.7-24 24v16c0 13.3 10.7 24 24 24h182.6l-75.5 72.4c-9.7 9.3-9.9 24.8-.4 34.3l11 10.9c9.4 9.4 24.6 9.4 33.9 0L404.3 273c9.4-9.4 9.4-24.6 0-33.9L271.6 106.3c-9.4-9.4-24.6-9.4-33.9 0l-11 10.9c-9.5 9.6-9.3 25.1.4 34.4z">
              </path>
            </svg>
          </a> </div>
      </div>
    </div>
  </div>
  <div *ngIf="dashBoardData?.length">
    <!-- <app-table [tableInfo]="dashboardTableData" [tableDataToDisplay]="dashBoardData" [searchKey]="keywordSearchValue" (clickEventHandle)="navigateToDetailsPage($event)"></app-table> -->
    <caption style="caption-side: top;" class="clearfix row">
      <div class="row clearfix m-0 text-primary">
        <div class="mr-auto col-md-8 p-0 mb-1">
          <span>List of Clients</span>
          <span>&nbsp;/&nbsp;</span>
          <span>Users</span>
          <span> ({{dashBoardData?.length}} <span>Results)</span>
          </span>
        </div>
      </div>
      <hr class="my-1" role="presentation">
    </caption>

    <div class="table-responsive mb-5">
      <div *ngIf="dashBoardData?.length" class="col-md-12 overflow-auto mb-10">
        <app-table [tableInfo]="dashboardTableData" [tableDataToDisplay]="dashBoardData"
          [searchKey]="keywordSearchValue" (clickEventHandle)="navigateToDetailsPage($event)"></app-table>
      </div>
    </div>
  </div>