import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { LoginComponent } from './modules/login/login.component';
import { TestComponent } from './modules/test/test.component';
import { MainComponent } from './modules/main/main.component';
import { FilevalidationComponent } from './modules/filevalidation/filevalidation.component';
import { DashboardComponent } from './modules/dashboard/dashboard.component';
import { VerifyOTPComponent } from './modules/verify-otp/verify-otp.component';
import { AccountUnlockComponent } from './modules/account-unlock/account-unlock.component';
import { FormsComponent } from './core/forms/forms.component';
import { CrudComponent } from './modules/crud/crud.component';
import { StpGuideComponent } from './modules/stp-guide/stp-guide.component';
import { ValidatorComponent } from './modules/validator/validator.component';
import { DetailspageComponent } from './modules/detailspage/detailspage.component';
import { DashboardDetailsComponent } from './modules/dashboard-details/dashboard-details/dashboard-details.component';
import { VapiconstructorComponent } from './modules/vapiconstructor/vapiconstructor.component';
import { UpdateUnlockComponent } from './modules/updateUnlock/update-unlock/update-unlock.component';
import { authGuard } from './guard/auth.guard';
const routes: Routes = [
  {
    path: '',
    component: LoginComponent,
  },
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'accountunlock',
    component: AccountUnlockComponent,
  },
  {
    path: 'updateunlock',
    component: UpdateUnlockComponent,
  },
  {
    path: 'verifyOTP',
    component: VerifyOTPComponent,
  },
  {
    path: 'test',
    component: TestComponent,
  },
  {
    path: 'app',
    component: MainComponent,
    children: [
      {
        path: 'dashboard',
        component: DashboardComponent,
        canActivate: [authGuard],
      },
      {
        path: 'detailspage',
        component: DashboardDetailsComponent,
        canActivate: [authGuard],
      },
      {
        path: 'dashboard-details',
        component: DashboardDetailsComponent,
        canActivate: [authGuard],
      },
      {
        path: 'stpguide',
        component: StpGuideComponent,
        canActivate: [authGuard],
      },
      {
        path: 'filevalidation',
        component: FilevalidationComponent,
        canActivate: [authGuard],
      },
      {
        path: 'validator',
        component: ValidatorComponent,
        canActivate: [authGuard],
      },
      {
        path: 'vapiconstructor',
        component: VapiconstructorComponent,
        canActivate: [authGuard],
      },
      {
        path: ':name/:action',
        component: CrudComponent,
        canActivate: [authGuard],
      },
    ],
  },
  {
    path: '**',
    component: PageNotFoundComponent,
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      onSameUrlNavigation: 'reload',
      bindToComponentInputs: true,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }
