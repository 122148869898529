<header class="bg-white shadow-sm mb-1">
    <a id="skipNav" class="skip-main" tabindex="0" role="navigation" (click)="skiptomain()"
        (keydown.enter)="mainFocus()" aria-label="Skip to main content">Skip to main content</a>
    <div class="text-center">
        <a class="navbar-brand d-inline-block header" target="_blank">
            <img src="assets/themes/images/Bofa1Logo.png" alt="Bank Of America's CashPro® Validator logo Primary"
                class="mt-1">
        </a>
    </div>
    <div>
        <div class="p-1 px-3">
            <div>
                <h3 *ngIf="!showLoginHeader" class="f-25"> &nbsp;&nbsp;CashPro&reg; Validator </h3>
                <h1 *ngIf="showLoginHeader" class="f-25"> &nbsp;&nbsp;CashPro® Validator-Login</h1>
            </div>
        </div>
    </div>
</header>