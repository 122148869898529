import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {
  private loaderVisible = false;

  show() {
    // console.log('Loader is being shown');
    this.loaderVisible = true;
  }

  hide() {
    // console.log('Loader is being hidden');
    this.loaderVisible = false;
  }

  isVisible(): boolean {
    return this.loaderVisible;
  }
}