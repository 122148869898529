<!-- <div class="modal fade" id="keepAliveModal" tabindex="-1" aria-labelledby="keepAliveModalLabel" >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="keepAliveModalLabel">Session Timeout Warning</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <p>You will be timed out in {{ countdown }} seconds due to inactivity.</p>
        </div>
      </div>
    </div>
  </div> -->
<div class="modal modal-content" id="keepAliveModal" tabindex="-1" aria-labelledby="keepAliveModalLabel">
  <div class="modal-header modal-header-warning">
    <h4 class="modal-title">You're Idle. Do Something!</h4>
  </div>
  <div class="modal-body" idle-countdown="countdown">
    You'll be logged out in <span class="badge badge-warning"></span> second(s).

    <div class="progress">
      <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="75"
        aria-valuemin="0" aria-valuemax="100" [ngStyle]="{'width': countdown*20+'%'}"></div>
    </div>
  </div>
</div>