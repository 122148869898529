<app-navbarmenus [menus]="AuthorizedMenus" [username]="currentUserName" />
<div class="vp-main" role="main">
    <div id="vp-main">
        <router-outlet></router-outlet>
    </div>
</div>
<app-modal id="sessionExpiredModal" style="display: none;">
    <div class="modal-header">
        <h1 class="modal-title fs-5" id="Session-Expired-Label">Session Expired</h1>
        <button type="button" class="btn-close" (click)="movetoLoginpage()"></button>
    </div>
    <div class="modal-body Session-Expired row">
        <div class="col-md-2">
            <h1> <i class="fas fa-exclamation-triangle text-danger"></i></h1>
        </div>
        <div class="col-md-10">
            <p>
                Your session expired due to inactivity.<br>
                Please sign out and sign in again.
            </p>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class=" h-btn btn btn-primary border-0 rounded-0" (click)="movetoLoginpage()">Sign
            Out</button>
    </div>
</app-modal>