import { Component, Input, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { CommonService } from 'src/app/services/common/common.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  showLoginHeader = false;
  constructor(private commonService: CommonService, private router: Router) { }

  ngOnInit(): void {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        // Check if the current route is 'login'
        this.showLoginHeader = (event.url === '/login') || (event.url === '/');
      }
    });
  }

  public skiptomain = () => {
    this.commonService.triggerMainFocus();
  }

  public mainFocus = () => {
    document.getElementById('skipNav')?.click();
  }
}

