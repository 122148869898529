<div class="p-3 pt-0 pb-0">
  <div>
    <div class="row">
      <div class="row pt-3">
        <div class="col-md-6">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item"><a href="#/app/dashboard">Dashboard</a></li>
              <li class="breadcrumb-item active" aria-current="page">Validation Request Details</li>
            </ol>
          </nav>
        </div>
        <div class="col-md-6 text-end d-flex align-items-center justify-content-end px-0">
          <button title="Page Refresh" tabindex="0" role="button" class="btn btn-custom btn-sm rounded-0 mb-1" (click)="fetchDashboardDetail({MessageType: messageType,
            clientid: clientId})">
            <i class="fas fa-redo-alt"></i>
          </button>
        </div>
      </div>
      <div class="col-md-3">
        <div class="form-group pt-3 pb-3">
          <label for="Report-Duartion" class="col form-label">Keyword</label>
          <div class="col-md-12">
            <form class="navbar-form" role="search">
              <div class="input-group add-on">
                <input class="form-control rounded-0" placeholder="Click search icon to search all pages"
                  [(ngModel)]="keywordSearchValue" name="srch-term" id="srch-term" type="text" />
                <div class="input-group-btn z-0">
                  <button (click)="loadDashboardDetailsByKey()" class="btn btn-default rounded-0 border"><i
                      class="fas fa-search"></i></button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

      <div class=" col-md-3 form-group pt-3 pb-3">
        <label for="sel1" class="col form-label">Client ID</label>
        <div>
          <p-dropdown [options]="clients1" [(ngModel)]="clientId" placeholder="Select a Client"
            styleClass="full-width-dropdown" (onChange)="fetchDashboardDetail({MessageType: messageType,
            clientid: clientId})"></p-dropdown>
          <!-- <select class="form-select rounded-0" id="clientID" name="clientID" [(ngModel)]="clientId" (ngModelChange)="fetchDashboardDetail({
            MessageType: messageType,
            clientid: clientId,
          })">
            <option [ngValue]="'All'">ALL</option>
            <option *ngFor="let client of clients" [ngValue]="client.clientid">{{ client.clientid }}</option>
          </select> -->
          <!-- <select2 class="rounded-0" tabindex="0" id="messageType" name="messageType" aria-label="Format"
            placeholder="Select" title="Format of the input message" [data]="data" [(ngModel)]="messageType"
            id="selec2_msg" (ngModelChange)="fetchDashboardDetail({
            MessageType: messageType,
            clientid: clientId,
          })">
          </select2> -->
        </div>
      </div>
      <div class=" col-md-3 form-group pt-3 pb-3">
        <label for="sel1" class="col form-label ">Format</label>
        <div>
          <!-- <select class="form-select rounded-0" id="messageType" name="messageType" [(ngModel)]="messageType"
            (ngModelChange)="fetchDashboardDetail({
            MessageType: messageType,
            clientid: clientId,
          })" name="sellist1">
            <option [ngValue]="'All'">ALL</option>
            <option [ngValue]="'2'">2</option>
            <option [ngValue]="'3'">3</option>
            <option [ngValue]="'4'">4</option>
          </select> -->
          <p-dropdown [options]="data" [filter]="true" filterBy="label" [(ngModel)]="messageType"
            placeholder="Select a Format of the input message" styleClass="full-width-dropdown" (onChange)="fetchDashboardDetail({MessageType: messageType,
            clientid: clientId})"></p-dropdown>

          <!-- <select2 class="rounded-0" tabindex="0" id="messageType" name="messageType" aria-label="Format"
            placeholder="Select" title="Format of the input message" [data]="data" [(ngModel)]="messageType"
            id="selec2_msg" (ngModelChange)="fetchDashboardDetail({
            MessageType: messageType,
            clientid: clientId,
          })">
          </select2> -->
        </div>
      </div>
      <div class=" col-md-3 form-group pt-3 pb-3">
        <label for="sel1" class="col form-label info">Report Duration </label>
        <div>
          <p-dropdown [options]="reportDurationData" [(ngModel)]="dashBoardFilter"
            placeholder="Select a Report Duration" styleClass="full-width-dropdown" (onChange)="fetchDashboardDetail({MessageType: messageType,
            clientid: clientId})"></p-dropdown>

          <!-- <select class="form-select rounded-0" id="Report-Duartion" [(ngModel)]="dashBoardFilter" (ngModelChange)="fetchDashboardDetail({
            MessageType: messageType,
            clientid: clientId,
          })" name="Report-Duartion">
            <option [ngValue]="'all'">All</option>
            <option [ngValue]="'Current Day'">Current Day</option>
            <option [ngValue]="'Previous Day'">Previous Day</option>
            <option [ngValue]="'Current Week'">Current Week</option>
            <option [ngValue]="'Current Month'">Current Month</option>
            <option [ngValue]="'Previous 90 Days'">Previous 90 Days</option>
            <option [ngValue]="'customRage'">Custom Range</option>
          </select> -->
        </div>
      </div>
    </div>
  </div>




  <div>
    <caption style="caption-side: top;" class="clearfix row">
      <div class=" col-md-12 px-row clearfix m-0 commonHeaderColor">
        <span>List of Request Details &nbsp;</span>
        <span>({{dashBoardDetailsData.length}}<span>&nbsp;Results)</span></span>
        <hr>
      </div>
    </caption>

    <div *ngIf="dashBoardDetailsData?.length" class="col-md-12 overflow-auto calc-height-dashboard mb-10">
      <app-table [tableInfo]="dashboardDetailsTableData" [tableDataToDisplay]="dashBoardDetailsData"
        [searchKey]="keywordSearchValue" (clickEventHandle)="handleClick($event)"
        (downloadEventHandle)="downloadFile($event)"></app-table>
    </div>
  </div>

  <app-modal id="downloadFileModal" style="display: none;">
    <div class="modal-header">
      <h5 class="modal-title">Download Validation Reports </h5>
      <button type="button" class="btn-close" (click)="modalService.close();"></button>
    </div>
    <div class="modal-body">

      <h6 class="mb-3">Please select the option you want to download.</h6>
      <form #downloadModalForm="ngForm" (ngSubmit)="submitDownloadModalForm(downloadModalForm)">
        <div class="form-group mb-3">
          <label for="downloadFormat">Download Format</label>
          <select class="form-control rounded-0 mt-2 custom-select-apparance" id="downloadFormat" name="downloadFormat"
            [(ngModel)]="desiredReportFormatOption">
            <option [value]='"text"'>Validation Report - Text Format</option>
            <option [value]='"pdf"' selected="selected">Validation Report - PDF Format</option>
          </select>
        </div>
        <div class="modal-footer">
          <button type="submit" class="h-btn btn btn-primary rounded-0 mx-2 ms-0">Submit</button>
          <button type="reset" class="h-btn btn btn-secondary rounded-0" (click)="closeDownload()">Close</button>
        </div>
      </form>
    </div>
  </app-modal>

  <app-modal id="CustomRangeModal" style="display: none;">
    <div class="modal-header">
      <h5 class="modal-title">Select Required Date Range </h5>
      <button type="button" class="btn-close" (click)="modalService.close();"></button>
    </div>
    <div class="modal-body">
      <form (ngSubmit)="submitrangepicker()">
        <div class="form-group mb-3">
          <label for="customDate">Custom Range</label>
          <div class="input-group">
            <label for="startDate" class="m-2">From</label>
            <input type="date" class="form-control" aria-label="Start" [(ngModel)]="startDate" format="dd/mm/yyyy"
              id="startDate" name="startDate" placeholder="From" />

            <label for="endDate" class="m-2">To</label>
            <input type="date" class="form-control" aria-label="end" [(ngModel)]="endDate" format="dd/mm/yyyy"
              id="endDate" name="endDate" placeholder="To" />
          </div>
        </div>
        <div class="modal-footer">
          <button type="submit" class="h-btn btn btn-primary rounded-0 mx-2 ms-0">Confirm</button>
          <button type="reset" class="h-btn btn btn-secondary rounded-0" (click)="closeDownload()">Cancel</button>
        </div>
      </form>
    </div>
  </app-modal>