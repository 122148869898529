import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CommonService } from '../common/common.service';
import RestURL from 'src/assets/config/restURLConstant.json';
import { Observable, catchError, of, tap } from 'rxjs';
import ConfigData from 'src/assets/config/config.json';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  loginResp: any = null;
  refreshTokenInterval: any;
  headers: any;
  constructor(private http: HttpClient, private commonService: CommonService) { }

  login(authData: any) {
    let authUrl = this.commonService.restDomainURL + RestURL.USER_LOGIN;
    return this.http.post(authUrl, authData);
  }

  signOut() {
    let signOutUrl = this.commonService.restDomainURL + RestURL.USER_LOGOUT;
    return this.http.get(signOutUrl);
  }

  getCurrentUserDetails(uname: string) {
    let loggedInUserDetails =
      this.commonService.restDomainURL + RestURL.LOGGEDIN_USER_DETAILS;
    return this.http.get(loggedInUserDetails, {
      params: new HttpParams().set('input', uname),
    });
  }

  changePwdWthOTP(data: any) {
    let changepedUrl =
      this.commonService.restDomainURL + RestURL.USER_OTP_SUBMIT;
    return this.http.post(changepedUrl, data);
  }

  resetPwd(data: any) {
    let resetpwdUrl =
      this.commonService.restDomainURL + RestURL.USER_FORGOT_PWD;
    return this.http.post(resetpwdUrl, data);
  }

  userChangePwdreset(data: any) {
    let changeresetpwdUrl =
      this.commonService.restDomainURL + RestURL.USER_CHGPWD_RESET;
    return this.http.post(changeresetpwdUrl, data);
  }

  getChallengeQus() {
    let getChaQusUrl =
      this.commonService.restDomainURL + RestURL.GET_CHALLENGEQUESTION;
    return this.http.get(getChaQusUrl);
  }

  getsecurityQus() {
    let getSecQusUrl =
      this.commonService.restDomainURL + RestURL.GET_SECURITYQUESTION;
    return this.http.get(getSecQusUrl);
  }

  getprechalngQus() {
    let getSecPreChlngUrl =
      this.commonService.restDomainURL + RestURL.PREPOPULATE_CHALLENGEQUESTION;
    return this.http.get(getSecPreChlngUrl);
  }

  postsecurityQus(data: any) {
    let postsecurityQusUrl =
      this.commonService.restDomainURL + RestURL.POST_SECURITYQUESTION;
    return this.http.post(postsecurityQusUrl, data);
  }

  finalChallQus(data: any) {
    let finalChallQusUrl =
      this.commonService.restDomainURL + RestURL.FINAL_CHALLENGEQUESTION;
    return this.http.post(finalChallQusUrl, data);
  }
  getOTPdts(data: any) {
    let getOTPUrl =
      this.commonService.restDomainURL + RestURL.OTP_CHALLENGEQUESTION;
    return this.http.post(getOTPUrl, data);
  }

  finalResetChallQus(username: any, data: any) {
    let finalResetChallQusUrl =
      this.commonService.restDomainURL +
      RestURL.FINAL_RESETCHALLENGEANS +
      '?input=' +
      username;
    return this.http.post(finalResetChallQusUrl, data);
  }
  profileChangePassword(data: any) {
    let profileChangePwdURL =
      this.commonService.restDomainURL + RestURL.USER_PROFILE_CHANGE_PWD;
    return this.http.post(profileChangePwdURL, data);
  }

  createClient(data: any) {
    let createClientURL =
      this.commonService.restDomainURL + RestURL.CREATECLIENT_USER;
    return this.http.post(createClientURL, data);
  }

  userResetChangePwd(data: any) {
    let userResetChangePwdURL =
      this.commonService.restDomainURL + RestURL.PUT_USERRESET_CHGPWD;
    return this.http.put(userResetChangePwdURL, data);
  }

  putSecQues(data: any) {
    let putSecQuesURL =
      this.commonService.restDomainURL + RestURL.PUT_SECURITYQUESTION;
    return this.http.put(putSecQuesURL, data);
  }

  getAllRegions() {
    let getlistofURL =
      this.commonService.restDomainURL + RestURL.GET_CREATECLIENTREGION_LIST;
    return this.http.get(getlistofURL);
  }

  createUser(data: any) {
    let createUSERURL =
      this.commonService.restDomainURL + RestURL.CREATEUSER_REGISTER;
    return this.http.post(createUSERURL, data);
  }

  getUserRegion() {
    let getUserRegionList =
      this.commonService.restDomainURL + RestURL.GET_CREATEDUSERREGION_LIST;
    return this.http.get(getUserRegionList);
  }

  getAllClients() {
    let getlistofclients =
      this.commonService.restDomainURL + RestURL.FETCH_CLIENTIDS;
    return this.http.get(getlistofclients);
  }

  userChkRegister() {
    let userChkRegisterUrl =
      this.commonService.restDomainURL + RestURL.USER_CHECK_REGISTERED;
    return this.http.get(userChkRegisterUrl);
  }

  getCatalog() {
    let getCatalogUrl = this.commonService.vapiDomainURL + RestURL.GET_CATALOG;
    return this.http.get(getCatalogUrl);
  }

  getVapiCatalog() {
    let getvapiCatalogUrl =
      this.commonService.vapiDomainURL + RestURL.GET_VAPICATALOG;
    return this.http.get(getvapiCatalogUrl);
  }

  getSSOLogin() {
    let newUrl = this.commonService.restDomainURL.replace('/v1/', '/');
    let getssologinUrl = newUrl + RestURL.GET_SAML_DETAILS;
    return this.http.get(getssologinUrl);
  }

  getRole() {
    let getRolenUrl = this.commonService.restDomainURL + RestURL.GET_ROLES;
    return this.http.get(getRolenUrl);
  }

  getSwitchRole(data: any) {
    let getSwitchRoleUrl =
      this.commonService.restDomainURL + RestURL.POST_SWITCHROLE + '?role=' + data;
    return this.http.post(getSwitchRoleUrl, data);
  }

  startRefreshToken() {
    // Refresh the token every 45 minutes (45 * 60 * 1000 ms)
    let refreshTokenTimer = ConfigData.AppConfig.tokenRefreshTime * 60 * 1000;
    // console.log('Refresh  token Start....');
    this.refreshTokenInterval = setInterval(() => {
      this.refreshToken().subscribe();
    }, refreshTokenTimer);
  }

  stopRefreshtoken() {
    // console.log('Refresh  token Stop....');
    clearInterval(this.refreshTokenInterval);
  }

  refreshToken(): Observable<any> {
    // Replace with your API endpoint and logic for refreshing the token
    // console.log('Refresh  token Progress....');
    let userChkRegisterUrl =
      this.commonService.restDomainURL + RestURL.REFRESH_TOKEN;
    return this.http.get(userChkRegisterUrl).pipe(
      tap((response: any) => {
        //this.token = response.token; // Save the new token
        sessionStorage.setItem('accessToken', response);
      }),
      catchError(this.handleError<any>('refreshToken'))
    );
  }

  handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      // console.log(error);
      return of(result as T);
    };
  }

}
