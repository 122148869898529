import { LocationStrategy, DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ModalComponent } from 'src/app/core/modal/modal.component';
import { DashboardService } from 'src/app/services/dashboard/dashboard.service';
import { ModalService } from 'src/app/services/common/modal.service';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/services/common/common.service';
import { R3SelectorScopeMode } from '@angular/compiler';
import { HttpClient } from '@angular/common/http';
import RestURL from 'src/assets/config/restURLConstant.json';
import { Select2Group, Select2Option } from 'ng-select2-component';
import { FormsModule } from '@angular/forms';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-dashboard-details',
  templateUrl: './dashboard-details.component.html',
  styleUrls: ['./dashboard-details.component.css'],
  providers: [ModalComponent, FormsModule],
})
export class DashboardDetailsComponent implements OnInit {
  dashBoardDetailsData: any = {};
  dashboardDetailsCountData: any = {};
  dashboardDetailsTableData: any = [];
  keywordSearchValue: string = '';
  dashBoardFilter: string = 'all';
  messageType: string = 'All';
  clientId: string = 'All';
  stateParams: any = {};
  desiredReportFormatOption: string = 'pdf';
  desiredReportID: string = '';
  clients: any;
  clients1: any = [];
  reportDurationData: any = [];
  data: (Select2Group | Select2Option)[];
  startDate: '';
  endDate: '';
  value: string = '';
  datevalue = {}
  constructor(
    private location: LocationStrategy,
    private dashboardService: DashboardService,
    public modalService: ModalService,
    private toastr: ToastrService,
    private commonService: CommonService,
    private http: HttpClient,
    private messageService: MessageService
  ) {
    this.stateParams = this.location.getState();
    this.reportDurationData = [
      {
        label: 'ALL',
        value: 'all',
      },
      {
        label: 'Current Day',
        value: 'Current Day',
      },
      {
        label: 'Previous Day',
        value: 'Previous Day',
      },
      {
        label: 'Current Week',
        value: 'Current Week',
      },
      {
        label: 'Current Month',
        value: 'Current Month',
      },
      {
        label: 'Previous 90 Days',
        value: 'Previous 90 Days',
      },
      {
        label: 'Custom Range',
        value: 'Custom Range',
      },
    ];
  }
  ngOnInit() {
    let payloadObj = {
      MessageType: 'All',
      clientid: 'all',
    };
    if (this.stateParams && this.stateParams['clickEventFrom'] !== 'widget') {
      payloadObj['MessageType'] = this.stateParams?.clickedData?.MessageType;
      payloadObj['clientid'] = this.stateParams?.clickedData?.clientid;
    }
    this.fetchDashboardDetail(payloadObj);

    /* To get Clientids*/
    let getlistofclients =
      this.commonService.restDomainURL +
      RestURL.FETCH_CLIENTIDS +
      '?input=dashboardDetail';
    this.http.get(getlistofclients).subscribe({
      next: (data: any) => {
        this.clients = data;
        this.clients1 = this.commonService.getMappedData(
          data,
          'clientid',
          'clientid'
        );
        this.clients1.unshift({ label: 'ALL', value: 'All' });
      },
      error: (err: any) => {
        // this.toastr.error(err?.error?.error?.message);
        this.messageService.add({
          severity: 'error',
          detail: err?.error?.error?.message
        });
      },
    });

    /* To get Format details*/
    this.commonService.getformatJsonData().subscribe((data) => {
      this.data = data.sort((a: any, b: any) => a.label.localeCompare(b.label));
      this.data.push({ label: 'ALL', value: 'ALL' });
      this.messageType = 'ALL';
    });
  }




  fetchDashboardDetail(payloadObj?: any) {
    let paramVal = { input: this.dashBoardFilter, status: this.stateParams.filterStatusVal };
    if (paramVal.input == 'Custom Range') {
      this.modalService.open('CustomRangeModal');
    } else {
      if (this.stateParams && this.stateParams['clickEventFrom'] === 'widget') {
        paramVal['status'] = this.stateParams?.filterStatusVal;
      } else {
        paramVal['status'] =
          this.stateParams?.headerOpt?.headerKey === 'NoOfMessage'
            ? 'All'
            : this.stateParams?.headerOpt?.headerKey?.toLowerCase();
      }
      this.dashboardService
        .fetchDashboardDetails(payloadObj, paramVal)
        .subscribe({
          next: (response: any) => {
            this.dashboardDetailsTableData['headers'] = [
              { label: 'Request ID', headerKey: 'Request_ID' },
              { label: 'Client ID', headerKey: 'Client_ID' },
              { label: 'Region', headerKey: 'Region' },
              { label: 'Format', headerKey: 'messagetype' },
              { label: 'Date and Time', headerKey: 'RequestDateTime' },
              { label: 'Status', headerKey: 'Status' },
              { label: '# of Fields with Errors', headerKey: 'NoofErrors' },
              {
                label: 'Input File',
                headerKey: 'Filename',
                details: { navigationLink: 'detailsPage' },
              },
              { label: 'Validation Report', actions: ['Download'] },

              // { label: 'Actions', actions: ['edit', 'delete'] },
            ]; // setting of the Table Columns
            this.dashBoardDetailsData = response;
            this.dashboardDetailsCountData = response?.length;
            // console.log(response);
          },
          error: (err: any) => {
            // console.log(err);
          },
        });
    }

  }


  fetchdashboarddetailbycustomrangefilter(Val) {
    this.value = JSON.stringify(Val);
    // console.log("this.value----", this.value, this.messageType)
    let payloadObj1 = {
      MessageType: this.messageType,
      clientid: 'All',
    };

    const encodedString = encodeURIComponent(this.value);
    let paramData;
    paramData = {
      input: encodedString,
      status: this.stateParams.filterStatusVal
    }
    this.dashboardService
      .fetchDashboardDetails(payloadObj1, paramData)
      .subscribe({
        next: (response: any) => {
          this.dashboardDetailsTableData['headers'] = [
            { label: 'Request ID', headerKey: 'Request_ID' },
            { label: 'Client ID', headerKey: 'Client_ID' },
            { label: 'Region', headerKey: 'Region' },
            { label: 'Format', headerKey: 'messagetype' },
            { label: 'Date and Time', headerKey: 'RequestDateTime' },
            { label: 'Status', headerKey: 'Status' },
            { label: '# of Fields with Errors', headerKey: 'NoofErrors' },
            {
              label: 'Input File',
              headerKey: 'Filename',
              details: { navigationLink: 'detailsPage' },
            },
            { label: 'Validation Report', actions: ['Download'] },

            // { label: 'Actions', actions: ['edit', 'delete'] },
          ]; // setting of the Table Columns
          this.dashBoardDetailsData = response;
          this.dashboardDetailsCountData = response?.length;
          this.modalService.close();
          // console.log(response);
        },
        error: (err: any) => {
          // console.log(err);
        },
      });


  }



  loadDashboardDetailsByKey() {
    let payloadObj = {
      Keyword: this.keywordSearchValue,
      Format: this.messageType,
      ReportDuration: this.dashBoardFilter,
      status: 'All',
    };
    this.dashboardService.fetchDashboardDetailsByKey(payloadObj).subscribe({
      next: (response: any) => {
        this.dashBoardDetailsData = response;
        this.dashboardDetailsCountData = response?.length;
      },
      error: (err: any) => {
        // console.log(err);
      },
    });
  }

  handleClick(event: any) {
    // console.log(event, 'Need to trigger Download sample');
    let payLoadObj = { ReportType: 'sample', ReqId: '' };
    if (event?.clickEventFrom === 'FileDownload') {
      payLoadObj = {
        ReportType: 'sample',
        ReqId: event?.clickedData?.Request_ID,
      };
    }

    this.dashboardService.downloadFile(payLoadObj).subscribe({
      next: (response: any) => {
        if (response?.success) {
          if (
            payLoadObj?.ReportType === 'text' ||
            payLoadObj?.ReportType == 'sample'
          ) {
            this.commonService.textDownload(
              this.commonService.b64DecodeUnicode(response?.message),
              'FileValidation_SampleFile_' + payLoadObj?.ReqId + '.xml'
            );
          }
          // this.toastr.success('File Downloaded Successfully');
          this.messageService.add({
            severity: 'success',
            detail: 'File Downloaded Successfully'
          });
        } else {
          // this.toastr.error(response.message);
          this.messageService.add({
            severity: 'error',
            detail: response.message
          });
        }
        this.modalService.close();
      },
      error: (err: any) => {
        // console.log(err);
      },
    });
  }

  downloadFile(event: any) {
    this.desiredReportID = '';
    this.desiredReportID =
      event?.clickEventFrom === 'Download'
        ? event?.clickedData?.Request_ID
        : '';
    this.modalService.open('downloadFileModal');
  }
  submitDownloadModalForm(form: any) {
    if (form.valid) {
      //console.log(form.value, 'form value here to print');
      let payLoadObj = {
        ReportType: form.value?.downloadFormat,
        ReqId: this.desiredReportID || '',
      };
      this.dashboardService.downloadFile(payLoadObj).subscribe({
        next: (response: any) => {
          if (response?.success) {
            if (form.value?.downloadFormat === 'text') {
              this.commonService.textDownload(
                this.commonService.b64DecodeUnicode(response?.message),
                'FileValidation_Report_' + this.desiredReportID + '.txt'
              );
            } else {
              //PDF Download block
              this.commonService.downloadSTPguidePdf(
                'FileValidation_Report_' + this.desiredReportID + '.pdf',
                response?.message
              );
            }
            // this.toastr.success('File Downloaded Successfully');
            this.messageService.add({
              severity: 'success',
              detail: 'File Downloaded Successfully'
            });

          } else {
            // this.toastr.error(response.message);
            this.messageService.add({
              severity: 'error',
              detail: response.message
            });
          }
          this.modalService.close();
        },
        error: (err: any) => {
          //console.log(err); //
          // this.toastr.error(err?.error?.error?.message);
          this.messageService.add({
            severity: 'error',
            detail: err?.error?.error?.message
          });
          this.modalService.close();
        },
      });
    }
  }
  saveDownloadedFile() { }
  closeDownload() {
    this.modalService.close();
  }

  submitrangepicker() {
    let startdate1 = new DatePipe('en-US').transform(this.startDate, 'dd/MM/yyyy');
    let endDate1 = new DatePipe('en-US').transform(this.endDate, 'dd/MM/yyyy');

    this.datevalue = {
      "startDate": startdate1,
      "endDate": endDate1
    }
    this.fetchdashboarddetailbycustomrangefilter(this.datevalue)
  }


}
