import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { CodemirrorComponent } from '@ctrl/ngx-codemirror';
import 'codemirror/mode/javascript/javascript';
import { Select2Data, Select2Group, Select2Option, Select2UpdateEvent } from 'ng-select2-component';
import { config } from 'rxjs';
import { FilevalidationInterface } from 'src/app/interfaces/filevalidation-interface';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/services/common/common.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MessageService } from 'primeng/api';
import * as CodeMirror from 'codemirror';
import 'codemirror/mode/javascript/javascript'; // Import the appropriate mode
import 'codemirror/addon/search/searchcursor'; // Import searchcursor addon
import 'codemirror/addon/search/search'; // Import search addon
import 'codemirror/addon/dialog/dialog'; // Optional: Import dialog for search UI
@Component({
  selector: 'app-filevalidation',
  templateUrl: './filevalidation.component.html',
  styleUrls: ['./filevalidation.component.css']
})
export class FilevalidationComponent implements OnInit {

  data: (Select2Group | Select2Option)[];

  FilevalidationInterface: FilevalidationInterface = {
    dropFileName: '',
    filePath: '',
    filename: '',
    messageDropdown: '',
    restdomain: {},
    inputContent: {},
    drpflenme: false,
    finaldrpfilename: '',
    contype: '',
  };

  public configData: any = {};
  public resJson: any;
  fileValData: any = {};
  outputid: HTMLTextAreaElement;
  exceptionid: HTMLTextAreaElement;
  private markers: CodeMirror.TextMarker[] = []; // Array to store the markers

  constructor(private toastr: ToastrService, public router: Router, private commonService: CommonService, private http: HttpClient, private messageService: MessageService) {
    this.configData = this.commonService.UIConfig;
    this.FilevalidationInterface.restdomain = this.configData.RESTServer[0]['RESTDomainName'];
  }

  /*CodeMirror*/
  @ViewChild('codemirror') codeEditor: CodemirrorComponent;
  codeMirrorOptions: any = {
    mode: "text/javascript",
    theme: 'idea',
    indentWithTabs: true,
    smartIndent: true,
    lineNumbers: true,
    lineWrapping: true,
    // extraKeys: { "Ctrl-Space": "autocomplete" },
    gutters: ["CodeMirror-linenumbers", "CodeMirror-foldgutter"],
    autoCloseBrackets: true,
    matchBrackets: true,
    lint: true,
    placeholder: 'Input...',
    extraKeys: {
      "Ctrl-Space": "autocomplete",
      'Ctrl-F': 'findPersistent', // To open the search dialog
      'Ctrl-G': 'findNext', // To find the next occurrence
      'Shift-Ctrl-G': 'findPrev', // To find the previous occurrence
      'Ctrl-H': 'replace', // To open the replace dialog
      'Shift-Ctrl-R': 'replaceAll' // To replace all occurrences
    }
  };
  splitPaneStyle = { width: "100%" };
  cursorPos: { line: number, ch: number } = { line: 0, ch: 0 };
  fileContent: any = '';
  modalData: any = {};


  ngOnInit() {
    this.commonService.getformatJsonData().subscribe(data => {
      this.data = data.sort((a: any, b: any) => a.label.localeCompare(b.label));
      let localvar = this.commonService.getModalData();
      if (localvar) {
        // let myData = this.data.filter((val) => val['value'] == localvar.messagetype)?.[0];
        // this.fileValData.messagetype = this.modalData.messagetype;
        this.fileValData.messagetype = localvar.messagetype;
      }
    });

    this.modalData = this.commonService.getModalData();
    if (this.modalData) {

      // let myData = this.data.filter((val) => val['value'] == this.modalData.messagetype)?.[0];
      // // this.fileValData.messagetype = this.modalData.messagetype;
      // this.fileValData.messagetype = myData;
      this.fileContent = this.modalData.input;

      if (this.FilevalidationInterface.drpflenme == false) {
        this.FilevalidationInterface.dropFileName = this.modalData.inputselected;
      } else {
        this.FilevalidationInterface.filename = this.modalData.inputselected;
      }
      let outputid = document.getElementById('output') as HTMLTextAreaElement;
      if (outputid) {
        outputid.value = this.modalData.outputVal;
      }
      let exceptionid = document.getElementById('exception') as HTMLTextAreaElement;
      if (exceptionid) {
        exceptionid.value = this.modalData.exceptionVal;
      }
    }
  }

  onSearch(query: string, cm: CodemirrorComponent) {
    // console.log(query, cm, cm.value, cm.codeMirror)
    const editor = cm.codeMirror as any;
    // Clear previous markers
    this.clearMarkers();
    const cursor = editor.getSearchCursor(query, null, { caseFold: true });
    // console.log('Editor:', editor);
    // console.log('Cursor:', cursor);
    // console.log('Query:', query);
    // while (cursor.findNext()) {
    //   const from = cursor.from();
    //   const to = cursor.to();
    //   console.log('Marking from:', from, 'to:', to); // Check the marking positions
    //   editor.markText(from, to, { className: 'highlighted-text' });
    // }
    while (cursor.findNext()) {
      const marker = editor.markText(cursor.from(), cursor.to(), { className: 'highlighted-text' });
      this.markers.push(marker); // Store the marker
    }
  }

  clearMarkers() {
    for (const marker of this.markers) {
      marker.clear(); // Clear each marker
    }
    this.markers = []; // Reset the markers array
  }


  update(key: string, event: Select2UpdateEvent<any>) {
    key = event.value;
  }

  readFile(fileList: any) {
    let file = fileList.target.files[0];
    this.FilevalidationInterface.dropFileName = '';
    this.FilevalidationInterface.filename = file?.name;
    let fileReader: FileReader = new FileReader();
    let self = this;
    fileReader.onloadend = function (x) {
      self.fileContent = fileReader.result;
    };
    fileReader.readAsText(file);
  }

  /* onDragStart(event: DragEvent, fileReader: string) {
     // console.log(event, filename)
     event.dataTransfer?.setData('text', fileReader);
     // console.log("event----", event)
   }*/

  setEditorContent(event: any) {
    if (event == '') {
      this.FilevalidationInterface.filename = ''
      this.FilevalidationInterface.dropFileName = ''
      this.fileValData.inputselected = ''
    }
    this.FilevalidationInterface.inputContent = event;
    if (this.FilevalidationInterface.drpflenme == true) {
      this.inputDownload(this.FilevalidationInterface.inputContent, this.FilevalidationInterface.dropFileName, true)
    }
  }

  isValidFile(fileContent: any): boolean {
    if (fileContent) {
      let allowedExtensions = ['xml', 'txt', 'dat'];
      let fileExtension = this.FilevalidationInterface.dropFileName.split('.').pop().toLowerCase() || this.FilevalidationInterface.filename.split('.').pop().toLowerCase();

      if (allowedExtensions.includes(fileExtension)) {
        return true;
      } else {
        this.FilevalidationInterface.filename = '';
        this.FilevalidationInterface.dropFileName = '';
        this.fileValData.inputselected = ''
        return false;
      }
    }
    return false;
  }


  handleFileDropped(fileName: string) {
    this.fileContent = '';
    this.FilevalidationInterface.filename = '';
    this.FilevalidationInterface.dropFileName = fileName;
  }

  cursorMoved() {
    this.cursorPos = (this.codeEditor.codeMirror as any).getCursor();
    let cursorln = (this.cursorPos.line) + 1;
    let cursorCol = this.cursorPos.ch;
    if (cursorln && cursorCol) {
      document.getElementById('lineNo').innerHTML = "Row :" + cursorln;
      document.getElementById('colNo').innerHTML = "Col : " + cursorCol;
    }

  }

  clearFileInput(files: any) {
    // console.log("testing", files)
  }

  executeRunValidation(ValData: any) {
    if (this.FilevalidationInterface.drpflenme == false) {
      this.FilevalidationInterface.finaldrpfilename = this.FilevalidationInterface.dropFileName;
    } else {
      const filePath = ValData.inputselected;
      this.FilevalidationInterface.filename = this.getFileName(filePath);
    }

    /*let outputid = document.getElementById('output') as HTMLInputElement;
    if (outputid) {
      outputid.value = '';
    }
    let exceptionid = document.getElementById('exception') as HTMLInputElement;
    if (exceptionid) {
      exceptionid.value = '';
    }*/

    // this.fileValData = {
    //   messagetype: ValData.messagetype,
    //   input: this.fileContent,
    //   inputselected: this.getFileName(ValData.inputselected)
    // };
    // this.commonService.setModalData(this.fileValData);

    let messageDropdownval = ValData.messagetype;
    if (messageDropdownval == "" || messageDropdownval == undefined) {
      // this.toastr.error("Please select the Format Type");
      this.messageService.add({
        severity: 'error',
        detail: "Please select the Format Type",
      });
    } else if (this.fileContent.trim() == "") {
      // this.toastr.error("Please provide input file and run validation");
      this.messageService.add({
        severity: 'error',
        detail: "Please provide input file and run validation",
      });
    } else {
      this.checkSession();
      let url = "";
      let inputType = "raw";
      let serviceType = "validation";
      let messageType = messageDropdownval;
      if (serviceType == "none" || inputType == "none" || messageType == "none") {
        // this.toastr.error("Make sure all options are selected before sending the request...");
        this.messageService.add({
          severity: 'error',
          detail: "Make sure all options are selected before sending the request..."
        });
      } else {
        if (serviceType == "validation") {
          url = this.commonService.restDomainURL + 'validator/raw?messageType=' + messageType;
          if (inputType == "json") {
            this.FilevalidationInterface.contype = "application/json";
          } else {
            this.FilevalidationInterface.contype = "text/plain";
          }
          const headers = new HttpHeaders({
            'Content-Type': this.FilevalidationInterface.contype,
            'x-filename': this.FilevalidationInterface.filename || this.FilevalidationInterface.finaldrpfilename,
            'Authorization': sessionStorage['tokenType'] + " " + sessionStorage['accessToken']
          });
          this.http.post(url, this.fileContent, { headers }).subscribe({
            next: (response: any) => {
              if (response.status === 401) {
                // this.toastr.error('Not a valid authenticated call. Please login again.');
                this.messageService.add({
                  severity: 'error',
                  detail: "Not a valid authenticated call. Please login again."
                });
                sessionStorage.clear();
                this.router.navigate(['/login']);
                return;
              }
              // if (response.status === 200) {
              this.resJson = response;
              if (this.resJson.success == false) {
                this.outputid = document.getElementById('output') as HTMLTextAreaElement;
                if (this.outputid) {
                  this.outputid.value = this.resJson.errorMsg + ("\n") + (this.resJson.TotalNumberOfTransactionProcessed != undefined ? "Total Number of Transactions Validated: " + this.resJson.TotalNumberOfTransactionProcessed : '') + ("\n") + (this.resJson.NumberOfTransactionsPassed != undefined ? "Number of Transactions Passed: " + this.resJson.NumberOfTransactionsPassed : '') + ("\n") + (this.resJson.NumberOfTransactionsFailed != undefined ? "Number of Transactions Failed: " + this.resJson.NumberOfTransactionsFailed : '');
                }
                // $rootScope.outputVal = document.getElementById("output").value; 
                this.exceptionid = document.getElementById('exception') as HTMLTextAreaElement;
                if (this.exceptionid) {
                  this.exceptionid.value = this.commonService.b64DecodeUnicode(this.resJson?.message)
                }
              } else {
                let outputid = document.getElementById('output') as HTMLTextAreaElement;
                if (outputid) {
                  outputid.value = this.resJson.errorMsg + ("\n") + (this.resJson.TotalNumberOfTransactionProcessed != undefined ? "Total Number of Transactions Validated: " + this.resJson.TotalNumberOfTransactionProcessed : '') + ("\n") + (this.resJson.NumberOfTransactionsPassed != undefined ? "Number of Transactions Passed: " + this.resJson.NumberOfTransactionsPassed : '') + ("\n") + (this.resJson.NumberOfTransactionsFailed != undefined ? "Number of Transactions Failed: " + this.resJson.NumberOfTransactionsFailed : '');
                }
                // $rootScope.outputVal = document.getElementById("output").value;                  
                let exceptionid = document.getElementById('exception') as HTMLTextAreaElement;
                if (exceptionid) {
                  exceptionid.value = this.commonService.b64DecodeUnicode(this.resJson?.message)
                }
                // $rootScope.exceptionVal = document.getElementById("exception").value;                 
              }

              // } else {
              // let outputid = document.getElementById('output') as HTMLInputElement;
              // let exceptionid = document.getElementById('exception') as HTMLInputElement;
              // if (outputid) {
              //   outputid.value = "Formatting Errors Found";
              // }

              this.fileValData = {
                messagetype: ValData.messagetype,
                input: this.fileContent,
                inputselected: this.getFileName(ValData.inputselected),
                outputVal: this.outputid.value,
                exceptionVal: this.exceptionid.value
              }
              this.commonService.setModalData(this.fileValData)
              // }
            },
            error: (err: any) => {
              // this.toastr.error(err.error.message)
              this.messageService.add({
                severity: 'error',
                detail: err.error.message,
              });
            },
          });
        }
      }
    }
  }

  //to download text report & Pdf report.  
  downloadReport(ValData: any, type: any) {
    if (this.FilevalidationInterface.drpflenme == false) {
      this.FilevalidationInterface.finaldrpfilename = this.FilevalidationInterface.dropFileName;
    } else {
      const filePath = ValData.inputselected;
      this.FilevalidationInterface.filename = this.getFileName(filePath);
    }
    let messageDropdownval = ValData.messagetype;
    if (this.fileContent.trim() === "") {
      // this.toastr.error(this.fileContent.trim() == "" ? "Please provide input file and run validation." : "Submit input sample uploaded to download file");
      this.messageService.add({
        severity: 'error',
        detail: this.fileContent.trim() == "" ? "Please provide input file and run validation." : "Submit input sample uploaded to download file"
      });
    } else {
      this.checkSession();
      let url = "";
      let inputType = "raw";
      let messageType = messageDropdownval;

      if (messageType === "none") {
        // this.toastr.error("Make sure all options are selected before sending the request...");
        this.messageService.add({
          severity: 'error',
          detail: "Make sure all options are selected before sending the request..."
        });
      } else {
        if (messageType) {
          url = this.commonService.restDomainURL + 'validator/report?messageType=' + messageType + '&type=' + type;

          if (inputType == "json") {
            this.FilevalidationInterface.contype = "application/json";
          } else {
            this.FilevalidationInterface.contype = "text/plain";
          }
          const headers = new HttpHeaders({
            'Content-Type': this.FilevalidationInterface.contype,
            "x-filename": this.FilevalidationInterface.filename || this.FilevalidationInterface.finaldrpfilename,
            'Authorization': sessionStorage['tokenType'] + " " + sessionStorage['accessToken']
          });

          this.http.post(url, this.fileContent, { headers }).subscribe({
            next: (response: any) => {
              // if (response.status === 401) {
              //   this.toastr.error('Not a valid authenticated call. Please login again.');
              //   sessionStorage.clear();
              //   this.router.navigate(['/login']);
              //   return;
              // }
              if (response.success == true) {
                // this.resJson = JSON.parse(response);
                // if (this.resJson.success == true) {
                if (this.FilevalidationInterface.dropFileName) {
                  this.FilevalidationInterface.filename = '';
                } else {
                  this.FilevalidationInterface.dropFileName = '';
                }
                const fileName = this.FilevalidationInterface.filename ? this.FilevalidationInterface.filename : this.FilevalidationInterface.dropFileName ? this.FilevalidationInterface.dropFileName : "validation-report";

                if (type === 'text') {
                  let finalfileName = fileName.split('.')[0] + ".txt";
                  let data = this.commonService.b64DecodeUnicode(response.message);
                  let blob = new Blob([data], { type: 'text/plain' });
                  const _win = window.navigator as any
                  if (_win && _win.msSaveOrOpenBlob) {
                    _win.msSaveBlob(blob, finalfileName);
                  } else {
                    let elem = window.document.createElement('a');
                    elem.href = window.URL.createObjectURL(blob);
                    elem.download = fileName;
                    elem.style.display = 'none';
                    document.body.appendChild(elem);
                    elem.click();
                    document.body.removeChild(elem);
                  }
                } else if (type === 'pdf') {
                  let finalfileName = fileName.split('.')[0] + ".pdf";
                  this.commonService.downloadSTPguidePdf(finalfileName ? finalfileName : "validation-report", response.message);
                  // this.commonService.downloadPdf(finalfileName);
                } else {
                  // this.toastr.error('Unable to download report.');
                  this.messageService.add({
                    severity: 'error',
                    detail: "Unable to download report."
                  });
                }
                // } else {
                //   this.toastr.error('Unable to download report.');
                // }
              } else {
                // this.toastr.error('Error generating report.');
                this.messageService.add({
                  severity: 'error',
                  detail: 'Error generating report.'
                });
              }
            },
            error: (err: any) => {
              // this.toastr.error(err.error.error.message);
              this.messageService.add({
                severity: 'error',
                detail: err.error.error.message
              });
            },
          });
        }
      }
    }
  }

  getFileName(filePath: string) {
    const index = filePath?.lastIndexOf('\\');
    return filePath?.substring(index + 1);
  }

  checkSession() {
    let token = sessionStorage['accessToken'];
    if (token == null) {
      // this.toastr.error("Login Required");
      this.messageService.add({
        severity: 'error',
        detail: "Login Required"
      });
      this.router.navigate(['/login']);
    }
  }

  inputDownload(filepath: any, filename: any, dropfl: boolean) {
    let filepathvalue;
    if (dropfl == true) {
      filepathvalue = filepath;
      this.FilevalidationInterface.dropFileName = filename;
    } else {
      filepathvalue = this.fileContent;
      this.FilevalidationInterface.filename = this.getFileName(filepath.inputselected);
    }

    if (filepathvalue.trim() == "") {
      // this.toastr.error("Select a sample file");
      this.messageService.add({
        severity: 'error',
        detail: "Select a sample file"
      });
    } else {
      if (this.FilevalidationInterface.dropFileName) {
        this.FilevalidationInterface.filename = '';
      } else {
        this.FilevalidationInterface.dropFileName = '';
      }
      const fileName = this.FilevalidationInterface.filename ? this.FilevalidationInterface.filename : this.FilevalidationInterface.dropFileName ? this.FilevalidationInterface.dropFileName : "validation-report";

      this.commonService.textDownload(filepathvalue, fileName);
    }
  }

  clearOutput() {
    let outputid = document.getElementById('output') as HTMLTextAreaElement;
    if (outputid) {
      outputid.value = '';
    }
    let exceptionid = document.getElementById('exception') as HTMLTextAreaElement;
    if (exceptionid) {
      exceptionid.value = '';
    }
  }

  refreshAll() {
    this.fileValData.messagetype = '';
    this.FilevalidationInterface.filename = '';
    this.FilevalidationInterface.dropFileName = '';
    this.fileContent = '';

    let outputid = document.getElementById('output') as HTMLTextAreaElement;
    if (outputid) {
      outputid.value = '';
    }
    let exceptionid = document.getElementById('exception') as HTMLTextAreaElement;
    if (exceptionid) {
      exceptionid.value = '';
    }
  }
}