<div class="container-fluid p-4 mb-5 pb-100">
    <div class="row  p-3 pt-0 pb-0">
        <div class="col m-0 p-0">
            <div class="row col-md-12 ">
                <div class=" col-md-3 form-group pt-3 pb-3">
                    <label for="sel1" class="col form-label required">Message Format</label>
                    <div>
                        <select2 class="rounded-0" tabindex="0" id="formatDropdown" name="formatDropdown"
                            #formatDropdown aria-label="Format" [placeholder]="'Search Format'"
                            title="Format of the input message" [data]="formatList" [(ngModel)]="validator.format"
                            (ngModelChange)="getVersion(validator.format, 'version')"
                            (update)="update('value2', $event)" id="selec2_msg">
                        </select2>
                    </div>
                </div>




                <div class=" col-md-3 form-group pt-3 pb-3">
                    <label for="sel1" class="col form-label required">Version</label>
                    <div>
                        <select2 class="rounded-0" tabindex="0" id="versionDropdown" name="versionDropdown"
                            #versionDropdown aria-label="Version" [placeholder]="'Search Version'"
                            title="Version of the input message" [data]="versionList" [(ngModel)]="validator.version"
                            (ngModelChange)="getMSGCategory(validator.version,'version')"
                            (update)="updateversion('version', $event)" id="selec2_msg">
                        </select2>
                    </div>
                </div>
                <div class=" col-md-3 form-group pt-3 pb-3">
                    <label for="sel1" class="col form-label ">Category</label>
                    <div>
                        <select2 class="rounded-0" tabindex="0" id="categoryDropdown" name="categoryDropdown"
                            #categoryDropdown aria-label="Category" [placeholder]="'Search Category'"
                            title="Category of the input message" [data]="categoryList" [(ngModel)]="validator.category"
                            (ngModelChange)=" getMSGNames(validator.category,'category');"
                            (update)="update('value2', $event)" id="selec2_msg">
                        </select2>
                    </div>
                </div>
                <div class=" col-md-3 form-group pt-3 pb-3">
                    <label for="sel1" class="col form-label required info">Name </label>
                    <span class="text-primary  mx-2 "><i class="fas fa-info-circle" data-bs-toggle="tooltip"
                            data-bs-placement="left"
                            data-bs-original-title="Select the messasge name from the dropdown list to get more information">
                        </i> </span>
                    <div>
                        <select2 class="rounded-0" tabindex="0" id="nameDropdown" name="nameDropdown" aria-label="Name"
                            #nameDropdown [placeholder]="'Search Name'" title="Name of the input message"
                            [data]="nameList" [(ngModel)]="validator.name"
                            (ngModelChange)="getTargetValidation(validator.name,'name')"
                            (update)="update('value2', $event)" id="selec2_msg">
                        </select2>
                    </div>
                </div>
                <div class="col col-md-3 form-group pt-3 pb-3">
                    <label for="sel1" class="col form-label">Validations</label>
                    <div>
                        <!-- <select2 class="rounded-0" tabindex="0" id="validationDropdown" name="validationDropdown"
                            aria-label="Name" [placeholder]="'Search'" [multiple]="true"
                            title="Validation of the input message" [data]="validationsList"
                            [(ngModel)]="validator.validation" (update)="update('value2', $event)" id="selec2_msg">
                        </select2> -->
                        <!-- (onChange)="update('value2', $event)" -->
                        <!-- <p-dropdown [options]="validationsList" [filter]="true" filterBy="label" [showClear]="true"
                            [(ngModel)]="validator.validation" placeholder="Search"
                            styleClass="full-width-dropdown"></p-dropdown> -->

                        <p-multiSelect styleClass="full-width-dropdown" [options]="validationsList"
                            [(ngModel)]="validator.validation" defaultLabel="Search" optionLabel="label"
                            (onChange)="update('value2', $event)"></p-multiSelect>
                    </div>
                </div>
                <div class=" row clearfix align-self-center">
                    <div class="col col-md-6 col-lg-3 accordion accordion-flush custom-accordian" id="advancedoptions">
                        <div class="accordion accordion-flush" id="accordionFlushExample">
                            <div class="accordion-item">
                                <h2 class="accordion-header" id="flush-headingOne">
                                    <button class=" collapsed px-0 advOpt_text" type="button" data-bs-toggle="collapse"
                                        data-bs-target="#flush-collapseOne" aria-expanded="false"
                                        aria-controls="flush-collapseOne" (click)="changeAngle()">
                                        <!-- <span class="accordion-text">Advanced Options
                                            <span id="advancedOptionStatus" #advancedOptionStatus
                                                style="display: none;">{{ValidatorInterface.isAdvancedCollapsed}}</span>
                                        </span> -->
                                        <h5 class="m-1"><i class="fas fa-angle-right mt-1 font-weight-bold collapse-btn"
                                                id="hideAngle"></i> Advanced Options <span id="advancedOptionStatus"
                                                style="display: none;">{{ValidatorInterface.isAdvancedCollapsed}}</span>
                                        </h5>
                                    </button>
                                </h2>
                                <div id="flush-collapseOne" class="accordion-collapse collapse"
                                    aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                                    <div class="accordion-body p-0">
                                        <div class="col col-md-12 form-group pt-3 pb-3">
                                            <label for="sel1" class="col form-label">Include parsed payload </label>
                                            <div>
                                                <select2 class="rounded-0" tabindex="0" id="includePayloadDropdown"
                                                    name="includePayloadDropdown" aria-label="Format"
                                                    [placeholder]="'Select'" [data]="data"
                                                    [(ngModel)]="validator.includeInput"
                                                    (update)="update('value2', $event)" id="selec2_msg">
                                                </select2>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class=" form-group pt-3 pb-3 col-md-6 col-lg-3">
                    <label for="sel1" class="col form-label">Provide File</label>
                    <div class="file-drop-area">
                        <span class="choose-file-button">
                            <i class="fas fa-upload"></i>
                        </span>
                        <span class="mx-3 file-message">Select to add.</span>
                        <input class="file-input" type="file" id="fileid" name="fileid" placeholder="Input" #files
                            accept=".xml,.txt,.DAT,.json" [(ngModel)]="validator.inputselected"
                            (change)="readFile($event)" title="Click to upload the content of a file"
                            (click)="clearFileInput(files)" multiple>
                    </div>
                    <!-- <span title="No file uploaded yet. Only .xml, .txt, and .dat files are allowed." class="navigation"
                        *ngIf="!fileContent">No file uploaded yet. Only .xml, .txt, and .json files are allowed.</span> -->
                    <span title="No file uploaded yet. Only .xml, .txt, and .json files are allowed."
                        *ngIf="!fileContent || (fileContent && !isValidFile(fileContent))">
                        No file uploaded yet. Only .xml, .txt, and .json files are allowed.
                    </span>


                    <div *ngIf="ValidatorInterface.filename">
                        <span class="navigation">
                            <i class="fa fa-trash" style="color: #0052c2;background-color:white; border:none;"
                                aria-hidden="true" onclick="document.getElementById('fileid').click()"></i>
                            {{ValidatorInterface.filename}}
                        </span>
                    </div>
                    <div *ngIf="ValidatorInterface.dropFileName">
                        <span class="navigation">
                            <i class="fa fa-trash" style="color: #0052c2;background-color:white; border:none;"
                                aria-hidden="true" onclick="document.getElementById('fileid').click()"></i>
                            {{ValidatorInterface.dropFileName}}
                        </span>
                    </div>
                </div>
            </div>
            <div class="row clearfix align-self-center mt-2">
                <div class="col-md-12 text-center">
                    <div class="row">
                        <div class="col-md-6 columndivider">
                            <div class="d-flex justify-content-between align-items-center">
                                <div>
                                    <span>OR</span>
                                </div>
                                <div>
                                    <button type="submit" class="h-btn btn btn-primary rounded-0 me-3"
                                        (click)="executeValidator(validator)">Validate</button>
                                    <button type="button" class="h-btn btn btn-secondary rounded-0"
                                        (click)="clearInput()">Clear</button>
                                </div>
                            </div>
                            <label class="form-label  w-100 p-0 text-start">Type or Paste Text Below</label>
                            <textarea class="form-control mt-2" id="input" style="height: 500px;"
                                placeholder="Enter your payload Here" cols="110" [(ngModel)]="fileContent"
                                (ngModelChange)="setEditorContent($event)"></textarea>
                        </div>
                        <div class="col-md-6">
                            <div class="row">
                                <div class="d-flex justify-content-end align-items-center">
                                    <div class="d-flex">
                                        <div>
                                            <button type="button" class="h-btn btn btn-secondary rounded-0"
                                                (click)="clearOutputValidator()">Clear</button>
                                        </div>
                                    </div>
                                </div>
                                <h2 class="response_text">
                                    <label class="px-col-form-label float-center text-center w-100 p-0">Response</label>
                                </h2>

                                <div class="accordion" id="accordionExample">
                                    <div class="accordion-item accordionbd">
                                        <h2 class="accordion-header" id="headingOne">
                                            <button class="accordion-button" type="button"
                                                [disabled]="ValidatorInterface.isDisabled">
                                                Status
                                            </button>
                                        </h2>
                                        <div id="collapseOne" class="accordion-collapse collapse">
                                            <div class="accordion-body">
                                                <div class="toolsPanel">
                                                    <p class="panelButton tooltipColor"
                                                        title="Copy the Response to clipboard"
                                                        (click)="copyText('statusOutput')">
                                                        <i class="fa fa-clone" color="#0052c2" aria-hidden="true"></i>
                                                    </p>
                                                </div>
                                                <pre id="statusOutput"
                                                    class="preAlign">{{ValidatorInterface.statusOutput}}</pre>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="accordion-item accordionbd">
                                        <h2 class="accordion-header" id="headingTwo">
                                            <button class="accordion-button collapsed" type="button"
                                                [disabled]="ValidatorInterface.isDisabled">
                                                Errors <span id="errorNumbers" class="ms-1"></span>
                                            </button>
                                        </h2>
                                        <div id="collapseTwo" class="accordion-collapse collapse">
                                            <div class="accordion-body">
                                                <div class="toolsPanel">
                                                    <p class="panelButton tooltipColor"
                                                        title="Copy the Response to clipboard"
                                                        (click)="copyText('errorsOutput')">
                                                        <i class="fa fa-clone" color="#0052c2" aria-hidden="true"></i>
                                                    </p>
                                                    <p class="panelButton tooltipColor" title="Download the Response"
                                                        (click)="downloadContent('errorsOutput', 'Errors', '.json')">
                                                        <i class="fa fa-download" color="#0052c2"
                                                            aria-hidden="true"></i>
                                                    </p>
                                                </div>
                                                <pre id="errorsOutput"
                                                    class="preAlign">{{ValidatorInterface.errorsOutput}}</pre>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="accordion-item accordionbd">
                                        <h2 class="accordion-header" id="headingThree">
                                            <button class="accordion-button collapsed" type="button"
                                                [disabled]="ValidatorInterface.isDisabled">
                                                Parsed payload
                                            </button>
                                        </h2>
                                        <div id="collapseThree" class="accordion-collapse collapse">
                                            <div class="accordion-body">
                                                <div class="toolsPanel">
                                                    <p class="panelButton tooltipColor"
                                                        title="Copy the Response to clipboard"
                                                        (click)="copyText('parsedPayloadOutput')">
                                                        <i class="fa fa-clone" color="#0052c2" aria-hidden="true"></i>
                                                    </p>
                                                    <p class="panelButton tooltipColor" title="Download the Response"
                                                        (click)="downloadContent('parsedPayloadOutput', 'ParsedPayload', '.json')">
                                                        <i class="fa fa-download" color="#0052c2"
                                                            aria-hidden="true"></i>
                                                    </p>
                                                </div>

                                                <pre id="parsedPayloadOutput"
                                                    class="preAlign">{{ValidatorInterface.parsedPayloadOutput}}</pre>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div id="tooltipAlerts" hidden></div>