<div class="container mb-5 pb-5">
  <div class="row col-md-12 border-bottom">
    <div class="col-md-6">
      <h4 class="mt-3 text-capitalize" tabindex="0">
        {{method}} - {{ modulename }}
      </h4>
    </div>
    <div class="col-md-6 text-end d-flex align-items-center justify-content-end" >
      <span  *ngIf="buttflag" class="text-primary icon_cls"><i class="fas fa-info-circle" data-bs-toggle="tooltip" data-bs-placement="right" title="User will receive an email with a temporary password and be prompted to setup challenge questions">
       </i> </span>   
    <button *ngIf="buttflag" type="button" title="Unlock User" aria-label="Unlock User" tabindex="0"
      role="button" class="btn-h btn btn-secondary rounded-0 mb-2 mr-3 buttclass " (click)="unlockUseraccount()">
       Unlock User
    </button>
    <button
        title="Page Refresh"
        tabindex="0"
        role="button"
        class="btn btn-icon btn-custom btn-sm rounded-0 mb-1" (click)="refresh()">
        <i class="fas fa-redo-alt"></i>
      </button>
    </div>
  </div>
  <div class="form-group mt-2">
    <span  *ngIf="method == 'create'">
    <h6><p>Asterisk items (<span class="text-danger" style="font-size: 1rem;">*</span>) are  required to proceed.</p></h6></span>
    <span  *ngIf="method == 'edit'">
      <h6><p>To edit {{modulename}} details, please select a {{modulename}} from the table
        below</p></h6></span>
</div>
  <!-- Added for the next section -->
  <div class="row col-md-12 m-0 p-0">
    <form
      [formGroup]="dynamicform"
      class="form-floating" autocomplete="off"
      (ngSubmit)="onSubmit()">
  
      <div *ngFor="let fdata of jsonFormData?.clients">
        <div class="col-md-4">
          <div class="form-group pt-3"[ngClass]="{'hidden': (this.method === 'edit'&& fdata.label == 'Role')}">
            <label id="label" class="col form-label" >{{ fdata.label }}</label>
            <sup class="text-danger" *ngIf="fdata.validators['required']"aria-hidden="true">*</sup>
          </div>
        </div>

        <div class="col-md-4">

          <!-- text field starts -->

          <div *ngIf="fdata.type == 'text'">
            <input 
              type="text" id="{{fdata['name']}}" [formControlName]= "fdata['name']" placeholder="Enter {{ fdata['label'] }}"
              class="form-control horizontal-form-fields rounded-0"  [attr.aria-label]= "fdata['label']"
              [class.is-invalid]="myForm[fdata['name']].invalid &&(myForm[fdata['name']].dirty || myForm[fdata['name']].touched)"
              [required]="fdata.validators['required'] ? true : false"/>

            <div class="help-block text-danger"  *ngIf="myForm[fdata['name']].invalid && (myForm[fdata['name']].dirty || myForm[fdata['name']].touched) && myForm[fdata['name']]?.errors?.['required']" id="err1">
              <span><i aria-hidden="true" class="fa fa-minus-circle px-text-error"></i>This value is required ! </span> 
            </div>
          </div>

          <!-- dropdown starts -->

          <div *ngIf="fdata.type == 'dropdown'" role="combobox"  aria-expanded="false" >

            <div *ngIf="fdata.label == 'Client ID'&& modulename == 'users' && method == 'edit'">
              <input  type="text"  [formControlName]= "fdata['name']" class="form-control horizontal-form-fields rounded-0"  [attr.aria-label]= "fdata['label']"
                  placeholder="Choose client ID"  title="ClientId"  >
          </div>
  

            <p-dropdown  id="p-dropdown_msg1" *ngIf="fdata.label == 'Region' && modulename == 'client'" [formControlName]="fdata.name"
            [options]="regionlist" optionLabel="label" placeholder="Choose client region"   aria-labelledby="Region-label"
            styleClass="full-width-dropdown"></p-dropdown>
            
            <p-dropdown  *ngIf="fdata.label == 'Region' && modulename == 'users' && method != 'edit'" [formControlName]="fdata.name"
            [options]="userregionlist"  placeholder="Choose client user region"  
            styleClass="full-width-dropdown"></p-dropdown>


            <div *ngIf="fdata.label == 'Region'&& modulename == 'users' && method == 'edit'">
              <input  type="text"  [formControlName]= "fdata['name']" class="form-control horizontal-form-fields rounded-0"  [attr.aria-label]= "fdata['label']"
              placeholder="Choose client user region"  title="User Region details"  >
            </div>

            <p-dropdown  *ngIf="fdata.label == 'Status'"  [formControlName]="fdata.name"
            [options]="statList"  placeholder="Choose client status"  
            styleClass="full-width-dropdown"></p-dropdown>
        
            <p-dropdown  *ngIf="fdata.label == 'Client ID' && method != 'edit'"  [formControlName]="fdata.name"
            [options]="clientallList"  placeholder="Choose client ID"  
            styleClass="full-width-dropdown"></p-dropdown>

            <p-dropdown   *ngIf="fdata.label != 'Region' && fdata.label != 'Status' && fdata.label != 'Client ID' && fdata.label == 'Role'&& method !='edit'" [formControlName]="fdata.name"
            [options]="createUserObj.roles"  placeholder="Choose role"  
            styleClass="full-width-dropdown"></p-dropdown>

            <div class="help-block text-danger" *ngIf="myForm[fdata['name']].invalid && (myForm[fdata['name']].dirty && myForm[fdata['name']].touched)" id="err2" aria-live="assertive">
                <span> <i aria-hidden="true" class="fa fa-minus-circle px-text-error"></i> This value is required ! </span> 
            </div>

      

          </div>

          <!-- textarea starts -->

          <div *ngIf="fdata.type == 'textarea'">
            <textarea
              class="form-control rounded-0"
              [formControlName]="fdata.name" 
              [attr.aria-label] = "fdata['label']"
              [required]="fdata.validators['required'] ? true : false"
              [class.is-invalid]="myForm[fdata['name']]?.errors?.['required'] && (myForm[fdata['name']].dirty || myForm[fdata['name']].touched)"
              placeholder="Enter {{ fdata['label'] }}"
              id="{{fdata['name']}}" cols="150"
              rows="4"></textarea>

              <div class="help-block text-danger"  *ngIf="myForm[fdata['name']].invalid && (myForm[fdata['name']].dirty || myForm[fdata['name']].touched) && myForm[fdata['name']]?.errors?.['required']" id="err3">
              <span> <i aria-hidden="true" class="fa fa-minus-circle px-text-error"></i> This value is required ! </span> 
          </div>
          </div>
        </div>
      </div>

      <div class="form-group mt-2">
        <span *ngIf="method == 'edit'">
        <h6><p>Asterisk items (<span class="text-danger" style="font-size: 1rem;">*</span>) are  required to proceed.</p></h6></span></div>

      <hr />

      <!-- button group starts -->
      <div class="input-group-btn z-0">
        <button
          class="btn-h btn btn-primary rounded-0"
          type="submit" [disabled]="!dynamicform.valid">
          Submit
        </button>

        <button
          class="btn-h btn btn-secondary rounded-0 mx-3 "
          type="button"
          (click)="cancel()">
          Cancel
        </button>

      </div>
      <!-- button group ends -->
    </form>
  </div>
</div>
