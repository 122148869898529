import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { BehaviorSubject, finalize, Observable, Subject, tap } from 'rxjs';
import { Router } from '@angular/router';
import { FileSaverService } from 'ngx-filesaver';

declare var bootstrap: any;

@Injectable({
  providedIn: 'root',
})
export class CommonService {
  // static getApplicationName() {
  //   throw new Error('Method not implemented.');
  // }
  isLoading = false;
  public UIConfig: any;
  public restDomainURL: any;
  public vapiDomainURL: any;
  private userLoggedIn = new Subject<boolean>();
  public switchRoleUpdate = new EventEmitter();
  public menuItemforGuard = new EventEmitter();
  private modalData: any;

  private formatjsonUrl = './assets/config/format.json';
  private includepayloadjsonUrl = './assets/config/includepayload.json';

  constructor(
    private http: HttpClient,
    public router: Router,
    private _FileSaverService: FileSaverService
  ) {
    this.userLoggedIn.next(false);
  }

  ngOnInit(): void {
  }

  fetchUIConfig() {
    this.isLoading = true;
    return this.http.get('./assets/config/config.json').pipe(
      tap((uiconf) => {
        this.UIConfig = uiconf;
        // let config = this.UIConfig;
        // console.log('config-----', this.UIConfig);
        let diffRestServer =
          (this.UIConfig.RESTServer, 'UIDomainName', location.hostname);
        // console.log("diffRestServer---", diffRestServer)
        if (this.UIConfig.IsRESTServerInSameMachine != 'Yes') {
          this.restDomainURL =
            this.UIConfig.RESTServer[0]['Protocol'] +
            '://' +
            this.UIConfig.RESTServer[0]['RESTDomainName'] +
            ':' +
            this.UIConfig.RESTServer[0]['Port'] +
            '/' +
            this.UIConfig['RESTWebApp'] +
            this.UIConfig['RestUrl'];

          this.vapiDomainURL =
            this.UIConfig.RESTServer[0]['Protocol'] +
            '://' +
            this.UIConfig.RESTServer[0]['RESTDomainName'] +
            ':' +
            this.UIConfig.RESTServer[0]['Port'] +
            '/' +
            this.UIConfig['vapiRESTWebApp'] +
            this.UIConfig['vapiRestUrl'];
          // console.log('this.restDomainURL---ifff', this.restDomainURL, this.vapiDomainURL);
        } else {
          let location = window.location;
          this.restDomainURL = `${location['protocol']}//${location['hostname']}:${location['port']}/${this.UIConfig['RESTWebApp']}${this.UIConfig['RestUrl']}`;

          this.vapiDomainURL = `${location['protocol']}//${location['hostname']}:${location['port']}/${this.UIConfig['vapiRESTWebApp']}${this.UIConfig['vapiRestUrl']}`;
          // console.log('this.restDomainURL---else', this.restDomainURL, this.vapiDomainURL);
        }
      }),
      finalize(() => {
        this.isLoading = false;
      })
    );
  }

  /* This functionality is used for triggering main content focus while navigation */
  public handleMainFocus = (event: any) => {
    if (event.code == 'Tab') {
      document.getElementById('vp-main')?.removeAttribute('tabindex');
      document
        .getElementById('vp-main')
        ?.removeEventListener('keydown', this.handleMainFocus);
    }
  };

  /* This functionality is used for triggering main content focus while navigation */
  public triggerMainFocus = () => {
    if (document.getElementById('vp-main')) {
      document.getElementById('vp-main')?.setAttribute('tabindex', '0');
      document.getElementById('vp-main')?.focus();
      document
        .getElementById('vp-main')
        ?.addEventListener('keydown', this.handleMainFocus);
    }
  };

  setUserLoggedIn(userLoggedIn: boolean) {
    this.userLoggedIn.next(userLoggedIn);
  }

  getUserLoggedIn(): Observable<boolean> {
    return this.userLoggedIn.asObservable();
  }

  b64DecodeUnicode(str: any) {
    return decodeURIComponent(
      atob(str)
        .split('')
        .map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join('')
    );
  }

  downloadPdf(filename: any) {
    // const text = document.getElementById(idname || "pdf-report").value;
    var element = document.createElement('a');
    element.setAttribute('href', 'data:application/octet-stream;base64,');
    element.setAttribute('download', filename);

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();
    document.body.removeChild(element);
  }

  downloadSTPguidePdf(filename: string, content: any) {
    var element = document.createElement('a');
    element.setAttribute(
      'href',
      'data:application/octet-stream;base64,' + encodeURIComponent(content)
    );
    element.setAttribute('download', filename);
    element.style.display = 'none';
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  }

  public textDownload = (content: any, name: any) => {
    const fileName = name;
    const fileType = this._FileSaverService.genType(fileName);
    const txtBlob = new Blob([content], {
      type: fileType,
    });
    this._FileSaverService.save(txtBlob, fileName);
  };

  getformatJsonData(): Observable<any> {
    return this.http.get<any>(this.formatjsonUrl);
  }

  getincludeJsonData(): Observable<any> {
    return this.http.get<any>(this.includepayloadjsonUrl);
  }

  b64EncodeUnicode(str: string): string {
    return btoa(
      encodeURIComponent(str).replace(
        /%([0-9A-F]{2})/g,
        function toSolidBytes(match, p1) {
          return String.fromCharCode(parseInt(p1, 16));
        }
      )
    );
  }

  public settingUpTooltip = () => {
    setTimeout(() => {
      const exampleTriggerEl = document.querySelectorAll(
        '[data-bs-toggle="tooltip"]'
      );
      if (exampleTriggerEl?.length) {
        exampleTriggerEl.forEach((ele) => {
          let tooltip = bootstrap.Tooltip.getOrCreateInstance(ele);
        });
      }
    }, 100);
  };

  copyToClipboard(text: string): void {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        // console.log('Copied to clipboard successfully!');
      })
      .catch((err) => {
        // console.error('Could not copy text: ', err);
      });
  }

  getSamlLoginResponse(cookieVal) {
    let dataSuccess = cookieVal;
    sessionStorage['accessToken'] = dataSuccess.accessToken;
    sessionStorage['tokenType'] = dataSuccess.tokenType;
    sessionStorage['username'] = dataSuccess.name;
    sessionStorage['admin'] = dataSuccess.Admin;
    sessionStorage['isAuthorized'] = dataSuccess.IsAuthorized;
    sessionStorage['version'] = dataSuccess.Version ? dataSuccess.Version : '';
    this.defaultPage(JSON.parse(atob(sessionStorage['isAuthorized'])).landingpage);
  }

  defaultPage(routepage) {
    if (sessionStorage['isAuthorized'] && !JSON.parse(atob(sessionStorage['isAuthorized'])).dashboard) {
      // this.router.navigate(JSON.parse(atob(sessionStorage['isAuthorized'])).landingpage);     

      if (routepage == 'createclient') {
        this.router.navigate(['app/' + 'client/create']);
        sessionStorage.setItem('landingpage', 'client/create');
      } else {
        this.router.navigate(['app/' + routepage]);
      }
      // this.router.navigate(['app/' + routepage]);
    } else {
      // this.router.navigate(JSON.parse(atob(sessionStorage['isAuthorized'])).landingpage);

      this.router.navigate(['app/' + routepage]);
    }
  }

  getSwitchRoleResponse(finalVal) {
    sessionStorage.clear();
    this.deleteAllCookies();
    var dataSuccess = finalVal;
    sessionStorage['accessToken'] = dataSuccess.accessToken;
    sessionStorage['tokenType'] = dataSuccess.tokenType;
    sessionStorage['username'] = dataSuccess.name;
    sessionStorage['admin'] = dataSuccess.Admin;
    sessionStorage['isAuthorized'] = dataSuccess.IsAuthorized;
    sessionStorage['version'] = dataSuccess.Version ? dataSuccess.Version : "";
    this.switchRoleUpdate.emit(JSON.parse(atob(sessionStorage['isAuthorized'])));
    this.defaultPage(JSON.parse(atob(sessionStorage['isAuthorized'])).landingpage);
  }

  setModalData(data: any): void {
    this.modalData = data;
  }

  getModalData(): any {
    return this.modalData;
  }

  getMappedData(dataArr: any, labelKey: string, valueKey: string): any {
    return dataArr.map((item) => ({
      label: item[labelKey],
      value: item[valueKey],
    }));
  }
  /**
   * @description if user press tab for ADA focus the element.
   * @param focusTime - time to focus.
   * @param elementId - element Id.
   *
   */
  public FocusElementForADA = (elementId, focusTime) => {
    setTimeout(() => {
      if (document.getElementById(elementId)) {
        document.getElementById(elementId).focus();
      }
    }, focusTime);
  };

  deleteAllCookies(): void {
    const cookies = document.cookie.split(';');
    for (let cookie of cookies) {
      const eqPos = cookie.indexOf('=');
      const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      document.cookie = name + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    }
  }
}
