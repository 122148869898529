import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-keep-alive-dialog',
  templateUrl: './keep-alive-dialog.component.html',
  styleUrls: ['./keep-alive-dialog.component.css'],
})
export class KeepAliveDialogComponent {
  @Input() countdown: number = 0;
  @Output() onStayLoggedIn = new EventEmitter<void>();

  stayLoggedIn() {
    this.onStayLoggedIn.emit();
  }
}
