import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CommonService } from '../common/common.service';


@Injectable({
  providedIn: 'root'
})

export class AuthguardService {

  router: any;
  newUrl: string;
  constructor(private route: Router, private commonservice: CommonService) {
    this.router = route;
  }
  loggedIn() {
    return !!sessionStorage.getItem('username');
  }
}



