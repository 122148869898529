import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import {
  FormControl,
  FormGroup,
  Validators,
  FormsModule,
  ReactiveFormsModule,
  FormBuilder,
  NgForm,
} from '@angular/forms';
import RestURL from 'src/assets/config/restURLConstant.json';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { AuthService } from 'src/app/services/auth/auth.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import {
  LoginInterface,
  changepwdInterface,
  sequrityQusInterface,
} from 'src/app/interfaces/login-interface';
import { ModalService } from 'src/app/services/common/modal.service';
import ConfigData from 'src/assets/config/config.json';
import { CommonService } from 'src/app/services/common/common.service';
import { ToastService } from 'src/app/services/toast/toast.service';
import { ToastModule } from 'primeng/toast';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, AfterViewInit {
  @ViewChild('closeButton') closeButton!: ElementRef;
  reactiveForm: FormGroup;
  resetNewpwdForm: FormGroup;
  form: LoginInterface = {
    controls: '',
    userId: '',
    password: '',
    user: '',
    newPassword: '',
    confirmPassword: '',
  };
  userForm: changepwdInterface = {
    username: '',
    email: '',
  };
  sequrityQusInterface: sequrityQusInterface = {
    qrCode: '',
    isMFAEnabled: '',
    items: [],
    finalData: [],
    finalQnChaSet: '',
    optVal: '',
    otpVal: '',
    finalAnswer: '',
    myData: [],
    finalqusansdata: {},
  };
  public loginSwitchCase: any;
  public role: any;
  landPath: any;
  constructor(
    private http: HttpClient,
    private authService: AuthService,
    public router: Router,
    private toastr: ToastrService,
    public modalService: ModalService,
    private commonService: CommonService,
    public toastService: ToastService,
    private messageService: MessageService
  ) {
    this.loginSwitchCase = ['Login', 'resetNewpwd', 'signInAssistance'];
  }
  // ngAfterViewInit(): void {
  //   throw new Error('Method not implemented.');
  // }

  ngOnInit() {

    document.getElementById('skipNav').style.display = 'none';
    this.role = 'Login';
    this.reactiveForm = new FormGroup({
      userId: new FormControl(this.form.userId, [Validators.required]),
      password: new FormControl(this.form.password, [Validators.required]),
    });

    this.resetNewpwdForm = new FormGroup({
      newPassword: new FormControl('', [Validators.required]),
      confirmPassword: new FormControl('', [Validators.required]),
    });
    this.checkRegistration();
  }

  ngAfterViewInit() {
    // Assuming the modal is displayed immediately after login,
    // you can set the focus on the close button.
    setTimeout(() => this.setFocusOnCloseButton(), 0);
  }

  setFocusOnCloseButton() {
    if (this.closeButton) {
      this.closeButton.nativeElement.focus();
    }
  }

  get userId() {
    return this.reactiveForm.get('userId')!;
  }

  get password() {
    return this.reactiveForm.get('password')!;
  }

  get newPassword() {
    return this.resetNewpwdForm.get('newPassword')!;
  }

  get confirmPassword() {
    return this.resetNewpwdForm.get('confirmPassword')!;
  }

  public checkRegistration = () => {
    this.authService.userChkRegister().subscribe({
      next: (response: any) => {
        let dataSuccess = response;
      },
      error: (err) => {
        // this.toastr.error(err?.error?.error?.message);
      },
    });
  };

  onlogin() {
    sessionStorage.clear();
    if (this.reactiveForm.invalid) {
      for (const control of Object.keys(this.reactiveForm.controls)) {
        this.reactiveForm.controls[control].markAsTouched();
      }
      return;
    }
    document.getElementById('skipNav').style.display = 'block';
    this.form = this.reactiveForm.value;
    this.form.user = this.form.userId;
    let loginData = {
      userId: this.form.userId,
      password: this.form.password,
    };

    let encoded_loginObj = btoa(JSON.stringify(loginData));
    this.authService.login(encoded_loginObj).subscribe({
      next: (response: any) => {
        let dataSuccess = response;
        this.authService.loginResp = response;
        sessionStorage.setItem('accessToken', dataSuccess.accessToken);
        sessionStorage.setItem('expirationDate', dataSuccess.expirationDate);
        sessionStorage.setItem('tokenType', dataSuccess.tokenType);
        sessionStorage.setItem('username', this.form.user);
        sessionStorage.setItem('admin', dataSuccess.Admin);
        sessionStorage.setItem('isAuthorized', dataSuccess.IsAuthorized);
        sessionStorage.setItem('version', dataSuccess.Version);
        sessionStorage.setItem(
          'isSecQnAnsAuthReq',
          dataSuccess.isSecQnAnsAuthReq
        );
        sessionStorage.setItem('isSecQnEnabled', dataSuccess.isSecQnEnabled);

        let date = new Date();
        date.setMinutes(
          date.getMinutes() + ConfigData.AppConfig.tokenRefreshTime
        );
        sessionStorage['RefreshDatetime'] = date.getTime();

        sessionStorage['currentUser'] = {
          username: loginData.userId,
          token: dataSuccess.accessToken,
        };

        this.authService.startRefreshToken();
        // add jwt token to auth header for all requests made by the $http service

        // $http.defaults.headers.common.Authorization = dataSuccess.tokenType + ' ' + dataSuccess.accessToken;

        if (dataSuccess.IsMFARequired) {
          if (dataSuccess.QRCode) {
            sessionStorage['qrCode'] = dataSuccess.QRCode.split('base64,')[1];
            //sessionStorage.isMFAEnabled = dataSuccess.IsMFAEnabled;
            this.sequrityQusInterface.qrCode =
              sessionStorage.getItem('qrCode') || '';
          }

          this.sequrityQusInterface.isMFAEnabled =
            sessionStorage.getItem('isMFAEnabled') || '';
          if (dataSuccess.IsMFAEnabled) {
            this.showMFAForm(
              'templates/volpay/modal/verifyMfaCode.html',
              this.commonService.restDomainURL + RestURL.USER_LOGIN
            );
          } else {
            this.showMFAForm(
              'templates/volpay/modal/enableMFA.html',
              this.commonService.restDomainURL + RestURL.ENABLE_MFA
            );
          }
        } else if (dataSuccess.isSecQnAnsAuthReq) {
          if (dataSuccess.isSecQnAnsAuthReq && !dataSuccess.isSecQnEnabled) {
            this.router.navigate(['accountunlock']);
          } else if (
            sessionStorage['isSecQnAnsAuthReq'] &&
            sessionStorage['isSecQnEnabled']
          ) {

            setTimeout(() => {
              this.modalService.open('security_ques');
              setTimeout(() => this.setFocusOnCloseButton(), 0);
            }, 100);

            this.sequrityQusInterface.items = [1];
            this.sequrityQusInterface.finalData = [];

            this.authService.getChallengeQus().subscribe({
              next: (response: any) => {
                let itemQnChaSet;
                itemQnChaSet = response;

                for (
                  let i = 0;
                  i < this.sequrityQusInterface.items.length;
                  i++
                ) {
                  for (i = 0; i < itemQnChaSet.challengeQuestions.length; i++) {
                    this.sequrityQusInterface.finalQnChaSet =
                      itemQnChaSet.challengeQuestions[i].question;
                    itemQnChaSet.challengeQuestions[i]['selVal'] = {
                      answer: '',
                    };
                  }

                  let obj = {
                    selVal: {
                      question: '',
                      answer: '',
                    },
                    finalQnSet: itemQnChaSet.challengeQuestions,
                  };
                  this.sequrityQusInterface.finalData.push(obj);
                }
              },
              error: (err) => {
                // this.toastr.error(err?.error?.error?.message);
                this.toastService.add({
                  type: 'error',
                  message: err?.error?.error?.message,
                });
              },
            });
          } else {
            // this.toastr.success(dataSuccess.message);
            this.messageService.add({
              severity: 'success',
              detail: dataSuccess.message,
            });
            this.router.navigate(['app/dashboard']);
          }
        } else {
          // this.toastr.success(
          //   dataSuccess.message || 'You are successfully signed in.'
          // );
          this.messageService.add({
            severity: 'success',
            detail: dataSuccess.message || 'You are successfully signed in.',
          });


          let authMenus =
            this.authService.loginResp?.IsAuthorized ||
            sessionStorage.getItem('isAuthorized');
          let landingpage = JSON.parse(atob(authMenus))['landingpage'];
          if (landingpage == 'createclient') {
            this.router.navigate(['app/' + 'client/create']);
            sessionStorage.setItem('landingpage', 'client/create');
          } else {
            this.router.navigate(['app/' + landingpage]);
            sessionStorage.setItem('landingpage', landingpage);
          }
        }

      },
      error: (err) => {
        this.toastService.add({
          type: 'error',
          message: err?.error?.error?.message,
        });
        // this.messageService.add({
        //   severity: 'error',
        //   detail: err?.error?.error?.message,
        // });
        // document.getElementById('toast').focus();     
        if (document.getElementById('toast')) {
          setTimeout(() => {
            document.getElementById('closeButton').focus();
          }, 0);

        }
        // this.toastr.error(err?.error?.error?.message), {
        //   closeButton: true
        // };
        sessionStorage.clear();
      },
    });
  }

  showMFAForm(templUrl: string, rstUrl: string) { }

  signInclick() {
    // this.ReadMore = !this.ReadMore; //not equal to condition
    // this.visible = !this.visible;
    this.role = 'signInAssistance';
    setTimeout(() => {
      document.getElementById('username').focus();
    }, 1000);
  }

  backtologin() {
    this.router.navigate(['/login']);
    setTimeout(() => {
      document.getElementById('userId').focus();
    }, 1000);
  }

  public onChangePwdSubmit(ngform: NgForm): void {
    // if (ngform.invalid) {
    //   for (const control of Object.keys(ngform.controls)) {
    //     ngform.controls[control].markAsTouched();
    //   }
    //   return;
    // }
    setTimeout(() => {
      // document.getElementById('securityToken').focus();
    }, 1000);

    let inputData = {
      username: this.userForm.username,
      email: this.userForm.email,
      origin: location.origin,
    };

    let encoded_inputDataObj = btoa(JSON.stringify(inputData));
    this.authService.changePwdWthOTP(encoded_inputDataObj).subscribe({
      next: (response: any) => {
        let dataSuccess = response;
        // this.toastr.success(dataSuccess.message);
        this.messageService.add({
          severity: 'success',
          detail: dataSuccess.message,
        });
        sessionStorage.setItem('forgotUserName', this.userForm.username);
        this.router.navigate(['verifyOTP']);
      },
      error: (err) => {
        // this.toastr.error(err?.error?.error?.message);
        setTimeout(() => {
          this.toastService.add({
            type: 'error',
            message: err?.error?.error?.message ? err?.error?.error?.message : "Username or Email are not valid"
          });
        }, 10);

        // document.getElementById('toast').focus();     
        if (document.getElementById('toast')) {
          setTimeout(() => {
            document.getElementById('closeButton').focus();
          }, 0);

        }
        // this.toastr.error(err?.error?.error?.message), {
        //   closeButton: true
        // };      
        // sessionStorage.clear();
      },
    });
  }

  /* Unlock user account submit*/
  accunlockSubmit(input: any) {
    // let items = [1, 2, 3, 4, 5, 6];
    // let myData: any[];
    // let slctdVal: any[];
    // let changingQnset = [];
    // let updateslctdVal = ['', '', '', '', '', ''];
    setTimeout(() => {
      document.getElementById('secuclose').focus();
    }, 1000);

    let unlockUserObj = JSON.stringify(input);
    this.authService.userChangePwdreset(unlockUserObj).subscribe({
      next: (response: any) => {
        let QnsSet = response;
        let changingQnset = QnsSet;
        sessionStorage.setItem('forgotUserName', this.userForm.username);

        let preQnsSet = response;
        /* for (let i = 0; i < preQnsSet.challengeQuestions.length; i++) {
         slctdVal = preQnsSet.challengeQuestions[i].questionId;
       }

        for (let i = 0; i < preQnsSet.challengeQuestions.length; i++) {
         changingQnset = QnsSet['challengeQuestions'].filter((val: { questionId: any }) => !slctdVal.includes(val.questionId));
         let obj = {
           selVal: {
             Qn: preQnsSet.challengeQuestions[i].questionId,
             Ans: preQnsSet.challengeQuestions[i].answer,
           },
           itemQnSet: changingQnset,
         };
         let drpdwnSel = QnsSet['challengeQuestions'].filter(
           (val: { questionId: any }) => val.questionId === obj.selVal.Qn
         )?.[0];

         obj.itemQnSet.push(drpdwnSel);
         myData.push(obj);
         console.log("myData---", myData)
       }*/

        for (let i = 0; i < preQnsSet.challengeQuestions.length; i++) {
          // let changingQnset = preQnsSet.challengeQuestions.filter((val: { questionId: any }) => !slctdVal.includes(val.questionId));
          preQnsSet.challengeQuestions['itemQnSet'] =
            preQnsSet.challengeQuestions[i];
          let obj = {
            selVal: {
              Qn: preQnsSet.challengeQuestions[i].questionId,
              Ans: preQnsSet.challengeQuestions[i].answer,
            },
            itemQnSet: [preQnsSet.challengeQuestions['itemQnSet']],
          };
          // let drpdwnSel = preQnsSet.challengeQuestions.filter((val: { questionId: any }) => val.questionId === obj.selVal.Qn)?.[0];
          // obj.itemQnSet.push(drpdwnSel);
          this.sequrityQusInterface.myData.push(obj);
        }

        this.modalService.open('security_resetques');
        // $('#security_resetques').modal('show');
      },
      error: (err: any) => {
        // this.toastr.error(err?.error?.error?.message);
        // this.modalService.open('security_resetques');
        setTimeout(() => {
          this.toastService.add({
            type: 'error',
            message: err?.error?.error?.message ? err?.error?.error?.message : "Username or Email are not valid"
          });
        }, 10);


        if (document.getElementById('toast')) {
          setTimeout(() => {
            document.getElementById('closeButton').focus();
          }, 0);

        }

      },
    });
  }

  //Final Security challenge question submission
  formQuAnsSubmit(otpDts: any) {
    let finalChallQuAns = [];
    for (let i = 0; i < this.sequrityQusInterface.finalData.length; i++) {
      for (
        let j = 0;
        j < this.sequrityQusInterface.finalData[i].finalQnSet.length;
        j++
      ) {
        finalChallQuAns.push({
          question:
            this.sequrityQusInterface.finalData[i].finalQnSet[j]['question'],
          answer:
            this.sequrityQusInterface.finalData[i].finalQnSet[j].selVal[
            'answer'
            ],
        });
      }
    }
    this.sequrityQusInterface.optVal = otpDts;
    let data = {
      quAns: finalChallQuAns,
      secretCode: this.sequrityQusInterface.optVal,
    };
    this.authService.finalChallQus(data).subscribe({
      next: (response: any) => {
        let dataSuccess = response;

        sessionStorage.removeItem('isSecQnAnsAuthReq');
        sessionStorage.removeItem('isSecQnEnabled');

        this.modalService.close();
        this.router.navigate(['app/dashboard']);
        setTimeout(() => {
          (document.querySelector('.nav-link.active') as HTMLElement)?.focus();
          (document.getElementsByClassName('nav-link active')[0] as HTMLElement)?.focus();
        }, 1000);
        // this.toastr.success(dataSuccess.message);
        this.messageService.add({
          severity: 'success',
          detail: dataSuccess.message,
        });
      },
      error: (err) => {
        // this.toastr.error(err?.error?.error?.message);
        this.toastService.add({
          type: 'error',
          message: err?.error?.error?.message,
        });
      },
    });
  }

  //Final reset assistance Security challenge question submission
  finalAssistQuAnsSubmit() {
    let qusAnsFinal = [];
    for (let i = 0; i < this.sequrityQusInterface.myData.length; i++) {
      for (
        let j = 0;
        j < this.sequrityQusInterface.myData[i].itemQnSet.length;
        j++
      ) {
        if (
          this.sequrityQusInterface.myData[i].selVal['Qn'] ==
          this.sequrityQusInterface.myData[i].itemQnSet[j]['questionId']
        ) {
          qusAnsFinal.push({
            question:
              this.sequrityQusInterface.myData[i].itemQnSet[j]['question'],
            answer: this.sequrityQusInterface.myData[i].selVal['Ans'],
          });
        }
      }
      this.sequrityQusInterface.finalqusansdata = {
        quAns: qusAnsFinal,
      };
    }
    let getUsername = sessionStorage.getItem('forgotUserName');
    this.authService
      .finalResetChallQus(
        getUsername,
        this.sequrityQusInterface.finalqusansdata
      )
      .subscribe({
        next: (response: any) => {
          let dataSuccess = response;
          this.role = 'resetNewpwd';
          this.router.navigate(['./']);

          // this.toastr.success(dataSuccess.message);
          this.messageService.add({
            severity: 'success',
            detail: dataSuccess.message,
          });
          this.modalService.close();
        },
        error: (err) => {
          // this.toastr.error(err?.error?.error?.message);
          this.toastService.add({
            type: 'error',
            message: err?.error?.error?.message,
          });
        },
      });
  }

  challQuesCancel() {
    this.router.navigate(['/login']);
    setTimeout(() => {
      document.getElementById('userId').focus();
    }, 1000);
  }

  /* final set new password for unlock account user */
  setNewPwdsubmit() {
    if (this.resetNewpwdForm.invalid) {
      for (const control of Object.keys(this.resetNewpwdForm.controls)) {
        this.resetNewpwdForm.controls[control].markAsTouched();
      }
      return;
    }
    this.form = this.resetNewpwdForm.value;
    if (this.form.newPassword != this.form.confirmPassword) {
      // this.toastr.error('New Password and Confirm Password does not match.');
      this.toastService.add({
        type: 'error',
        message: 'New Password and Confirm Password does not match.',
      });
      return;
    }
    let resetNewpwdData = {
      username: sessionStorage.getItem('forgotUserName'),
      newPassword: this.form.newPassword,
    };

    let encoded_resetNewpwdData = btoa(JSON.stringify(resetNewpwdData));
    this.authService.userResetChangePwd(encoded_resetNewpwdData).subscribe({
      next: (response: any) => {
        let dataSuccess = response;
        this.toastService.add({
          type: 'success',
          message: dataSuccess.message,
        });
        this.router.navigate(['./']);
      },
      error: (err) => {
        this.toastService.add({
          type: 'error',
          message: err?.error?.error?.message,
        });
        // this.toastr.error(err?.error?.error?.message);
      },
    });
  }

  get formControls() {
    return this.form['controls'];
    // return this.loginForm['controls'] as { [key: string]: FormControl };
  }

  ssoLogin() {
    this.authService.getSSOLogin().subscribe({
      next: (response: any) => {
        // console.log("sso", response);
        let dataSuccess = response;
        let requestUrl = dataSuccess.RequestUrl;
        let paramsKey = dataSuccess.queryParams[0].key;
        let paramsVal = dataSuccess.queryParams[0].value;
        let samlRequest = requestUrl + '?' + paramsKey + '=' + paramsVal;
        window.location.href = samlRequest;
      },
      error: (err) => {
        // this.toastr.error(err.error.message);
        this.toastService.add({
          type: 'error',
          message: err.error.error.message,
        });
      },
    });
  }

  // enterfunctionforADA = (evt, id) => {
  //   if (evt.key == "Tab") {
  //     this.commonService.FocusElementForADA(id, 0)
  //   }
  // }

  public setTabIndex_ADA = (evt, id, from) => {
    // && evt?.['shiftKey']
    if (evt?.['key'] == 'Tab') {
      evt.preventDefault();
      this.commonService.FocusElementForADA(id, 100);
    } else if (from) {
      evt.preventDefault();
      // this.utils.FocusElementForADA(id, 0)
      if (document.getElementById(id)) {
        document.getElementById(id).focus();
      }
    }
  };
}
