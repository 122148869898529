import { Injectable } from '@angular/core';
import { CommonService } from '../common/common.service';
import RestURL from 'src/assets/config/restURLConstant.json';
import { HttpClient, HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class DashboardService {
  constructor(private http: HttpClient, private commonService: CommonService) {}

  getDashboardDetails(filterVal: string) {
    let dashboardDetailsURL =
      this.commonService.restDomainURL + RestURL.DASHBOARD_DETAILS;
    return this.http.get(dashboardDetailsURL, {
      params: new HttpParams().set('input', filterVal),
    });
  }

  fetchDashboardDetails(payload: any, filterVal: any) {
    let urlparam = '';
    if (filterVal['input'] && filterVal['status']) {
      urlparam =
        '/req?input=' + filterVal['input'] + '&status=' + filterVal['status'];
    } else if (filterVal['input'] && !filterVal['status']) {
      urlparam = '/req?input=' + filterVal['input'];
    } else if (!filterVal['input'] && filterVal['status']) {
      urlparam = '/req?status=' + filterVal['status'];
    }
    let dashboardDetailsURL =
      this.commonService.restDomainURL + RestURL.DASHBOARD_DETAILS + urlparam;
    return this.http.post(dashboardDetailsURL, payload);
  }

  getDashboardDetailsByKey(payload: any) {
    let dashboardDetailsByKeyURL =
      this.commonService.restDomainURL + RestURL.FIND_KEY_DASHBOARD;
    return this.http.post(dashboardDetailsByKeyURL, payload);
  }

  fetchDashboardDetailsByKey(payload: any) {
    let dashboardDetailsByKeyURL =
      this.commonService.restDomainURL + RestURL.FIND_KEY_DETAILS;
    return this.http.post(dashboardDetailsByKeyURL, payload);
  }

  downloadFile(params: any) {
    let dashboardDetailsByKeyURL =
      this.commonService.restDomainURL + RestURL.DOWNLOAD_FILE;
    return this.http.get(dashboardDetailsByKeyURL, {
      params: new HttpParams()
        .set('ReportType', params?.ReportType)
        .set('ReqId', params?.ReqId),
    });
  }
}
