<body class="d-flex flex-column h-100">
    <main class="clearfix pb-5 flex-fill container-fluid">
        <ng-container>
            <div class="mt-2 position-relative h-100 pb-5 ng-scope">
                <div class="row m-2">

                    <ng-container>
                        <!-- Login Form -->
                        <ng-template #Login let-name="Login">
                            <div class="col-md-4 mb-3">
                                <div class="card border-0  rounded-0 shadow-lg bg-light h-100">
                                    <div class="card-body p-3 pt-4">
                                        <form autocomplete="off" id="loginForm" class="col-12"
                                            [formGroup]="reactiveForm" name="loginForm" (ngSubmit)="onlogin()">
                                            <app-toast id="toast"></app-toast>
                                            <div class="form-group m-3">
                                                <div class="input-group">
                                                    <label id="loginusername" for="userId">Username</label>
                                                </div>
                                                <div class="input-group">
                                                    <input tabindex="0" class="form-control rounded-0" type="text"
                                                        id="userId" name="userId" formControlName="userId"
                                                        aria-required="true" placeholder="Please enter the username"
                                                        aria-labelledby="loginusername" aria-describedby="err1"
                                                        [class.is-invalid]="userId.invalid && (userId.dirty || userId.touched)">
                                                    <!-- [attr.aria-describedby]="userId.invalid && (userId.dirty || userId.touched) && userId.errors?.['required'] ? 'err1' : ''" -->

                                                    <div id="err1" class="help-block text-danger inputerr">
                                                        <span
                                                            *ngIf="userId.invalid && (userId.dirty || userId.touched) && userId.errors?.['required']">*
                                                            Username is required </span>
                                                    </div>

                                                </div>
                                                <!-- <div *ngIf="userId.invalid && (userId.dirty || userId.touched) && userId.errors?.['required']"
                                                    class="help-block text-danger" id="err1">
                                                    <span>* Username is required </span>
                                                </div> -->
                                            </div>
                                            <div class="form-group m-3">
                                                <div class="input-group">
                                                    <label id="loginpassword" for="password">Password</label>
                                                </div>
                                                <div class="input-group">
                                                    <input tabindex="0" class="form-control rounded-0" type="password"
                                                        id="password" name="password" formControlName="password"
                                                        aria-required="true" placeholder="Please enter the password"
                                                        aria-labelledby="loginpassword" aria-describedby="err2"
                                                        [class.is-invalid]="password.invalid && (password.dirty || password.touched)">
                                                    <div id="err2" class="help-block text-danger inputerr">
                                                        <span
                                                            *ngIf="password.invalid && (password.dirty || password.touched) && password.errors?.['required']">
                                                            * Password is required
                                                        </span>
                                                    </div>
                                                </div>
                                                <!-- <div *ngIf="password.invalid && (password.dirty || password.touched) && password.errors?.['required']"
                                                    class="help-block text-danger" id="err2">
                                                    <span>* Password is required </span>
                                                </div> -->
                                            </div>
                                            <div class="d-flex">
                                                <div class="form-group  m-3">
                                                    <div>
                                                        <button type="submit" form="loginForm" tabindex="0"
                                                            aria-label="Sign in" class="h-40 btn btn-primary rounded-0 "
                                                            [disabled]="reactiveForm.invalid">Sign in</button>
                                                    </div>
                                                </div>
                                                <div class="form-group  m-3 d-flex align-items-center">
                                                    <div class="p-0 mt-md-2">
                                                        <a href="#/" tabindex="0" role="button"
                                                            style="text-decoration: none;"
                                                            aria-label="Sign in assistance" (click)="signInclick()">Sign
                                                            in assistance</a>
                                                    </div>
                                                </div>
                                            </div>
                                            <hr class="hr-text" data-content="OR">
                                            <div class="col-sm-12 p-2">
                                                <div class="d-flex">
                                                    <button tabindex="0" type="button" aria-label="Login with SSO"
                                                        class="btn btnsso btn-outline-primary w-100 rounded-1"
                                                        (click)="ssoLogin()"> Sign in with Bank SSO
                                                    </button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                            <!-- About - Description -->
                            <div class="col-md-8 mb-3">
                                <div class="card border-0  shadow-lg bg-light h-100">
                                    <div class="card-body p-3">
                                        <app-about></app-about>
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                        <!-- Reset New Password -->
                        <ng-template #resetNewpwd let-name="resetNewpwd">
                            <div class="col-md-4 mb-3">
                                <div class="card border-0  rounded-0 shadow-lg bg-light h-100">
                                    <div class="card-body p-3 pt-4">
                                        <form autocomplete="off" id="loginForm" class="col-12"
                                            [formGroup]="resetNewpwdForm" name="resetNewpwdForm"
                                            (ngSubmit)="setNewPwdsubmit()">
                                            <app-toast></app-toast>
                                            <div class="form-group m-3">
                                                <div class="input-group">
                                                    <label for="password" class="">New Password</label>
                                                </div>
                                                <div class="input-group">
                                                    <input tabindex="0" class="form-control rounded-0" type="password"
                                                        id="newPassword" name="newPassword"
                                                        formControlName="newPassword" aria-labelledby="newpwdLabel"
                                                        required placeholder="Please enter the new password"
                                                        [attr.aria-describedby]="newPassword.invalid && (newPassword.dirty || newPassword.touched) && newPassword.errors?.['required'] ? 'err2' : ''"
                                                        [class.is-invalid]="newPassword.invalid && (newPassword.dirty || newPassword.touched)">
                                                </div>
                                                <div *ngIf="newPassword.invalid && (newPassword.dirty || newPassword.touched) && newPassword.errors?.['required']"
                                                    class="help-block text-danger" id="err2">
                                                    <span>* New Password is required </span>
                                                </div>
                                            </div>
                                            <div class="form-group m-3">
                                                <div class="input-group">
                                                    <label for="password" class="">Confirm Password</label>
                                                </div>
                                                <div class="input-group">
                                                    <input tabindex="0" class="form-control rounded-0" type="password"
                                                        id="confirmPassword" name="confirmPassword"
                                                        formControlName="confirmPassword" aria-labelledby="cnfpwdLabel"
                                                        required placeholder="Please enter the confirm password"
                                                        [attr.aria-describedby]="confirmPassword.invalid && (confirmPassword.dirty || confirmPassword.touched) && confirmPassword.errors?.['required'] ? 'err2' : ''"
                                                        [class.is-invalid]="confirmPassword.invalid && (confirmPassword.dirty || confirmPassword.touched)">
                                                </div>
                                                <div *ngIf="confirmPassword.invalid && (confirmPassword.dirty || confirmPassword.touched) && confirmPassword.errors?.['required']"
                                                    class="help-block text-danger" id="err2">
                                                    <span>* Confirm Password is required </span>
                                                </div>
                                            </div>
                                            <div class="d-flex">
                                                <div class="form-group  m-3">
                                                    <div>
                                                        <button type="submit" form="loginForm" tabindex="0"
                                                            title="Sign in" class="h-40 btn btn-primary rounded-0 me-3"
                                                            [disabled]="resetNewpwdForm.invalid">Submit</button>

                                                        <button type="button" class="h-40 btn btn-primary rounded-0"
                                                            tabindex="0" type="reset"
                                                            (click)="challQuesCancel()">Cancel</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                            <!-- About - Description -->
                            <div class="col-md-8 mb-3">
                                <div class="card border-0  shadow-lg bg-light h-100">
                                    <div class="card-body p-3">
                                        <app-about></app-about>
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                        <!-- Sign In Assistance-->
                        <ng-template #signInAssistance let-name="signInAssistance">
                            <div class="col-md-4 mb-3">
                                <div class="card border-0 rounded-0 shadow-lg bg-light h-100">
                                    <div class="card-body p-3 pt-4">
                                        <form #form="ngForm" class="col-12" autocomplete="off">
                                            <app-toast id="toast" role="alert" aria-live="assertive"
                                                aria-atomic="true"></app-toast>
                                            <div class="form-group m-3">
                                                <div class="input-group">
                                                    <label id="signusername" for="username">Username</label>
                                                </div>
                                                <div class="input-group rounded-0">
                                                    <input tabindex="0" type="text" id="username" name="username"
                                                        #username="ngModel" class="form-control rounded-0"
                                                        [(ngModel)]="userForm.username" required
                                                        placeholder="Please enter the username"
                                                        aria-labelledby="signusername"
                                                        [attr.aria-describedby]="username.invalid && (username.dirty || username.touched) && username.errors?.['required'] ? 'err1' : ''"
                                                        [class.is-invalid]="username.invalid && (username.dirty || username.touched)">
                                                </div>
                                                <div *ngIf="username.invalid && (username.dirty || username.touched) && username.errors?.['required']"
                                                    class="help-block text-danger" id="err1">
                                                    <span>* Username is required </span>
                                                </div>
                                            </div>
                                            <div class="form-group m-3">
                                                <div class="input-group">
                                                    <label id="signpassword" for="password">Email</label>
                                                </div>
                                                <div class="input-group"> <input tabindex="0" type="text" id="email"
                                                        name="email" class="form-control rounded-0"
                                                        [(ngModel)]="userForm.email" required #email="ngModel"
                                                        ng-pattern-restrict="^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$"
                                                        placeholder="Please enter the email"
                                                        aria-labelledby="signpassword"
                                                        [attr.aria-describedby]="email.invalid && (email.dirty || email.touched) && email.errors?.['required'] ? 'err2' : ''"
                                                        [class.is-invalid]="email.invalid && (email.dirty || email.touched)">
                                                </div>
                                                <div *ngIf="email.invalid && (email.dirty || email.touched) && email.errors?.['required']"
                                                    class="help-block text-danger" id="err2">
                                                    <span>* Email is required </span>
                                                </div>
                                            </div>
                                            <div class="d-flex justify-content-between">
                                                <div class="form-group m-3">
                                                    <div> <button type="submit" tabindex="0" title="Change Password"
                                                            class=" btn btn-primary rounded-0" [disabled]="form.invalid"
                                                            (click)="onChangePwdSubmit(form)">Change Password</button>
                                                    </div>
                                                </div>
                                                <div class="d-flex align-items-center"> <span> OR </span> </div>
                                                <div class="form-group m-3 d-flex align-items-center">
                                                    <div> <button type="submit" tabindex="0" title="Account Unlock"
                                                            class=" btn btn-primary rounded-0" [disabled]="form.invalid"
                                                            (click)="accunlockSubmit(userForm)">Account Unlock</button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                class="form-group m-3 mt-0 d-flex align-items-center justify-content-end">
                                                <div class="p-0 mt-md-2"> <a tabindex="0" role="button" href="#/"
                                                        title="Back to Login" (click)="backtologin()">Back to
                                                        Login</a>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                            <!-- About - Description -->
                            <div class="col-md-8 mb-3">
                                <div class="card border-0  shadow-lg bg-light h-100">
                                    <div class="card-body p-3">
                                        <app-about></app-about>
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                    </ng-container>

                    <ng-container [ngSwitch]="role">
                        <ng-container *ngSwitchCase="'Login'">
                            <ng-container *ngTemplateOutlet="Login">Login</ng-container>
                            <hr>
                        </ng-container>
                        <ng-container *ngSwitchCase="'resetNewpwd'">
                            <ng-container *ngTemplateOutlet="resetNewpwd">resetNewpwd</ng-container>
                            <hr>
                        </ng-container>
                        <ng-container *ngSwitchCase="'signInAssistance'">
                            <ng-container *ngTemplateOutlet="signInAssistance">signInAssistance</ng-container>
                            <hr>
                        </ng-container>
                    </ng-container>
                </div>

                <!-- Security Questions Modal-->
                <app-modal id="security_ques" style="display: none;">
                    <div class="modal-header">
                        <h2 class="modal-title " id="securityquestion">Challenge Questions</h2>
                        <button type="button" #closeButton id="closeButton" class="btn-close"
                            (click)="modalService.close();" data-bs-dismiss="modal" aria-label="Close"
                            aria-label="Answer challenge questions dialog close button to activate press enter"></button>
                    </div>
                    <div class="modal-body">
                        <form name="securityqusForm" #securityqusForm="ngForm"
                            (ngSubmit)="securityqusForm.form.valid && formQuAnsSubmit(sequrityQusInterface.otpVal)">
                            <h3 style="font-size: 16px;">
                                <p class="">For security, verify your identity by answering one challenge
                                    questions.
                                </p>
                            </h3>
                            <div class="form-group" *ngFor="let item of sequrityQusInterface.finalData;  let i = index">
                                <div *ngFor="let val of item.finalQnSet" class="mb-3">
                                    <label class="col-form-label" for="secAns_{{i}}" [attr.id]="'seqfnquan_'+i">{{i
                                        +1}}.&nbsp;{{val.question}}</label>
                                    <div class="input-group">
                                        <input type="password" tabindex="0" class="form-control" name="secAns_{{i}}"
                                            id="secAns_{{i}}" minlength="6" maxlength="45"
                                            ng-pattern-restrict="^([A-Za-z0-9\s*]*|)$" #finalAnswer="ngModel"
                                            autocomplete="off" aria-invalid="false" [(ngModel)]="val.selVal.answer"
                                            placeholder="Enter your answer" [attr.aria-labelledby]="'seqfnquan_'+i"
                                            [attr.aria-describedby]="'err1_' + i "
                                            [class.is-invalid]="finalAnswer.invalid && (finalAnswer.dirty || finalAnswer.touched)"
                                            required />
                                    </div>
                                    <!-- [attr.aria-describedby]="finalAnswer.invalid && (finalAnswer.dirty || finalAnswer.touched) && finalAnswer.errors?.['required'] ? 'err2' : ''" -->
                                    <div *ngIf="finalAnswer.invalid && (finalAnswer.dirty || finalAnswer.touched) && finalAnswer.errors?.['required']"
                                        class="help-block text-danger" [attr.id]="'err1_' + i ">
                                        <span>* Answer is required </span>
                                    </div>
                                </div>
                                <h3 class="h3font"><label class="text-primary headr_text" id="otp" for="otpval">Please
                                        enter OTP sent through
                                        mail.</label>
                                </h3>
                                <div class="input-group mb-1">
                                    <input type="text" tabindex="0" class="form-control px-rounded-0" name="otpval"
                                        id="otpval" maxlength="6" #otp="ngModel" ng-pattern-restrict="^([0-9\s*]*|)$"
                                        [(ngModel)]="sequrityQusInterface.otpVal" autocomplete="off"
                                        aria-invalid="false" placeholder="Enter your OTP" aria-labelledby="otp"
                                        [attr.aria-describedby]="'err2_' + i "
                                        [class.is-invalid]="otp.invalid && (otp.dirty || otp.touched)"
                                        (keydown)="!securityqusForm.form.valid ? setTabIndex_ADA($event, 'closeButton', false) : setTabIndex_ADA($event, 'subbtn', false) "
                                        required />
                                </div>
                                <!-- [attr.aria-describedby]="otp.invalid && (otp.dirty || otp.touched) && otp.errors?.['required'] ? 'err2' : ''" -->
                                <div *ngIf="otp.invalid && (otp.dirty || otp.touched) && otp.errors?.['required']"
                                    class="help-block text-danger" [attr.id]="'err2_' + i ">
                                    <span>* OTP is required </span>
                                </div>
                            </div>
                            <div class="modal-footer mt-4">
                                <div class="mt-4 text-start">
                                    <button id="subbtn" type="submit" tabindex="0"
                                        class="h-40 btn btn-primary rounded-0" [disabled]="!securityqusForm.form.valid"
                                        aria-label="Sign in">Sign in</button>
                                    <!-- <button tabindex="0" type="reset"
                            class="px-btn px-btn-secondary px-rounded-0 px-mr-3">Trouble signing
                            in ?</button> -->
                                </div>
                            </div>
                        </form>
                    </div>
                </app-modal>

                <!-- security questions unlock account pop up -->
                <app-modal id="security_resetques" style="display: none;">
                    <div class="modal-header">
                        <h2 class="modal-title" id="securityresetquestion">Challenge Questions</h2>
                        <button type="button" class="btn-close" (click)="modalService.close();" data-bs-dismiss="modal"
                            id="secuclose"
                            aria-label="Answer challenge questions dialog close button to activate press enter"></button>
                    </div>
                    <div class="modal-body">
                        <form name="challQuesForm" #challQuesForm="ngForm" autocomplete="off"
                            (ngSubmit)="challQuesForm.form.valid && finalAssistQuAnsSubmit()">
                            <h3 style="font-size: 16px;">
                                <p>Asterisk items (<span class="text-danger" style="font-size: 1rem;">*</span>) are
                                    required to proceed.</p>
                            </h3>
                            <div class="form-group" *ngFor="let item of sequrityQusInterface.myData; let j = index">
                                <div *ngFor="let val of item.itemQnSet;" class="mb-3">
                                    <label class="col-form-label" [attr.id]="'seqfinalQuAns_'+j"
                                        for="secfinAns_{{j}}">{{j+1}}.&nbsp;{{val.question}}</label>
                                    <div class="input-group rounded-0">
                                        <input type="password" tabindex="0" class="form-control rounded-0"
                                            name="secfinAns_{{j}}" id="secfinAns_{{j}}" minlength="6" maxlength="45"
                                            pattern="^([A-Za-z0-9\s*]*|)$" #finalQuAns="ngModel" autocomplete="off"
                                            aria-invalid="false" [(ngModel)]="item.selVal.Ans" required
                                            placeholder="Enter your answer" [attr.aria-labelledby]="'seqfinalQuAns_'+j"
                                            [attr.aria-describedby]="'err1_' + j + ' err2_' + j"
                                            [class.is-invalid]="finalQuAns.invalid && (finalQuAns.dirty || finalQuAns.touched)" />
                                    </div>
                                    <!-- [attr.aria-describedby]="finalQuAns.invalid && (finalQuAns.dirty || finalQuAns.touched) && finalQuAns.errors?.['required'] ? 'err1' : ''" -->
                                    <div *ngIf="finalQuAns.invalid && (finalQuAns.dirty || finalQuAns.touched) && finalQuAns.errors?.['required']"
                                        class="help-block text-danger" [id]="'err1_' + j">
                                        <span>* Answer is required </span>
                                    </div>
                                    <div *ngIf="finalQuAns.errors?.['minlength']" class="help-block text-danger"
                                        [id]="'err2_' + j">
                                        <span> No special character allowed. Use alpha numeric and space. 6-45
                                            characters in
                                            length.</span>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="mt-2">
                                    <div class="p-0">
                                        <button type="button" class="h-40 btn btn-primary rounded-0 me-3" tabindex="0"
                                            type="submit" [disabled]="!challQuesForm.form.valid">Save</button>
                                        <button type="button" class="h-40 btn btn-primary rounded-0" tabindex="0"
                                            type="reset" (click)="challQuesCancel()">Reset</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </app-modal>

            </div>
        </ng-container>
    </main>
</body>