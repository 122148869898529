import { Injectable } from '@angular/core';
import { CommonService } from '../common/common.service';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import RestURL from 'src/assets/config/restURLConstant.json';
@Injectable({
  providedIn: 'root'
})
export class AdminmoduleService {
  constructor(private http: HttpClient, private commonService: CommonService) { }

  createClient(data: any) {
    let createClientURL = this.commonService.restDomainURL + RestURL.CREATECLIENT_USER;
    return this.http.post(createClientURL, data);
  }

  getAllRegions() {
    let getlistofURL = this.commonService.restDomainURL + RestURL.GET_CREATECLIENTREGION_LIST;
    return this.http.get(getlistofURL);
  }

  createUser(data: any) {
    let createUSERURL = this.commonService.restDomainURL + RestURL.CREATEUSER_REGISTER;
    return this.http.post(createUSERURL, data);
  }

  getUserRegion() {
    let getUserRegionList = this.commonService.restDomainURL + RestURL.GET_CREATEDUSERREGION_LIST;
    return this.http.get(getUserRegionList);
  }

  getAllClients() {
    let getlistofclients = this.commonService.restDomainURL + RestURL.FETCH_CLIENTIDS;
    return this.http.get(getlistofclients);
  }

}
