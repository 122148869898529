import { Component, Input, OnChanges, OnInit, Output, EventEmitter, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { FormGroup, FormBuilder, ReactiveFormsModule, Validators, FormControl, AbstractControl } from '@angular/forms';
import { ActivatedRoute, Router, NavigationEnd, Scroll } from '@angular/router';
import { Subscription } from 'rxjs';
import { AdminmoduleService } from 'src/app/services/admin/adminmodule.service';
import { ToastrService } from 'ngx-toastr';
import { CrudComponent } from 'src/app/modules/crud/crud.component';
import RestURL from 'src/assets/config/restURLConstant.json';
import { CommonService } from 'src/app/services/common/common.service';
import { Select2Module, Select2UpdateEvent, Select2Value } from 'ng-select2-component';
import { DropdownModule } from 'primeng/dropdown';
import { MessageService } from 'primeng/api';
interface JsonFormValidators {
  required?: boolean;
}

interface JsonFormControls {
  name: string;
  label: string;
  value: string;
  type: string;
  validators: JsonFormValidators;
}

export interface JsonFormData {
  clients: JsonFormControls[];
}
@Component({
  selector: 'app-forms',
  templateUrl: './forms.component.html',
  styleUrls: ['./forms.component.css'],
  standalone: true,
  imports: [CrudComponent, FormsComponent, CommonModule, ReactiveFormsModule, Select2Module, DropdownModule],
  schemas: [NO_ERRORS_SCHEMA],

})

export class FormsComponent implements OnInit, OnChanges {
  @Input() jsonFormData: JsonFormData;
  @Input() method: string = '';
  @Input() modulename: string = '';
  @Input() editdata: any = {};
  @Input() disflag: any;
  @Input() buttflag: boolean;
  @Output() actionEvent = new EventEmitter();
  @Output() unlockEvent = new EventEmitter();
  disableFlag: boolean = false
  @Input() from: any;
  overlay = false;
  public dynamicform: FormGroup = this.fb.group({ clientid: [''] });
  public routeSubscription$: Subscription;
  submitted: boolean = false;
  regionlist: any = [];
  public params: any;
  clientallList: any = [];
  userregionlist: any = [];
  rolesList: any;
  URL: any;
  statList: any = [];
  createUserObj: any = {};
  valueChangesSubscription: Subscription;
  formDisableFields = [];

  constructor(
    private http: HttpClient,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private adminService: AdminmoduleService,
    private toastr: ToastrService,
    private commonService: CommonService,
    private messageService: MessageService) {
    this.router.events.subscribe((event) => {
      this.dynamicform.reset();
      if (event instanceof Scroll) {
        if (event.routerEvent instanceof NavigationEnd) {
          if (event.routerEvent.url.indexOf('/edit') > -1) {
            this.disableFlag = true;
          } else {
            this.disableFlag = false;
          }
        }
      } else if (event instanceof NavigationEnd) {
        if (event.url.indexOf('/edit') > -1) {
          this.disableFlag = true;
          this.reload();
        } else {
          this.disableFlag = false;
        }
      }

    })


  }
  ngOnInit() {
    this.getRegionFunc();
    this.getclientList();
    this.getstatus();
    this.formDisableFields = ['clientid', 'username', 'region']
    if (this.dynamicform.get("clientid") != null && this.modulename == 'users') {
      this.dynamicform.get("clientid").valueChanges.subscribe(selectedValue => {
        let url = RestURL.GET_CREATEDUSERREGION_LIST
        let regionUrl = this.commonService.restDomainURL + url;
        let obj: any = {};
        obj = {
          "params": { clientid: selectedValue }
        }
        if (selectedValue) {
          this.http.get(regionUrl, obj).subscribe({
            next: (data: any) => {
              let dataSuccess = data;
              if (dataSuccess[0].Role == 1) {
                this.createUserObj.roles = [
                  {
                    'label': 'Ti Power User',
                    'value': 'tipoweruser'
                  },
                  {
                    'label': 'User',
                    'value': 'user'
                  },
                  {
                    'label': 'VAPI User',
                    'value': 'vapiuser'
                  }
                ]
              } else {
                this.createUserObj.roles = [
                  {
                    'label': 'User',
                    'value': 'user'
                  },
                  {
                    'label': 'VAPI User',
                    'value': 'vapiuser'
                  }
                ]

              }
              let userregArr = [];
              dataSuccess.map(function (item: any) {
                userregArr.push({ label: item?.region, value: item?.region })
              });

              this.userregionlist = userregArr;
              this.dynamicform.get('region').setValue(this.userregionlist[0].value)
            },
            error: (err: any) => {
              // this.toastr.error(err?.error?.error?.message);
              this.messageService.add({
                severity: 'error',
                detail: err?.error?.error?.message
              });
            }
          });
        }
      })
    }

    setTimeout(() => {
      if (document.getElementById('selec2_msg1')) {
        var p = document.getElementById('selec2_msg1');
        if (p.getElementsByClassName('select2-selection select2-selection--single')) {
          p.getElementsByClassName('select2-selection select2-selection--single')[0].setAttribute('aria-label', 'Search Region')
        }
      }
      if (document.getElementById('selec2_msg2')) {
        var q = document.getElementById('selec2_msg2');
        if (q.getElementsByClassName('select2-selection select2-selection--single')) {
          q.getElementsByClassName('select2-selection select2-selection--single')[0].setAttribute('aria-label', 'User Search Region')
        }
      }
      if (document.getElementById('selec2_msg3')) {
        var r = document.getElementById('selec2_msg3');
        if (r.getElementsByClassName('select2-selection select2-selection--single')) {
          r.getElementsByClassName('select2-selection select2-selection--single')[0].setAttribute('aria-label', 'Status')
        }
      }
      if (document.getElementById('selec2_msg4')) {
        var s = document.getElementById('selec2_msg4');
        if (s.getElementsByClassName('select2-selection select2-selection--single')) {
          s.getElementsByClassName('select2-selection select2-selection--single')[0].setAttribute('aria-label', 'Search Client id')
        }
      }
      if (document.getElementById('selec2_msg5')) {
        var t = document.getElementById('selec2_msg5');
        if (t.getElementsByClassName('select2-selection select2-selection--single')) {
          t.getElementsByClassName('select2-selection select2-selection--single')[0].setAttribute('aria-label', 'Search Role')
        }
      }

    }, 0);



  }

  getRegionFunc() {
    this.adminService.getAllRegions().subscribe({
      next: (resp: any) => {
        this.loadDropdownvalues(resp)
      },
      error: (error: Error) => {
      },
    });
  }

  getclientList() {
    this.adminService.getAllClients().subscribe({
      next: (resp: any) => {
        this.loadDropdownClientvalues(resp)
      }, error: (error: Error) => {
      },
    });
  }

  loadDropdownvalues = function (response: any) {
    if (response && response.length) {
      let uniqueArr = [];
      response.map(function (item: any) {
        uniqueArr.push({ label: item?.region, value: item?.region })
      });
      this.regionlist = uniqueArr;
    }
  }
  loadDropdownClientvalues = function (response: any) {
    if (response && response.length) {
      let uniqueArr1 = [];
      response.map(function (item: any) {
        uniqueArr1.push({ label: item?.clientid, value: item?.clientid })
      });
      this.clientallList = uniqueArr1;
    }
  }

  getstatus() {
    this.statList = [
      { label: 'ACTIVE', value: 'active' },
      { label: 'INACTIVE', value: 'inactive' }]
  }
  ngOnChanges() {
    if (this.jsonFormData) {
      this.createForm(this.jsonFormData.clients);
    }
    setTimeout(() => {
      if (this.editdata && this.method == 'edit' && this.disableFlag) {
        this.dynamicform?.patchValue(this.editdata);
      }
    }, 500)

  }

  createForm(clients: JsonFormControls[]) {
    for (const client of clients) {
      this.dynamicform.addControl(client.name, new FormControl({ value: client.value, disabled: false }, client?.validators?.['required'] ? [Validators.required] : []));

      if (this.disableFlag) {
        this.dynamicform.get(client.name).disable();
      } else {
        this.dynamicform.get(client.name).enable();
      }

      if (this.disflag) {
        if (this.formDisableFields.includes(client.name) && this.disableFlag) {
          //console.log("chek",client.name)
          this.dynamicform.get(client.name).disable();
        } else {
          this.dynamicform.get(client.name).enable();
        }
      }

    }
    if (this.method == 'edit' && this.modulename == 'users') {
      this.dynamicform.get('role').clearValidators();
    }
  }

  cancel() {
    this.router.navigate(['app/' + sessionStorage.getItem('landingpage')]);
  }

  ngOnDestroy() {
    if (this.valueChangesSubscription) {
      this.valueChangesSubscription.unsubscribe();
    }
  }

  onSubmit() {
    this.submitted = true;
    let data = this.dynamicform.getRawValue();
    this.submitted = true;

    if (this.dynamicform.valid) {
      if (this.method == 'create' && this.modulename == 'client') {
        this.adminService.createClient(data).subscribe({
          next: (response: any) => {
            let dataSuccess = response;
            // this.toastr.success(
            //   dataSuccess.message || 'Created client successfully'
            // );
            this.messageService.add({
              severity: 'success',
              detail: dataSuccess.message || 'Created client successfully'
            });
            this.dynamicform.reset();
          },
          error: (err) => {
            // this.toastr.error(err?.error?.error?.message);
            this.messageService.add({
              severity: 'error',
              detail: err?.error?.error?.message
            });
          },
        });
      } else if (this.method == 'create' && this.modulename == 'users') {
        this.adminService.createUser(data).subscribe({
          next: (response: any) => {
            //console.log('response---', response);
            let dataSuccess = response;
            // this.toastr.success(
            //   dataSuccess.message || 'Created user successfully'
            // );
            this.messageService.add({
              severity: 'success',
              detail: dataSuccess.message || 'Created user successfully'
            });
            this.dynamicform.reset();
          },
          error: (err) => {
            // this.toastr.error(err?.error?.error?.message);
            this.messageService.add({
              severity: 'error',
              detail: err?.error?.error?.message
            });
          },
        });
      } else if (this.method == 'edit' && this.submitted && this.modulename == 'client') {
        //console.log("editing::",this.editdata)
        data.isClientAdmin = this.editdata.isClientAdmin ? true : false
        this.URL = this.commonService.restDomainURL + RestURL.PUT_EDIT_CLIENT
        this.http.put(this.URL, data).subscribe({
          next: (response: any) => {
            let dataSuccess = response;
            // this.toastr.success(dataSuccess.message);
            this.messageService.add({
              severity: 'success',
              detail: dataSuccess.message
            });
            this.dynamicform.reset();
            this.actionEvent.emit({ changedetect: true })
          },
          error: (err: any) => {
            // this.toastr.error(err?.error?.error?.message);
            this.messageService.add({
              severity: 'error',
              detail: err?.error?.error?.message
            });
          }

        })
      } else if (this.method == 'edit' && this.submitted && this.modulename == 'users') {
        delete data.role;
        data.isUserAdmin = this.editdata.isUserAdmin == 3 ? true : false;
        this.URL = this.commonService.restDomainURL + RestURL.PUT_EDIT_USER
        this.http.put(this.URL, data).subscribe({
          next: (response: any) => {
            let dataSuccess = response;
            // this.toastr.success(dataSuccess.message);
            this.messageService.add({
              severity: 'success',
              detail: dataSuccess.message
            });
            this.dynamicform.reset();
            this.actionEvent.emit({ changedetect: true })
          },
          error: (err: any) => {
            //  this.toastr.error(err?.error?.error?.message);
            this.messageService.add({
              severity: 'error',
              detail: err?.error?.error?.message
            });
          }

        })
      }
    } else {
      // this.toastr.error('Fill all the mandatory fileds');
    }
  }



  get myForm() {
    return this.dynamicform.controls;
  }

  unlockUseraccount() {
    this.unlockEvent.emit({ popup: true })
  }
  refresh() {
    this.reload()
  }



  public reload = () => {
    this.dynamicform.reset()
    this.jsonFormData?.clients.forEach(x => {
      this.dynamicform.removeControl(x.value);
    });
    this.dynamicform.markAsPristine();
    this.dynamicform.markAsUntouched();
    //this.jsonFormData.clients = [];
    this.editdata = {};
    this.dynamicform.clearValidators()
  }
}

